import useAppConfig from '@core/app-config/useAppConfig';
export const accountSettingsMixins = {
    data() {
        return {
            users: '',
            copyUsers: '',
            config: useAppConfig(),
        };
    },

    computed: {
        resultMsg() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'swe_message' : 'eng_message';
        },

        getLang() {
            return this.config.lang.value;
        },
    },
    methods: {
        getUsers() {
            this.loadingState = true;
            this.accountForm = false;

            this.$useJwt
                .getCurrentUsers(this.userData.user_uuid)
                .then(res => {
                    const {data} = res.data;

                    this.users = data;
                    this.copyUsers = JSON.stringify(data);
                })
                .finally(() => {
                    this.loadingState = false;
                    this.accountForm = true;
                });
        },
    },

    mounted() {
        this.getUsers();
    },
};
