<template>
  <div>
    <div class="flex flex-col">
      <div class="tab-header grid grid-cols-2 md:grid-cols-9  gap-5 bg-theme-sidebar rounded-xl c-p-2 mb-3 ">
        <div @click="tab = 1" :class="tab === 1 ? 'bg-white text-gray-800 c-p-2 w-full' : 'text-gray-100'"
             class="c-p-1 rounded-xl  cursor-pointer text-center whitespace-nowrap"><span class="text-[11px] font-semibold">{{
            $t('UserDropdown.profile')
          }}</span>
        </div>
        <div @click="tab = 2" :class="tab === 2 ? 'bg-white text-gray-800 c-p-2 w-full' : 'text-gray-100'"
             class="c-p-1 rounded-xl cursor-pointer text-center whitespace-nowrap"><span class="text-sm font-semibold">{{
            $t('UserDropdown.subscription')
          }}</span>
        </div>
        <div @click="tab = 3" :class="tab === 3 ? 'bg-white text-gray-800 c-p-2 w-full' : 'text-gray-100'"
             class="c-p-1 rounded-xl cursor-pointer text-center whitespace-nowrap"><span class="text-sm font-semibold">{{
            $t('UserDropdown.billing')
          }}</span>
        </div>
        <div v-if="hiddenTab.invoice_configuration === false" @click="tab = 4"
             :class="tab === 4 ? 'bg-white text-gray-800 c-p-2 w-full' : 'text-gray-100'"
             class="c-p-1 rounded-xl cursor-pointer text-center whitespace-nowrap"><span class="text-sm font-semibold">{{
            $t('UserDropdown.invoiceConfig')
          }}</span>
        </div>
        <div v-if="hiddenTab.contract_configuration === false" @click="tab = 5"
             :class="tab === 5 ? 'bg-white text-gray-800 c-p-2 w-full' : 'text-gray-100'"
             class="c-p-1 rounded-xl cursor-pointer text-center whitespace-nowrap"><span class="text-sm font-semibold">{{
            $t('UserDropdown.contractConfig')
          }}</span>
        </div>
        <div v-if="hiddenTab.member_configuration === false" @click="tab = 6"
             :class="tab === 6 ? 'bg-white text-gray-800 c-p-2 w-full' : 'text-gray-100'"
             class="c-p-1 rounded-xl cursor-pointer text-center whitespace-nowrap"><span class="text-sm font-semibold">{{
            $t('UserDropdown.memberConfig')
          }}</span>
        </div>
        <div v-if="hiddenTab.invoice_configuration === false" @click="tab = 7"
             :class="tab === 7 ? 'bg-white text-gray-800 c-p-2 w-full' : 'text-gray-100'"
             class="c-p-1 rounded-xl cursor-pointer text-center whitespace-nowrap"><span class="text-sm font-semibold">{{
            $t('UserDropdown.articleConfig')
          }}</span>
        </div>
        <div v-if="hiddenTab.email_configuration === false" @click="tab = 8"
             :class="tab === 8 ? 'bg-white text-gray-800 c-p-2 w-full' : 'text-gray-100'"
             class="c-p-1 rounded-xl cursor-pointer text-center whitespace-nowrap"><span class="text-sm font-semibold">{{
            $t('UserDropdown.emailConfig')
          }}</span>
        </div>
        <div v-if="hiddenTab.api_configuration === false" @click="tab = 9"
             :class="tab === 9 ? 'bg-white text-gray-800 c-p-2 w-full' : 'text-gray-100'"
             class="c-p-1 rounded-xl cursor-pointer text-center whitespace-nowrap"><span class="text-sm font-semibold">{{
            $t('UserDropdown.apiConfig')
          }}</span>
        </div>
      </div>


      <transition name="zoom-fade" mode="out-in">
        <div v-if="tab === 1">
          <b-card>
            <b-card-text class="text-center">
              <b-container>
                <b-row
                    class="justify-content-center align-content-center"
                >
                  <b-col
                      class="d-flex justify-content-sm-start"
                      xl="6"
                      cols="6"
                  >
                    <h2 class="mb-0">
                      {{ $t(locale.title) }}
                    </h2>
                  </b-col>
                  <b-col
                      class="d-flex justify-content-sm-end"
                      xl="6"
                      cols="6"
                  >
                    <!-- <router-link
                        class="d-flex text-secondaryColor justify-content-center"
                        to="/dashboard"
                    >
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="22"
                        />
                        {{ $t(locale.anchor) }}
                    </router-link> -->
                  </b-col>
                </b-row>
              </b-container>
            </b-card-text>

            <div class="mt-5">
              <div class="container">
                <b-row style="">
                  <UpdateInformation :locale="locale"/>
                  <ChangePassword :locale="locale"/>
                  <b-col xl="4">
                    <UpdateImage mode="" :locale="locale"/>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-card>
        </div>
      </transition>

      <transition name="zoom-fade" mode="out-in">
        <div v-if="tab === 2">
          <SubscriptionComponent/>
        </div>
      </transition>

      <transition name="zoom-fade" mode="out-in">
        <div v-if="tab === 3">
          <BillingViewVue :switchingTab="switchTab"/>
        </div>
      </transition>

      <transition name="zoom-fade" mode="out-in">
        <div v-if="tab === 4">
          <InvoiceConfigViewVue/>
        </div>
      </transition>

      <transition name="zoom-fade" mode="out-in">
        <div v-if="tab === 5">
          <ContractConfigViewVue/>
        </div>
      </transition>

      <transition name="zoom-fade" mode="out-in">
        <div v-if="tab === 6">
          <MemberConfigVue/>
        </div>
      </transition>

      <transition name="zoom-fade" mode="out-in">
        <div v-if="tab === 7">
          <ArticleConfigVue/>
        </div>
      </transition>

      <transition name="zoom-fade" mode="out-in">
        <div v-if="tab === 8">
          <EmailConfigViewVue/>
        </div>
      </transition>
      <transition name="zoom-fade" mode="out-in">
        <div v-if="tab === 9">
          <ApiConfig/>
        </div>
      </transition>

    </div>
  </div>
</template>

<script>
import {getUserData} from '@/auth/utils';
import {accountSettingsMixins} from '@/mixins/accountSettingsMixins';
import UpdateInformation from '@/@core/components/accountsettings/UpdateInformation.vue';
import ChangePassword from '@/@core/components/accountsettings/ChangePassword.vue';
import UpdateImage from '@/@core/components/accountsettings/UpdateImage.vue';
import SubscriptionComponent from '@/@core/components/accountsettings/Subscription.vue';
import BillingViewVue from '@/@core/components/accountsettings/BillingView.vue';
import InvoiceConfigViewVue from '@/@core/components/accountsettings/InvoiceConfigView.vue';
import ContractConfigViewVue from '@/@core/components/accountsettings/ContractConfigView.vue';
import EmailConfigViewVue from '@/@core/components/accountsettings/EmailConfigView.vue';
import ArticleConfigVue from '@/@core/components/accountsettings/ArticleConfig.vue';
import MemberConfigVue from '@/@core/components/accountsettings/MemberConfig.vue';
import ApiConfig from "@core/components/accountsettings/ApiConfig.vue";
import {mapGetters} from "vuex"

export default {
  name: 'ConfigurationSettings',
  mixins: [accountSettingsMixins],
  components: {
    ChangePassword,
    UpdateImage,
    UpdateInformation,
    SubscriptionComponent,
    BillingViewVue,
    InvoiceConfigViewVue,
    ContractConfigViewVue,
    EmailConfigViewVue,
    ArticleConfigVue,
    MemberConfigVue,
    ApiConfig
  },
  data() {
    return {
      tab: 1,
      loading: false,
      loadingState: true,
      accountForm: false,
      userData: getUserData(),
      configurationParams: {},
      users: '',
      locale: {
        title: 'AcountSettings.Title',
        anchor: 'AcountSettings.Anchor',
        updateInformation: 'AcountSettings.UpdateInformation',
        changePassword: 'AcountSettings.changePassword',
        changePict: 'AcountSettings.updatePicture',
        btnProfile: 'AcountSettings.updateProfile',
        btnProfilePic: 'AcountSettings.updateProfilePic',
        btnPassword: 'AcountSettings.updatePassword',
        displayName: 'AcountSettings.placeHolder.displayName',
        fullName: 'AcountSettings.placeHolder.fullName',
        email: 'AcountSettings.placeHolder.email',
        phoneNumber: 'AcountSettings.placeHolder.phoneNumber',
        currentPassword: 'AcountSettings.placeHolder.currentPassword',
        newPassword: 'AcountSettings.placeHolder.newPassword',
        confirmPassword: 'AcountSettings.placeHolder.confirmPassword',
        chooseNewPict: 'AcountSettings.newPict',
        currentPic: 'AcountSettings.currentPic',
        loading: 'Message.loading',
      },
      hiddenTab: {
        email_configuration: false,
        member_configuration: false,
        article_configuration: false,
        invoice_configuration: false,
        contract_configuration: false,
        api_configuration: false,

      }
    }
  },
  computed: {
    ...mapGetters({
      GET_HIDDEN_RESOURCE_ID: 'appConfig/getResourceId'
    })
  },
  methods: {
    updatePict() {
    },
    switchTab(number) {
      this.tab = number
    },
    getUserSubscriptions() {
      this.$useJwt.subscription().catch(err => {
        if (err.response.status === 403) {
          window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
        }
      })
    },
    getHiddenResources() {
      this.$useJwt.hiddenResource({method: 'get', URL: `/${this.hiddenResource.id}`}, {}).then(resp => {
        if (resp.data.hidden_pages.configuration) {
          Object.keys(this.hiddenTab).map(key => {
            if (resp.data.hidden_pages.configuration.includes(key) === true) {
              this.hiddenTab[key] = true
            }
          })
        }
      })
    }
  },
  mounted() {
    this.getHiddenResources()
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
  }
};
</script>

<style scoped>
.tab-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}


.tab-header > div {
    max-width: calc(100% / 9 - 10px); /* Subtracting the total margin from each tab width */
    flex: 1;
    min-width: 100px;
    margin: 5px 10px; /* Adjust as needed for desired space */
    padding: 5px 10px; /* Adjust as per design. This will ensure the background covers the text with some space around */
    flex: none; /* This ensures that the item doesn't shrink or grow within a flex container */
    min-width: max-content;
    flex-shrink: 0;
}




</style>
