<template>
  <div class="bg-white p-[20px] w-full h-full">
    <div class="w-full flex justify-end">
          <span class="pr-[10px] cursor-pointer" @click="showModal">
            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M17.4998 8.75C15.9788 8.75193 14.5207 9.35699 13.4452 10.4325C12.3697 11.508 11.7646 12.9661 11.7627 14.4871H14.6794C14.6794 12.931 15.9452 11.6667 17.4998 11.6667C19.0544 11.6667 20.3202 12.931 20.3202 14.4871C20.3202 15.3592 19.6187 15.9921 18.5469 16.8583C18.1967 17.1326 17.8604 17.4242 17.5392 17.7319C16.0837 19.1858 16.0414 20.7302 16.0414 20.9023V21.875H18.9581L18.9567 20.9519C18.9581 20.9285 19.0048 20.389 19.5998 19.7954C19.8185 19.5767 20.0942 19.3579 20.38 19.1275C21.516 18.2073 23.2354 16.8175 23.2354 14.4871C23.2342 12.9661 22.6296 11.5078 21.5543 10.4321C20.4789 9.35653 19.0207 8.75154 17.4998 8.75ZM16.0414 23.3333H18.9581V26.25H16.0414V23.3333Z"
    fill="black"/>
<path
    d="M17.5003 2.91602C9.45908 2.91602 2.91699 9.4581 2.91699 17.4993C2.91699 25.5406 9.45908 32.0827 17.5003 32.0827C25.5416 32.0827 32.0837 25.5406 32.0837 17.4993C32.0837 9.4581 25.5416 2.91602 17.5003 2.91602ZM17.5003 29.166C11.0676 29.166 5.83366 23.9321 5.83366 17.4993C5.83366 11.0666 11.0676 5.83268 17.5003 5.83268C23.933 5.83268 29.167 11.0666 29.167 17.4993C29.167 23.9321 23.933 29.166 17.5003 29.166Z"
    fill="black"/>
</svg>

          </span>
    </div>

    <div class="w-full lg:w-[90%] mx-auto mt-[20px] lg:mt-[5px]">
      <ValidationObserver ref="formApiConfiguration">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-3">
          <div class="px-[2px] lg:px-[25px]">
            <h4>{{ $t('apiConfig.auto_sync_git_to_accounting') }}</h4>
              <div class="py-[10px]">
                  <div class="flex">
                      <div>
                          <ValidationProvider
                              #default="{errors}"
                              rules="required"
                              :name="$t('apiConfig.auto_sync')"
                          >
                              <b-form-checkbox
                                  v-model="form.auto_sync"
                                  class="custom-control-primary"
                              >
                                  <span class="pl-[5px]">
                                      {{ $t('apiConfig.auto_sync') }}
                                  </span>
                                  <span>
                                    <span
                                        class="cursor-pointer"
                                        v-b-tooltip="renderHelptext('auto_sync')"
                                    >
                                      <mdicon
                                          role="button"
                                          :width="20"
                                          :height="20"
                                          name="help-circle-outline"
                                      />
                                    </span>
                                  </span>
                              </b-form-checkbox>
                              <small
                                  class="text-danger"
                                  v-text="errors[0]"
                              />
                          </ValidationProvider>
                      </div>
                  </div>
                  <div class="w-full pt-[19px]" v-if="form.auto_sync">
                      <b-form-group>
                          <label class="text-[15px]">{{ $t('apiConfig.club_to_sync') }}</label>
                          <span>
                            <span
                                class="cursor-pointer"
                                v-b-tooltip="renderHelptext('club_to_sync')"
                            >
                              <mdicon
                                  role="button"
                                  :width="20"
                                  :height="20"
                                  name="help-circle-outline"
                              />
                            </span>
                          </span>
                          
                          <ValidationProvider
                              #default="{errors}"
                              rules="required"
                              :name="$t('apiConfig.auto_sync')"
                          >
                              <select
                                  v-model="form.sync_to_account"
                                  class="w-full flex focus:outline-none rounded c-p-2 bg-white custom-border"
                              >
                                  <option disabled value="">{{ $t('apiConfig.selectClub')}}</option>
                                  <option v-for="(item, index) in userData.club_accounts" :value="item.uuid" :key="index">
                                      {{ item.name }}
                                  </option>
                              </select>
                              <small
                                  class="text-danger"
                                  v-text="errors[0]"
                              />
                          </ValidationProvider>
                      </b-form-group>
                      <b-form-group>
                        <label class="text-[15px]">{{ $t('apiConfig.sync_start_date') }}</label>
                        <span v-b-tooltip="renderHelptext('sync_start_date')" class="cursor-pointer">
                          <mdicon role="button" :width="20" :height="20" name="help-circle-outline" />
                        </span>
                        <ValidationProvider #default="{ errors }" :rules="{'required': form.auto_sync}" :name="$t('apiConfig.sync_start_date')">
                          <div class="datepicker-wrapper">
                            <datepicker :disabledDates="{ to: startDate}" v-model="form.sync_start_date" ref="datepicker-sync_start_date" :placeholder="$t('apiConfig.select_date')"></datepicker>
                            <button @click="showDatepicker('sync_start_date')" class="calendar-button">
                              <mdicon name="calendar" />
                            </button>
                          </div>
                          <small class="text-danger" v-text="errors[0]" />
                        </ValidationProvider>
                      </b-form-group>

                    </div>
                </div>
                <br>
                <h4>{{ $t('apiConfig.auto_create_credits') }}</h4>
                <div class="flex">
                    <div class="py-[10px]">
                      <div class="flex pb-[15px]">
                        <div>
                          <ValidationProvider
                              #default="{errors}"
                              rules="required"
                              :name="$t('apiConfig.auto_sync')"
                          >
                            <b-form-checkbox
                                v-model="form.auto_credit_invoice_in_accounting"
                                class="custom-control-primary"
                            >
                        <span class="pl-[5px]">
                          {{ $t('apiConfig.auto_credit_invoice_in_accounting') }}
                        </span>
                        <span>
                          <span
                              class="cursor-pointer"
                              v-b-tooltip="renderHelptext('auto_credit_invoice_in_accounting')"
                          >
                            <mdicon
                                role="button"
                                :width="20"
                                :height="20"
                                name="help-circle-outline"
                            />
                          </span>
                        </span>
                    </b-form-checkbox>
                    <small
                        class="text-danger"
                        v-text="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </div>
              <div class="w-full pt-[19px]" v-if="form.auto_credit_invoice_in_accounting">
                <b-form-group>
                    <label class="text-[15px]">{{ $t('apiConfig.create_credit_start_date') }}</label>
                    <ValidationProvider
                        #default="{errors}"
                        :rules="{'required': form.create_credit_start_date}"
                        :name="$t('apiConfig.create_credit_start_date')"
                    >
                        <div class="datepicker-wrapper">
                            <datepicker :disabledDates="{ to: startDate}" v-model="form.create_credit_start_date" ref="datepicker-create_credit_start_date" :placeholder="$t('apiConfig.select_date')"></datepicker>
                            <button @click="showDatepicker('create_credit_start_date')" class="calendar-button">
                              <mdicon name="calendar" />
                            </button>
                          </div>
                        <small
                            class="text-danger"
                            v-text="errors[0]"
                        />
                    </ValidationProvider>
                </b-form-group>
              </div>
              </div>
            </div>
          </div>
          <div class="px-[2px] lg:px-[25px]">
            <h4>{{ $t('apiConfig.auto_update_invoice_with_git') }}</h4>
            <div class="py-[10px]">
              <h6>{{ $t('apiConfig.auto_update_payments') }}</h6>
              <div class="flex pb-[15px]">
                <div>
                  <ValidationProvider
                      #default="{errors}"
                      rules="required"
                      :name="$t('apiConfig.auto_sync')"
                  >
                    <b-form-checkbox
                        v-model="form.auto_update_git_payments"
                        class="custom-control-primary"
                    >
                 <span class="pl-[5px]">
                 {{ $t('apiConfig.auto_mark_invoice_as_paid') }}
                 </span>
                 <span>
                    <span
                        class="cursor-pointer"
                        v-b-tooltip="renderHelptext('auto_mark_invoice_as_paid')"
                    >
                      <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                      />
                    </span>
                  </span>
                    </b-form-checkbox>
                    <small
                        class="text-danger"
                        v-text="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </div>
              

              <div>
                <b-form-checkbox
                          v-model="form.include_partially_paid_invoices"
                          class="custom-control-primary"
                      >
                  <span class="pl-[5px]">
                  {{ $t('apiConfig.include_partially_paid_invoices') }}
                  </span>
                  <span>
                      <span
                          class="cursor-pointer"
                          v-b-tooltip="renderHelptext('include_partially_paid_invoices')"
                      >
                        <mdicon
                            role="button"
                            :width="20"
                            :height="20"
                            name="help-circle-outline"
                        />
                      </span>
                    </span>
                      </b-form-checkbox>
                </div>

              

              <div class="w-full pt-[19px]" v-if="form.auto_update_git_payments">
                <b-form-group>
                    <label class="text-[15px]">{{ $t('apiConfig.payment_start_date') }}</label>
                    <ValidationProvider
                        #default="{errors}"
                        :rules="{'required': form.auto_sync}"
                        :name="$t('apiConfig.payment_start_date')"
                    >
                      <div class="datepicker-wrapper">
                          <datepicker :disabledDates="{ to: startDate}" v-model="form.payment_start_date" ref="datepicker-payment_start_date" :placeholder="$t('apiConfig.select_date')"></datepicker>
                          <button @click="showDatepicker('payment_start_date')" class="calendar-button">
                            <mdicon name="calendar" />
                          </button>
                        </div>
                      <small
                          class="text-danger"
                          v-text="errors[0]"
                      />
                    </ValidationProvider>
                </b-form-group>
            </div>
            <br>
            <h6>{{ $t('apiConfig.auto_update_credits') }}</h6>
              <div class="flex">
                <div>
                  <ValidationProvider
                      #default="{errors}"
                      rules="required"
                      :name="$t('apiConfig.auto_sync')"
                  >
                    <b-form-checkbox
                        v-model="form.auto_update_git_credits"
                        class="custom-control-primary"
                    >
                 <span class="pl-[5px]">
                 {{ $t('apiConfig.auto_mark_invoice_as_credited') }}
                 </span>
                 <span>
                    <span
                        class="cursor-pointer"
                        v-b-tooltip="renderHelptext('auto_mark_invoice_as_credited')"
                    >
                      <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                      />
                    </span>
                  </span>
                    </b-form-checkbox>
                    <small
                        class="text-danger"
                        v-text="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </div>
              <div class="w-full pt-[19px]" v-if="form.auto_update_git_credits">
                <b-form-group>
                    <label class="text-[15px]">{{ $t('apiConfig.credit_start_date') }}</label>
                    <ValidationProvider
                        #default="{errors}"
                        :rules="{'required': form.auto_sync}"
                        :name="$t('apiConfig.credit_start_date')"
                    >
                    <div class="datepicker-wrapper">
                            <datepicker :disabledDates="{ to: startDate}" v-model="form.credit_start_date" ref="datepicker-credit_start_date" :placeholder="$t('apiConfig.select_date')"></datepicker>
                            <button @click="showDatepicker('credit_start_date')" class="calendar-button">
                              <mdicon name="calendar" />
                            </button>
                          </div>
                        <small
                            class="text-danger"
                            v-text="errors[0]"
                        />
                    </ValidationProvider>
                </b-form-group>
            </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
      <div class="flex justify-center mt-[20px]">
        <button
    v-ripple.400
    size="lg"
    v-on:click="saveConfiguration"
    class="text-white w-full lg:w-[40%] rounded-none bg-[#4fcfc0] py-[10px] hover:drop-shadow"
>
    <span v-if="loading">
        <b-spinner small type="grow"/>
        {{ $t('Message.loading') }}...
    </span>
    <span
        v-else
        v-text="$t('saveSettings')"
    />
</button>


      </div>
    </div>
    <b-modal
        id="modal-configuration"
        modal-class="modal-primary"
        ref="modal"
        :title="$t(locale.guide)"
        @close="closeModal"
        @cancel="closeModal"
        @hide="closeModal"
        :hide-footer="true"
    >
      <b-card-text>
        <div v-if="guideConfig.length > 0">
          <h4>{{ guideConfig[0][subject] }}</h4>
          <div v-html="guideConfig[0][textConfig]">

          </div>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import i18n from '@/libs/i18n/index.js';
import {mapGetters} from "vuex"
import Datepicker from 'vuejs-datepicker';

extend('date_within_last_year', {
    validate: (value) => {
      const dateValue = new Date(value);
      const yearAgo = new Date();
      yearAgo.setFullYear(yearAgo.getFullYear() - 1);
      return dateValue >= yearAgo;
    },
    message: field => i18n.t('validation.dateWithin12Months', { field })
  });
extend('required', {
    ...required,
    message: field => i18n.t('validation.required', { field })
  });

export default {
  name: "ApiConfig",
  components: {
    ValidationProvider,
    ValidationObserver,
    Datepicker
  },
  data: function () {
    return {
      startDate: new Date(new Date().setMonth(new Date().getMonth() - 6)),
      helptext: [],
      loading: false,
      userData: JSON.parse(localStorage.getItem("userData")),
      guideConfig: [],
      locale: {
        guide: 'ConfigurationGuide.title'
      },
      form: {
        "auto_sync": false,
        "sync_to_account": this.$t('apiConfig.selectClub'), // This is the placeholder value
        "auto_update_git_credits": false,
        "auto_update_git_payments": false,
        "auto_credit_invoice_in_accounting": false,
        "sync_start_date": null,
        "payment_start_date": null,
        "credit_start_date": null, 
        "create_credit_start_date": null,
        "include_partially_paid_invoices": false
      }
    }
  },
  watch: {
    'form.sync_start_date'(newDate) {
      this.form.sync_start_date = this.formatDateToYYYYMMDD(newDate);
    },
    'form.credit_start_date'(newDate) {
      this.form.credit_start_date = this.formatDateToYYYYMMDD(newDate);
    },
    'form.payment_start_date'(newDate) {
      this.form.payment_start_date = this.formatDateToYYYYMMDD(newDate);
    },
    'form.create_credit_start_date'(newDate) {
      this.form.create_credit_start_date = this.formatDateToYYYYMMDD(newDate);
    }
  },
  methods: {
    formatDateToYYYYMMDD(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    showDatepicker(fieldId="") {
      const datepickerRef = `datepicker-${fieldId}`;
      if (this.$refs[datepickerRef]) {
        this.$refs[datepickerRef].showCalendar();
      }
    },
    getHelptext(helptext) {
      this.$useJwt
          .customHelptext({URL: `?dashboard_page=${helptext}`, method: "get"})
          .then((res) => {
            this.helptext = res.data.results;
          });
    },
    renderHelptext(field) {
      const filter = this.helptext.filter((el) => el.field === field);
      if (filter.length > 0) {
        return filter[0][this.helpText];
      }
      return "";
    },
    closeModal() {
    },
    showModal() {
      this.$refs.modal.show();
    },
    saveConfiguration: function () {
      if (!this.form.sync_to_account) {
        this.popupMsg(
            this.$t("Message.Failed"),
            this.$t('apiConfig.clubNotSelected'),
            "AlertTriangleIcon",
            "danger"
        );
        return false
      }
      this.loading = true;
      this.$useJwt
          .updateApiConfig(this.form)
          .then(res => {
            const {data} = res;

            this.popupMsg(
                this.$t('Message.Success'),
                data[this.locale_message],
                'CheckIcon',
                'success'
            );
            this.loading = false
          })
          .catch(e => {
            this.loading = false
            this.popupMsg(
                this.$t("Message.Failed"),
                e.response.data[this.locale_message],
                "AlertTriangleIcon",
                "danger"
            );
          });
    },
    getApiConfiguration: function () {
      this.$useJwt.getApiConfig().then(resp => {
        Object.keys(this.form).map(key => {
          if (resp.data[key]) {
            this.form[key] = resp.data[key];
          } else if (key === 'sync_to_account') {
            // Use placeholder value if there's no value in the server response
            this.form[key] = this.$t('apiConfig.selectClub');
          }
        })
      })
    }, 
    getGuides(config) {
      this.$useJwt.customGuides({URL: `?guide_type=${config}`, method: 'get'})
          .then(res => {
            this.guideConfig = res.data.results
          })
    },
  },
  computed: {
    textConfig() {
      return this.GET_LOCALE === "sv" ? "swe_text" : "eng_text";
    },
    subject() {
      return this.GET_LOCALE === "sv" ? "swe_subject" : "eng_subject";
    },
    helpText() {
      return this.GET_LOCALE === "sv" ? "swedish_text" : "english_text";
    },
    locale_message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    isSyncAccountValid() {
      return this.form.sync_to_account && this.form.sync_to_account !== this.$t('apiConfig.selectClub');
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  mounted() {
    this.getApiConfiguration()
    this.getGuides('api_config')
    this.getHelptext("api_config");
  },
}
</script>

<style scoped>
.datepicker-wrapper {
  position: relative;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  cursor: pointer;
  width: 50%; 
}

.datepicker-wrapper input {
  border: none;
  outline: none;
  width: 100%;
  padding-right: 30px;
}

.calendar-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none;
  cursor: pointer;
  /* Style as needed */
}

.calendar-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
</style>