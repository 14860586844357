export const settingsMixins = {
    data() {
        return {
            resourceList: [],
            selectedResource: [],
        };
    },

    methods: {
        getCurrentResourceList() {
            return new Promise(resolve => {
                this.$useJwt.configure().then(res => {
                    // const {resources_to_check} = res.data.data[0];

                    // resources_to_check.forEach(element => {
                    //     this.selectedResource.push({
                    //         label:
                    //             element.resource.charAt(0).toUpperCase() +
                    //             element.resource.slice(1),
                    //         value: element.resource,
                    //     });
                    // });

                    resolve(true);
                });
            });
        },
        getAvailableResource() {
            // const dataArray = [];
            // this.$useJwt.getResourceList().then(res => {
            //     const {results} = res.data;

            //     results.forEach(element => {
            //         dataArray.push({
            //             label:
            //                 element.resource.charAt(0).toUpperCase() +
            //                 element.resource.slice(1),
            //             value: element.resource,
            //         });
            //     });
            //     this.resourceList = dataArray;
            // });
        },
        init() {
            this.getCurrentResourceList().then(res => {
                this.getAvailableResource();
            });
        },
    },
    mounted() {
        this.init();
    },
};
