<template>
    <b-col xl="4" class="d-flex p-2 flex-column" style="gap: 1.5rem">
        <div class="d-flex flex-column" style="gap: 1rem">
            <b-card-title>
                {{ $t(locale.updateInformation) }}
            </b-card-title>

            <ValidationObserver ref="formUpdateInformation">
                <b-row>
                    <b-col xl="12" class="">
                        <b-row>

                            <b-col cols="12">
                                <ValidationProvider
                                    #default="{errors}"
                                    rules="required"
                                    :name="$t(locale.fullName)"
                                >
                                    <b-form-group :label="$t(locale.fullName)">
                                        <b-form-input
                                            @input="
                                                inputField('full_name', $event)
                                            "
                                            v-model="users.full_name"
                                            :placeholder="$t(locale.fullName)"
                                        />
                                        <small
                                            class="text-danger"
                                            v-text="errors[0]"
                                        />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col cols="12">
                                <ValidationProvider
                                    #default="{errors}"
                                    rules="required|email"
                                    :name="$t(locale.email)"
                                >
                                    <b-form-group :label="$t(locale.email)">
                                        <b-form-input
                                            :value="users.email"
                                            :placeholder="$t(locale.email)"
                                            disabled
                                            readonly
                                        />
                                        <small
                                            class="text-danger"
                                            v-text="errors[0]"
                                        />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col cols="12">
                                <ValidationProvider
                                    #default="{errors}"
                                    rules="integer"
                                    :name="$t(locale.phoneNumber)"
                                >
                                    <b-form-group
                                        :label="$t(locale.phoneNumber)"
                                    >
                                        <b-form-input
                                            @input="inputField('phone', $event)"
                                            v-model="users.phone"
                                            :placeholder="
                                                $t(locale.phoneNumber)
                                            "
                                        />
                                        <small
                                            class="text-danger"
                                            v-text="errors[0]"
                                        />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col cols="12">
                                <b-button
                                    @click="updateProfile"
                                    variant="primary"
                                    :disabled="disabledBtn"
                                    block
                                >
                                    <span v-if="loading">
                                        <b-spinner small type="grow" />
                                        {{ $t(locale.loading) }}...
                                    </span>

                                    <span
                                        v-else
                                        v-text="$t(locale.btnProfile)"
                                    />
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </ValidationObserver>
        </div>
    </b-col>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {accountSettingsMixins} from '@/mixins/accountSettingsMixins';

export default {
    mixins: [accountSettingsMixins],
    props: {
        locale: {
            type: Object,
        },
    },

    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            configurationParams: {},
            disabledBtn: true,
            loading: '',
        };
    },
    methods: {
        inputField(params, value) {
            let copyUsers = JSON.parse(this.copyUsers);

            if (copyUsers[params] !== value) {
                this.configurationParams[params] = value;
                this.disabledBtn = false;
            } else this.disabledBtn = true;
        },
        updateProfile() {
            this.$refs.formUpdateInformation.validate().then(res => {
                if (res) {
                    if (!this.configurationParams.hasOwnProperty('full_name'))
                        this.configurationParams.full_name =
                            this.users.full_name;
                    this.loading = true;
                    this.$useJwt
                        .updateUsers(this.users.uuid, this.configurationParams)
                        .then(res => {
                            const {data} = res;

                            this.popupMsg(
                                this.$t('Message.Success'),
                                data[this.resultMsg],
                                'CheckIcon',
                                'success'
                            );
                        })
                        .finally(() => (this.loading = false));
                }
            });
        },
    },
};
</script>
