<template>
  <div>
    <b-card class="position-relative" :class="{'md-access-denied-height': accessDenied === true}">
      <md-access-denied :is-access-denied="accessDenied" v-if="accessDenied === true" class="mt-40" :height="200"/>
      <div class="flex flex-col font-rubik">
        <ValidationObserver ref="articleForm">
          <div class="relative w-full">
            <div class="right-0 absolute">
              <span class="cursor-pointer" @click="$bvModal.show('modal-configuration')">  
                <mdicon
                  role="button"
                  :width="20"
                  :height="20"
                  name="help-circle-outline"
                />
              </span>
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div class="col-span-2 flex flex-col">
              <div class="flex flex-col">
                <h3 class="text-lg mb-1 font-rubik">{{ $t('articleConfig.accountingSettings') }}</h3>
                <div class="flex">
                  <div class="flex flex-col space-y-1 md:w-1/3">
                    <div v-if="hidden_fields.update_description_in_existing_articles === true">
                      <span class="cursor-pointer" v-b-tooltip="renderHelptext('update_description_in_existing_articles')">
                        <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                        />
                      </span>
                      <b-form-checkbox
                        v-model="articleForm.update_description_in_existing_articles"
                        :id="'checkbox-update-description-article'"
                        name="updateArtice"
                        class="c-mb-2"
                      >
                        {{ $t('articleConfig.updateDescriptionExistingArticles') }}
                      </b-form-checkbox>
                    </div>
                    <div v-if="hidden_fields.update_accounts_in_existing_articles === true">
                      <span class="cursor-pointer" v-b-tooltip="renderHelptext('update_accounts_in_existing_articles')">
                        <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                        />
                      </span>
                      <b-form-checkbox
                        v-model="articleForm.update_accounts_in_existing_articles"
                        :id="'checkbox-update-account-article'"
                        name="updateArtice"
                        class="c-mb-2"
                      >
                        {{ $t('articleConfig.updateAccountsExistingArticles') }}
                      </b-form-checkbox>
                    </div>
                    <div v-if="hidden_fields.update_price_in_existing_articles === true">
                      <span class="cursor-pointer" v-b-tooltip="renderHelptext('update_price_in_existing_articles')">
                        <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                        />
                      </span>
                      <b-form-checkbox
                        v-model="articleForm.update_price_in_existing_articles"
                        :id="'checkbox-update-price'"
                        name="updateArticlePrice"
                        class="c-mb-2"
                      >
                        {{ $t('articleConfig.updatePriceExistingArticles') }}
                      </b-form-checkbox>
                    </div>
                    <div v-if="hidden_fields.use_file_account === true">
                      <span class="cursor-pointer" v-b-tooltip="renderHelptext('use_file_account')">
                        <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                        />
                      </span>
                      <b-form-checkbox
                        v-model="articleForm.use_file_account"
                        :id="'checkbox-account'"
                        name="radio-account"
                        class="c-mb-2"
                      >
                        {{ $t('articleConfig.useAccountInFile') }}
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex flex-col">
              <div class="mt-1" v-if="hidden_fields.article_pricelist === true">
                <label for="" class="text-base font-semibold">{{ $t('articleConfig.pricelist') }}
                  <span>
                                    <span class="cursor-pointer" v-b-tooltip="renderHelptext('article_pricelist')">  
                                        <mdicon
                                            role="button"
                                            :width="20"
                                            :height="20"
                                            name="help-circle-outline"
                                        />
                                    </span>
                                </span>
                </label>
                <div class="md:w-1/2">
                  <v-select class="w-full" v-model="articleForm.article_pricelist" label="code"
                            @option:selected="setPriceList($event, 'article_pricelist')" :filterable="false"
                            :options="pricelistResults" @search="searchPriceList">
                    <template slot="no-options">
                      Sök...
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.code + ' ' + option.description }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.code }}
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
              <template v-for="coding in accountCodings">
                <div class="mt-1" v-if="hidden_fields[coding] === true" :key="coding">
                  <label for="" class="text-base font-semibold">
                    {{ $t(`articleConfig.${coding}`) }}
                    <span>
                      <span class="cursor-pointer" v-b-tooltip="renderHelptext(coding)">
                        <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                        />
                      </span>
                    </span>
                  </label>
                  <div class="md:w-1/2">
                    <v-select class="w-full" v-model="articleForm[coding]" label="name"
                              @option:selected="setAccount($event, coding)" :filterable="true"
                              :options="accountCodingResults">
                      <template slot="no-options">
                        Artikelkonteringar...
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.name }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ getSelectedItemName(option) }}
                        </div>
                      </template>
                    </v-select>
                  </div>
                </div>
              </template>

              <div class="mt-1" v-if="hidden_fields.unit === true">
                <label for="" class="text-base font-semibold">{{ $t('articleConfig.articleUnit') }}
                  <span>
                                    <span class="cursor-pointer" v-b-tooltip="renderHelptext('unit')">  
                                        <mdicon
                                            role="button"
                                            :width="20"
                                            :height="20"
                                            name="help-circle-outline"
                                        />
                                    </span>
                                </span>
                </label>
                <div class="md:w-1/2">
                  <b-form-group>
                    <v-select
                        :placeholder="'Units'"
                        :options="units"
                        v-model="articleForm.unit"
                        @option:selected="computeSelect($event, 'unit')"
                        class="resource-selector d-inline-block w-full"
                    >
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.label }}
                        </div>
                      </template>
                    </v-select>
                  </b-form-group>
                </div>
              </div>
              <div class="mt-1" v-if="hidden_fields.eu_vat_sale_account === true">
                <label for="" class="text-base font-semibold">{{ $t('articleConfig.euSalesAccountVAT') }}
                  <span>
                                    <span class="cursor-pointer" v-b-tooltip="renderHelptext('eu_vat_sale_account')">  
                                        <mdicon
                                            role="button"
                                            :width="20"
                                            :height="20"
                                            name="help-circle-outline"
                                        />
                                    </span>
                                </span>
                </label>
                <div class="md:w-1/2">
                  <v-select class="w-full" v-model="articleForm.eu_vat_sale_account" label="number"
                            @option:selected="setAccount($event, 'eu_vat_sale_account')" :filterable="false"
                            :options="accountResults" @search="searchAccounts">
                    <template slot="no-options">
                      Sök...
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.number + ' ' + option.description }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.number }}
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="mt-1" v-if="hidden_fields.eu_sale_account === true">
                <label for="" class="text-base font-semibold">{{ $t('articleConfig.euSalesAccount') }}
                  <span>
                                    <span class="cursor-pointer" v-b-tooltip="renderHelptext('eu_sale_account')">  
                                        <mdicon
                                            role="button"
                                            :width="20"
                                            :height="20"
                                            name="help-circle-outline"
                                        />
                                    </span>
                                </span>
                </label>
                <div class="md:w-1/2">
                  <v-select class="w-full" v-model="articleForm.eu_sale_account" label="number"
                            @option:selected="setAccount($event, 'eu_sale_account')" :filterable="false"
                            :options="accountResults" @search="searchAccounts">
                    <template slot="no-options">
                      Sök...
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.number + ' ' + option.description }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.number }}
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="mt-1" v-if="hidden_fields.export_sale_account === true">
                <label for="" class="text-base font-semibold">{{ $t('articleConfig.exportSalesAccount') }}
                  <span>
                                    <span class="cursor-pointer" v-b-tooltip="renderHelptext('export_sale_account')">  
                                        <mdicon
                                            role="button"
                                            :width="20"
                                            :height="20"
                                            name="help-circle-outline"
                                        />
                                    </span>
                                </span>
                </label>
                <div class="md:w-1/2">
                  <v-select class="w-full" v-model="articleForm.export_sale_account" label="number"
                            @option:selected="setAccount($event, 'export_sale_account')" :filterable="false"
                            :options="accountResults" @search="searchAccounts">
                    <template slot="no-options">
                      Sök...
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.number + ' ' + option.description }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.number }}
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>

            <div class="flex flex-col">
              <div class="mt-1" v-if="hidden_fields.se_sale_account_25 === true">
                <label for="" class="text-base font-semibold">{{ $t('articleConfig.seSaleAccount25') }}
                  <span>
                      <span class="cursor-pointer" v-b-tooltip="renderHelptext('se_sale_account_25')">  
                          <mdicon
                              role="button"
                              :width="20"
                              :height="20"
                              name="help-circle-outline"
                          />
                      </span>
                  </span>
                </label>
                <div class="md:w-1/2">
                  <v-select class="w-full" v-model="articleForm.se_sale_account_25" label="number"
                            @option:selected="setAccount($event, 'se_sale_account_25')" :filterable="false"
                            :options="accountResults" @search="searchAccounts">
                    <template slot="no-options">
                      Sök...
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.number + ' ' + option.description }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.number }}
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="mt-1" v-if="hidden_fields.se_sale_account_12 === true">
                <label for="" class="text-base font-semibold">{{ $t('articleConfig.seSaleAccount12') }}
                  <span>
                        <span class="cursor-pointer" v-b-tooltip="renderHelptext('se_sale_account_12')">  
                            <mdicon
                                role="button"
                                :width="20"
                                :height="20"
                                name="help-circle-outline"
                            />
                        </span>
                    </span>
                </label>
                <div class="md:w-1/2">
                  <v-select class="w-full" v-model="articleForm.se_sale_account_12" label="number"
                            @option:selected="setAccount($event, 'se_sale_account_12')" :filterable="false"
                            :options="accountResults" @search="searchAccounts">
                    <template slot="no-options">
                      Sök...
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.number + ' ' + option.description }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.number }}
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="mt-1" v-if="hidden_fields.se_sale_account_6 === true">
                <label for="" class="text-base font-semibold">{{ $t('articleConfig.seSaleAccount6') }}
                  <span>
                        <span class="cursor-pointer" v-b-tooltip="renderHelptext('se_sale_account_6')">  
                            <mdicon
                                role="button"
                                :width="20"
                                :height="20"
                                name="help-circle-outline"
                            />
                        </span>
                    </span>
                </label>
                <div class="md:w-1/2">
                  <v-select class="w-full" v-model="articleForm.se_sale_account_6" label="number"
                            @option:selected="setAccount($event, 'se_sale_account_6')" :filterable="false"
                            :options="accountResults" @search="searchAccounts">
                    <template slot="no-options">
                      Sök...
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.number + ' ' + option.description }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.number }}
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="mt-1" v-if="hidden_fields.se_sale_account_0 === true">
                <label for="" class="text-base font-semibold">{{ $t('articleConfig.seSaleAccount0') }}
                  <span>
                        <span class="cursor-pointer" v-b-tooltip="renderHelptext('se_sale_account_0')">  
                            <mdicon
                                role="button"
                                :width="20"
                                :height="20"
                                name="help-circle-outline"
                            />
                        </span>
                    </span>
                </label>
                <div class="md:w-1/2">
                  <v-select class="w-full" v-model="articleForm.se_sale_account_0" label="number"
                            @option:selected="setAccount($event, 'se_sale_account_0')" :filterable="false"
                            :options="accountResults" @search="searchAccounts">
                    <template slot="no-options">
                      Sök...
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.number + ' ' + option.description }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.number }}
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
              <br>
              <div class="mt-" v-if="hidden_fields.se_sale_account_default === true">
                <label for="" class="text-base font-semibold">{{ $t('articleConfig.seSaleAccountDefault') }}
                  <span>
                      <span class="cursor-pointer" v-b-tooltip="renderHelptext('se_sale_account_default')">  
                          <mdicon
                              role="button"
                              :width="20"
                              :height="20"
                              name="help-circle-outline"
                          />
                      </span>
                  </span>
                </label>
                <div class="md:w-1/2">
                  <v-select class="w-full" v-model="articleForm.se_sale_account_default" label="number"
                            @option:selected="setAccount($event, 'se_sale_account_default')" :filterable="false"
                            :options="accountResults" @search="searchAccounts">
                    <template slot="no-options">
                      Sök...
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.number + ' ' + option.description }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.number }}
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
            <div class="flex flex-col">
              <div class="md:w-1/2" v-if="hidden_fields.vat_code_25">
                    <label for="" class="text-base font-semibold">{{ $t('articleConfig.vat_code_25') }}</label>
                    <span class="cursor-pointer" v-b-tooltip="renderHelptext('vat_code_25')">  
                          <mdicon
                              role="button"
                              :width="20"
                              :height="20"
                              name="help-circle-outline"
                          />
                      </span>
                    <div class="w-full">
                      <b-form-input
                        type="text"
                        v-model="articleForm.vat_code_25"
                        name="vat_code_25"
                        :placeholder="``"
                      />
                    </div>
                  </div>
                  <div class="md:w-1/2" v-if="hidden_fields.vat_code_12">
                    <label for="" class="text-base font-semibold">{{ $t('articleConfig.vat_code_12') }}</label>
                    <span class="cursor-pointer" v-b-tooltip="renderHelptext('vat_code_12')">  
                          <mdicon
                              role="button"
                              :width="20"
                              :height="20"
                              name="help-circle-outline"
                          />
                      </span>
                    <div class="w-full">
                      <b-form-input
                        type="text"
                        v-model="articleForm.vat_code_12"
                        name="vat_code_12"
                        :placeholder="``"
                      />
                    </div>
                  </div>
                  <div class="md:w-1/2" v-if="hidden_fields.vat_code_6">
                    <label for="" class="text-base font-semibold">{{ $t('articleConfig.vat_code_6') }}</label>
                    <span class="cursor-pointer" v-b-tooltip="renderHelptext('vat_code_6')">  
                          <mdicon
                              role="button"
                              :width="20"
                              :height="20"
                              name="help-circle-outline"
                          />
                      </span>
                    <div class="w-full">
                      <b-form-input
                        type="text"
                        v-model="articleForm.vat_code_6"
                        name="vat_code_6"
                        :placeholder="``"
                      />
                    </div>
                  </div>
                  <div class="md:w-1/2" v-if="hidden_fields.vat_code_0">
                    <label for="" class="text-base font-semibold">{{ $t('articleConfig.vat_code_0') }}</label>
                    <span class="cursor-pointer" v-b-tooltip="renderHelptext('vat_code_0')">  
                          <mdicon
                              role="button"
                              :width="20"
                              :height="20"
                              name="help-circle-outline"
                          />
                      </span>
                    <div class="w-full">
                      <b-form-input
                        type="text"
                        v-model="articleForm.vat_code_0"
                        name="vat_code_0"
                        :placeholder="``"
                      />
                    </div>
                  </div>
              </div>
            <div class="col-span-2 flex justify-center">
              <button @click="updateArticleSettings" type="submit"
                      class="flex md:w-1/4 p-1 text-center focus:outline-none bg-theme-teal text-white whitespace-nowrap rounded-md">
                            <span class="w-full text-center" v-if="saving">
                                <b-spinner
                                    small
                                    type="grow"
                                />
                                {{ $t('Message.loading') }}...
                            </span>

                <span
                    class="whitespace-nowrap text-center w-full text-base"
                    v-else
                    v-text="
                                    $t(locale.save)
                                "
                />
              </button>
            </div>

          </div>
        </ValidationObserver>
      </div>
      <div class="account-main" v-if="hidden_fields.custom_accounts">
        <AccountMap/>
      </div>
    </b-card>
    <b-modal
        id="modal-configuration"
        modal-class="modal-primary"
        ref="modal"
        :title="$t(locale.guide)"
        @close="closeModal"
        @cancel="closeModal"
        @hide="closeModal"
        :hide-footer="true"
    >
      <b-card-text>
        <div v-if="guideConfig.length > 0">
          <h5>{{ guideConfig[0][subject] }}</h5>
          <div v-html="guideConfig[0][textConfig]">

          </div>
        </div>
      </b-card-text>
    </b-modal>
    
  </div>
</template>

<script>
import {getSubscribersMixins} from '@/mixins/getSubscribersMixins';
import debounce from 'lodash.debounce'
import {billingMixins} from '@/mixins/billingMixins';
import CustomNoHeaderModalVue from '@/@core/customComponent/CustomNoHeaderModal.vue';
import vSelect from 'vue-select';
import {ValidationProvider, ValidationObserver} from 'vee-validate';

import mdAccessDenied from "@core/components/permission/mdAccessDenied";
import {mapGetters} from "vuex";
import PAGES from "@/services/helpers/constant";
import AccountMap from "./AccountMap.vue"
export default {
  name: 'ArticleConfigView',
  mixins: [getSubscribersMixins, billingMixins],
  components: {
    vSelect,
    CustomNoHeaderModalVue,
    ValidationProvider,
    ValidationObserver,
    mdAccessDenied,
    AccountMap
  },
  data() {
    return {
      saving: false,
      modalVisible: false,
      accessDenied: false,
      guideConfig: [],
      hidden_fields: {...JSON.parse(JSON.stringify(PAGES['configuration']['article_configuration']))},
      accountCodings: [
        'article_account_coding_default',
        'article_account_coding_25',
        'article_account_coding_12',
        'article_account_coding_6',
        'article_account_coding_0'
      ],
      articleForm: {
        auto_set_article_number_and_sales_accounts: true,
        update_description_in_existing_articles: false,
        update_accounts_in_existing_articles: false,
        update_price_in_existing_articles: false,
        use_file_account: true,
        se_sale_account_25: '',
        se_sale_account_12: '',
        se_sale_account_6: '',
        se_sale_account_0: '',
        se_sale_account_default: '',
        eu_sale_account: '',
        eu_vat_sale_account: '',
        export_sale_account: '',
        article_pricelist: '',
        article_account_coding_default: '',
        article_account_coding_25: '',
        article_account_coding_12: '',
        article_account_coding_6: '',
        article_account_coding_0: '',
        unit: '',
        vat_code_25: '',
        vat_code_12: '',
        vat_code_6: '',
        vat_code_0: '',
      },
      article_account_coding_default: '',
      article_account_coding_25: '',
      article_account_coding_12: '',
      article_account_coding_6: '',
      article_account_coding_0: '',
      accountCodingResults: [],
      accountResults: [],
      pricelistResults: [],
      units: [],
      items: [],
      fields: [
        {
          key: 'id',
          label: 'invoicesCard.table.number',
          tdClass: 'bTableThStyle',
          thStyle: {
            padding: '0.72rem 10px',
            width: '70px',
          },
        },
        {
          key: 'golfclub',
          label: 'BillingPageCard.companyHead',
          tdClass: 'bTableThStyle',
          thStyle: {
            padding: '0.72rem 0',
            width: '50px',
          },
        },
        {
          key: 'invoice_number',
          label: 'BillingPageCard.invoiceHead',
          tdClass: 'bTableThStyle',
          thStyle: {
            padding: '0.72rem 0',
            width: '50px',
          },
        },
        {
          key: 'date',
          label: 'BillingPageCard.dateHead',
          tdClass: 'bTableThStyle',
          thStyle: {
            padding: '0.72rem 0',
            width: '50px',
          },
        },
      ],
      locale: {
        save: 'ContractConfig.save',
        guide: 'ConfigurationGuide.title'
      },
      helptext: []
    };
  },
  computed: {
    message() {
      if (this.getLang === '') {
        return this.swedishLang ? 'message' : 'eng_message';
      } else return this.getLang === 'sv' ? 'message' : 'eng_message';
    },
    subject() {
      if (this.getLang === '') {
        return this.swedishLang ? 'swe_subject' : 'eng_subject';
      } else return this.getLang === 'sv' ? 'swe_subject' : 'eng_subject';
    },
    textConfig() {
      if (this.getLang === '') {
        return this.swedishLang ? 'swe_text' : 'eng_text';
      } else return this.getLang === 'sv' ? 'swe_text' : 'eng_text';
    },
    helpText() {
      if (this.getLang === '') {
        return this.swedishLang ? 'swedish_text' : 'english_text';
      } else return this.getLang === 'sv' ? 'swedish_text' : 'english_text';
    },
    features() {
      if (this.getLang === '') {
        return this.swedishLang ? 'features' : 'features';
      } else return this.getLang === 'sv' ? 'features' : 'features';
    },
    accountCodeLg() {
      return this.GET_LOCALE === 'sv' ? 'name' : "name_english"
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  mounted() {
    this.getGuides('article_config')
    this.getHelptext('article_config')
    this.getAllRegisters()
    this.getAccountCoding()
    this.getPriceList()
    this.getArticleConfig()

  },
  methods: {
    closeModal() {
    },
    disabledFields() {
      let hidden_object = this.hiddenResource
      try {
        // this.$useJwt.hiddenResource({method: 'get', URL: `/${hidden_object['id']}/`}, {}).then()
        hidden_object['hidden_fields']['configuration']['article_configuration'].map(item => {
          this.hidden_fields[item] = false
        })
      } catch (e) {

      }
    },
    getGuides(config) {
      this.$useJwt.customGuides({URL: `?guide_type=${config}`, method: 'get'})
          .then(res => {
            this.guideConfig = res.data.results
          })
    },
    getArticleConfig() {
      this.accessDenied = false
      this.$useJwt.customConfiguration({URL: '/article', method: 'get'})
          .then(res => {
            this.articleForm = res.data.results[0]
            const accountCodingModes = this.accountCodings;
            // Call setAccount for each coding mode if it exists in the response
            accountCodingModes.forEach(mode => {
              if (res.data.results[0][mode]) {
                // Assuming the backend gives you the full object and you want to extract id and name
                this.setAccount({id: res.data.results[0][mode].id, name: res.data.results[0][mode].name}, mode);
              }
            });
            if (res.data.results[0].unit){
                this.articleForm.unit = res.data.results[0].unit.code
            }
            if (res.data.results[0].article_pricelist){
                this.articleForm.article_pricelist = res.data.results[0].article_pricelist.code
            }
          }).catch(err => {
        if (err.response.status === 403) {
          this.accessDenied = true
        } else {
          this.accessDenied = false
        }
      })
    },
    getHelptext(helptext) {
      this.$useJwt.customHelptext({URL: `?dashboard_page=${helptext}`, method: 'get'})
          .then(res => {
            this.helptext = res.data.results
          })
    },
    renderHelptext(field) {
      const filter = this.helptext.filter(el => el.field === field)
      if (filter.length > 0) {
        return filter[0][this.helpText]
      }
      return ''
    },
    searchAccounts(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteAccounts(loading, search, this);
      }
    },
    searchAccountCoding(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteAccountCoding(loading, search, this);
      }
    },
    getSelectedItemName(nameObj) {
      const selectedItem = this.accountCodingResults.find(item => item.id === nameObj.name);
      if (selectedItem) {
        const accountId = selectedItem.article_account_id || ""; // Use empty string if null
        const name = selectedItem.name || selectedItem.name_english || ""; // Use empty string if null
        return name;
      }
      return '';
    },

    updateArticleSettings() {
      this.$refs.articleForm.validate().then(res => {
        if (res) {
          this.saving = true
          let payload = {}
          Object.keys(this.articleForm).map(key => {
            if (this.articleForm[key] !== null) {
              payload[key] = this.articleForm[key]
            }
          })


          this.$useJwt.customConfiguration({URL: `/article/${payload.uuid}`, method: 'put'}, payload)
              .then(res => {
                this.saving = false
                this.popupMsg(
                    this.$t('Message.Success'),
                    res.data[this.message],
                    'CheckIcon',
                    'success'
                );
              })
              .catch(err => {
                this.saving = false
                if (err.response.data.error) {
                  this.popupMsg(
                      this.$t('Message.Failed'),
                      err.response.data.error,
                      'AlertTriangleIcon',
                      'danger'
                  );
                } else {
                  this.popupMsg(
                      this.$t('Message.Failed'),
                      err.response.data[this.message],
                      'AlertTriangleIcon',
                      'danger'
                  );
                }
              })
        }
      })

    },
    searchRemoteAccounts: debounce(function (loading, search, vm) {
      this.$useJwt.getAllRegisters('accounts?search=' + escape(search))
          .then(res => {
            this.accountResults = res.data.results
            loading(false);
          })
          .catch(err => {
          })
    }, 350),
    searchRemoteAccountCoding: debounce(function (loading, search, vm) {
      this.$useJwt.getAllRegisters('account_codings?search=' + escape(search))
          .then(res => {
            this.accountCodingResults = res.data.results
            loading(false);
          })
          .catch(err => {
          })
    }, 350),
    getAccountCoding() {
      this.$useJwt.getAllRegisters(`account_codings?p=${new Date().getMilliseconds()}`)
          .then(res => {
            this.accountCodingResults = res.data.results
            loading(false);
          })
          .catch(err => {
          })
    },
    setAccount(item, mode) {
      const accountCodingModes = this.accountCodings;
      if (accountCodingModes.includes(mode)) {
        this.articleForm[mode] = item.id;
        try {
          this[mode] = item.name;
        } catch (e) {
          // handle any errors if necessary
        }
      } else {
        this.articleForm[mode] = item.number;
      }
    },
    getPriceList: function () {
      this.$useJwt.getAllRegisters('pricelists')
          .then(res => {
            this.pricelistResults = res.data.results
            loading(false);
          })
          .catch(err => {
          })
    },
    searchPriceList(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemotePricelist(loading, search, this);
      }
    },
    searchRemotePricelist: debounce(function (loading, search, vm) {
      this.$useJwt.getAllRegisters('pricelists?search=' + escape(search))
          .then(res => {
            this.pricelistResults = res.data.results
            loading(false);
          })
          .catch(err => {
          })
    }, 350),
    setPriceList(item, mode) {
      this.articleForm[mode] = item.code
    },
    computeSelect(item, key) {
      this.articleForm[key] = item.value
    },
    async getAllRegisters() {
      const units = await this.$useJwt.getAllRegisters(`units?p=${new Date().getMilliseconds()}`)
      this.units = units.data.results.map(el => {
        return {label: el.description, value: el.code}
      })
    },
  },
  created() {
    this.disabledFields()
  }

};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.account-main{
  margin-top: 10%;
}
</style>
