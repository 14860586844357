<template>
  <div>
    <b-card class="position-relative" :class="{'md-access-denied-height': accessDenied === true}">
      <md-access-denied :is-access-denied="accessDenied" v-if="accessDenied === true" class="mt-40" :height="200"/>
      <div class="flex flex-col">
        <ValidationObserver ref="emailForm">
          <div class="relative w-full">
            <div class="right-0 absolute">
              <span
                  class="cursor-pointer"
                  @click="$bvModal.show('modal-configuration')"
              >
                <mdicon
                    role="button"
                    :width="20"
                    :height="20"
                    name="help-circle-outline"
                />
              </span>
            </div>
          </div>
          <div class="md:grid grid-cols-1 md:grid-cols-2 md:gap-5">
            <div class="flex flex-col mb-1" v-if="hidden_fields.receipt_subject">
              <div>
                <label for="" class="text-base"
                >{{ $t('emailConfig.receiptSubject') }}
                  <span>
                    <span
                        class="cursor-pointer"
                        v-b-tooltip="renderHelptext('receipt_subject')"
                    >
                      <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                      />
                    </span>
                  </span>
                </label>
                <div class="w-full md:w-1/2">
                  <b-form-input
                      name="login-email"
                      v-model="emailForm.receipt_subject"
                      :placeholder="'Ämne'"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-col mb-1" v-if="hidden_fields.reminder_subject">
              <div>
                <label for="" class="text-base"
                >{{ $t('emailConfig.reminderSubject') }}
                  <span>
                    <span
                        class="cursor-pointer"
                        v-b-tooltip="renderHelptext('reminder_subject')"
                    >
                      <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                      />
                    </span>
                  </span>
                </label>
                <div class="w-full md:w-1/2">
                  <b-form-input
                      name="login-email"
                      v-model="emailForm.reminder_subject"
                      :placeholder="'Ämne'"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-col mb-1" v-if="hidden_fields.receipt_text">
              <div>
                <label for="" class="text-base"
                >{{ $t('emailConfig.receiptMessage') }}
                  <span>
                    <span
                        class="cursor-pointer"
                        v-b-tooltip="renderHelptext('receipt_text')"
                    >
                      <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                      />
                    </span>
                  </span>
                </label>
                <b-form-textarea
                    id="textarea"
                    v-model="emailForm.receipt_text"
                    placeholder="Meddelande"
                    rows="3"
                    max-rows="6"
                ></b-form-textarea>
              </div>
            </div>
            <div class="flex flex-col mb-1" v-if="hidden_fields.reminder_text">
              <div>
                <label for="" class="text-base"
                >{{ $t('emailConfig.reminderMessage') }}
                  <span>
                    <span
                        class="cursor-pointer"
                        v-b-tooltip="renderHelptext('reminder_text')"
                    >
                      <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                      />
                    </span>
                  </span>
                </label>
                <b-form-textarea
                    id="textarea"
                    placeholder="Meddelande"
                    v-model="emailForm.reminder_text"
                    rows="3"
                    max-rows="6"
                ></b-form-textarea>
              </div>
            </div>
            <div class="flex flex-col mb-1" v-if="hidden_fields.use_general_mail_server">
              <h2 class="text-xl mt-2 uppercase text-gray-900">
                {{ $t('emailConfig.configurations') }}
                <!-- <span>
                  <span
                      class="cursor-pointer"
                      v-b-tooltip="renderHelptext('use_general_mail_server')"
                  >
                    <mdicon
                        role="button"
                        :width="20"
                        :height="20"
                        name="help-circle-outline"
                    />
                  </span>
                </span> -->
              </h2>
              <div class="flex flex-col">
                <div class="mt-1">
                  <label for="" class="text-base"
                  >{{ $t('emailConfig.replyEmailAddress') }}
                    <span>
                      <span
                          class="cursor-pointer"
                          v-b-tooltip="renderHelptext('reply_email_address')"
                      >
                        <mdicon
                            role="button"
                            :width="20"
                            :height="20"
                            name="help-circle-outline"
                        />
                      </span>
                    </span>
                  </label>
                  <div class="w-1/2">
                    <b-form-input
                        name="reply-email"
                        type="email"
                        v-model="emailForm.reply_email_address"
                        :placeholder="'Mejladress'"
                    />
                  </div>
                </div>
              <!-- <b-form-checkbox
                    :id="'checkbox-'"
                    class="mb-1"
                    name="checkbox-form-1"
                    v-model="emailForm.use_general_mail_server"
                    :value="true"
                >
                  {{ $t('emailConfig.golfigoServer') }}
                </b-form-checkbox>
                <div class="mt-1">
                  <label for="" class="text-base"
                  >{{ $t('emailConfig.mailServer') }}
                    <span>
                      <span
                          class="cursor-pointer"
                          v-b-tooltip="renderHelptext('mail_server')"
                      >
                        <mdicon
                            role="button"
                            :width="20"
                            :height="20"
                            name="help-circle-outline"
                        />
                      </span>
                    </span>
                  </label>
                  <div class="w-1/2">
                    <b-form-input
                        name="login-email"
                        v-model="emailForm.mail_server"
                        :placeholder="'Mejlserver'"
                    />
                  </div>
                </div>
                <div class="mt-1">
                  <label for="" class="text-base"
                  >{{ $t('emailConfig.port') }}
                    <span>
                      <span
                          class="cursor-pointer"
                          v-b-tooltip="renderHelptext('email_port')"
                      >
                        <mdicon
                            role="button"
                            :width="20"
                            :height="20"
                            name="help-circle-outline"
                        />
                      </span>
                    </span>
                  </label>
                  <div class="w-1/2">
                    <b-form-input
                        name="login-email"
                        v-model="emailForm.email_port"
                        :placeholder="'Port'"
                    />
                  </div>
                </div> -->
                <!-- <div class="mt-1">
                  <label for="" class="text-base"
                  >{{ $t('emailConfig.emailAddress') }}
                    <span>
                      <span
                          class="cursor-pointer"
                          v-b-tooltip="renderHelptext('email_address')"
                      >
                        <mdicon
                            role="button"
                            :width="20"
                            :height="20"
                            name="help-circle-outline"
                        />
                      </span>
                    </span>
                  </label>
                  <div class="w-1/2">
                    <b-form-input
                        name="login-email"
                        type="email"
                        v-model="emailForm.email_address"
                        :placeholder="'Mejladress'"
                    />
                  </div>
                </div>
                <div class="mt-1">
                  <label for="" class="text-base"
                  >{{ $t('emailConfig.password') }}
                    <span>
                      <span
                          class="cursor-pointer"
                          v-b-tooltip="renderHelptext('email_password')"
                      >
                        <mdicon
                            role="button"
                            :width="20"
                            :height="20"
                            name="help-circle-outline"
                        />
                      </span>
                    </span>
                  </label>
                  <div class="w-1/2">
                    <b-input-group class="input-group-merge">
                      <b-form-input
                          :type="currentPasswordHidden ? 'password' : 'text'"
                          name="login-email"
                          v-model="emailForm.email_password"
                          :placeholder="'Lösenord'"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                            class="cursor-pointer"
                            :icon="
                            currentPasswordHidden ? 'EyeIcon' : 'EyeOffIcon'
                          "
                            @click="
                            currentPasswordHidden = !currentPasswordHidden
                          "
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </div> -->
              </div>
              <br>
              <div class="col-span-2" v-if="hidden_fields.logo">
                <div>
                    <label for="" class="text-base">{{ $t('emailConfig.logo') }}
                        <span>
                            <span class="cursor-pointer" v-b-tooltip="renderHelptext('banner')">
                                <mdicon role="button" :width="20" :height="20" name="help-circle-outline"/>
                            </span>
                        </span>
                    </label>
                    <div class="md:w-1/4">
                        <input type="file" @change="inputImage" ref="imageInput"/>
                        <!-- Display image preview here if emailForm.banner is not null -->
                        <img v-if="emailForm.banner && !emailForm.bannerPreview" :src="emailForm.banner" alt="Logo Image" />
                        <img v-else-if="emailForm.bannerPreview" :src="emailForm.bannerPreview" alt="Logo Image" />

                        <span v-if="emailForm.banner || emailForm.bannerPreview" @click="removeImage" style="color: darkblue; cursor: pointer;">Ta bort bild</span>
                    </div>
                </div>
              </div>
            </div>
            
            <div class="flex flex-col mb-1">
              <h2 class="text-xl mt-2 uppercase text-gray-900">
                {{ $t('emailConfig.autoTasks') }}
                <span>
                  <span
                      class="cursor-pointer"
                      v-b-tooltip="renderHelptext('auto_send_receipts')"
                  >
                    <mdicon
                        role="button"
                        :width="20"
                        :height="20"
                        name="help-circle-outline"
                    />
                  </span>
                </span>
              </h2>
              <div class="flex flex-col">
                <b-form-checkbox v-if="hidden_fields.auto_send_receipts"
                                 :id="'checkbox-general'"
                                 class="mb-2"
                                 v-model="emailForm.auto_send_receipts"
                                 name="checkbox-form-1"
                                 :value="true"
                >
                  <div class="flex flex-col">
                    <span>
                      {{ $t('emailConfig.autoReceipts') }}
                    </span>
                    <span
                        class="flex flex-col mt-1"
                        v-if="emailForm.auto_send_receipts"
                    >
                      <span class="text-gray-500"
                      >{{ $t('emailConfig.applyTo') }}
                        <span>
                          <span
                              class="cursor-pointer"
                              v-b-tooltip="renderHelptext('receipts_apply_to')"
                          >
                            <mdicon
                                role="button"
                                :width="20"
                                :height="20"
                                name="help-circle-outline"
                            />
                          </span>
                        </span>
                      </span>
                      <b-form-radio
                          name="radio-receipt"
                          class="c-mb-2"
                          v-model="emailForm.receipts_apply_to"
                          value="all"
                      >
                        {{ $t('emailConfig.all') }}
                      </b-form-radio>
                      <b-form-radio
                          name="radio-receipt"
                          class="c-mb-2"
                          v-model="emailForm.receipts_apply_to"
                          value="golf"
                      >
                        {{ $t('emailConfig.golf') }}
                      </b-form-radio>
                    </span>
                  </div>
                </b-form-checkbox>
              </div>

              <div class="flex flex-col">
                    <span
                        class="flex flex-col mt-1"
                        v-if="emailForm.auto_send_receipts"
                    >
                      <span class="text-gray-500"
                      >{{ $t('emailConfig.paymentDateSince') }}
                        <span>
                          <span
                              class="cursor-pointer"
                              v-b-tooltip="renderHelptext('payment_date_since')"
                          >
                            <mdicon
                                role="button"
                                :width="20"
                                :height="20"
                                name="help-circle-outline"
                            />
                          </span>
                        </span>
                      </span>
                      <b-form-group>
                        <ValidationProvider #default="{ errors }" :rules="{'required': emailForm.auto_send_receipts}" :name="$t('emailConfig.payment_date_since')">
                          <div class="datepicker-wrapper">
                            <datepicker :disabledDates="{ to: startDate}" v-model="emailForm.payment_date_since" ref="datepicker-payment_date_since" :placeholder="$t('apiConfig.select_date')"></datepicker>
                            <button @click="showDatepicker('payment_date_since')" class="calendar-button">
                              <mdicon name="calendar" />
                            </button>
                          </div>
                          <small class="text-danger" v-text="errors[0]" />
                        </ValidationProvider>
                      </b-form-group>
                    </span>

                <b-form-checkbox v-if="hidden_fields.auto_send_reminders"
                                 :id="'checkbox-reminder-main'"
                                 class="mb-2"
                                 v-model="emailForm.auto_send_reminders"
                                 name="checkbox-form-1"
                                 :value="true"
                >
                  <div class="flex flex-col">
                    <span>
                      {{ $t('emailConfig.autoReminders') }}
                    </span>
                    <span
                        class="flex flex-col mt-1"
                        v-if="emailForm.auto_send_reminders"
                    >
                      <span class="text-gray-500"
                      >{{ $t('emailConfig.applyTo') }}
                        <span>
                          <span
                              class="cursor-pointer"
                              v-b-tooltip="renderHelptext('receipts_apply_to')"
                          >
                            <mdicon
                                role="button"
                                :width="20"
                                :height="20"
                                name="help-circle-outline"
                            />
                          </span>
                        </span>
                      </span>
                      <b-form-radio v-if="hidden_fields.reminders_apply_to"
                                    name="radio-checkbox-reminder"
                                    class="c-mb-2"
                                    v-model="emailForm.reminders_apply_to"
                                    value="all"
                      >
                        {{ $t('emailConfig.all') }}
                      </b-form-radio>
                      <b-form-radio v-if="hidden_fields.reminders_apply_to"
                                    name="radio-checkbox-reminder"
                                    class="c-mb-2"
                                    v-model="emailForm.reminders_apply_to"
                                    value="golf"
                      >
                        {{ $t('emailConfig.golf') }}
                      </b-form-radio>
                    </span>
                  </div>
                </b-form-checkbox>
                
                <div class="flex flex-col">
                  <span
                    class="flex flex-col mt-1"
                    v-if="emailForm.auto_send_reminders"
                  >
                    <span class="text-gray-500"
                    >{{ $t('emailConfig.dueDateSince') }}
                    <span>
                      <span
                        class="cursor-pointer"
                        v-b-tooltip="renderHelptext('due_date_since')"
                      >
                      <mdicon
                        role="button"
                        :width="20"
                        :height="20"
                        name="help-circle-outline"
                      />
                      </span>
                    </span>
                    </span>
                    <b-form-group>
                    <ValidationProvider #default="{ errors }" :rules="{'required': emailForm.auto_send_reminders}" :name="$t('emailConfig.due_date_since')">
                      <div class="datepicker-wrapper">
                      <datepicker :disabledDates="{ to: startDate}" v-model="emailForm.due_date_since" ref="datepicker-due_date_since" :placeholder="$t('apiConfig.select_date')"></datepicker>
                      <button @click="showDatepicker('due_date_since')" class="calendar-button">
                        <mdicon name="calendar" />
                      </button>
                      </div>
                      <small class="text-danger" v-text="errors[0]" />
                    </ValidationProvider>
                    </b-form-group>
                  </span>
                </div>


                <span
                        class="flex flex-col mt-1"
                        v-if="emailForm.auto_send_reminders"
                    >
                <div class="flex flex-col">
                    <span>
                      {{ $t('emailConfig.daysAfterDueDate') }}
                      <span>
                        <span
                            class="cursor-pointer"
                            v-b-tooltip="
                            renderHelptext('reminders_days_after_duedate')
                          "
                        >
                          <mdicon
                              role="button"
                              :width="20"
                              :height="20"
                              name="help-circle-outline"
                          />
                        </span>
                      </span>
                    </span>
                  </div>
                  <b-form-input
                      class="mb-2"
                      name="days-input"
                      v-model="emailForm.reminders_days_after_duedate"
                  >
                  </b-form-input>
                </span>
                </div>
              </div>
            
            
            <div class="col-span-2 flex justify-center">
              <button
                  @click="saveSettings"
                  type="submit"
                  :disabled="accessDenied"
                  class="flex md:w-1/4 p-1 text-center focus:outline-none bg-theme-teal text-white whitespace-nowrap rounded-md"
              >
                <span class="flex justify-center w-full" v-if="saving">
                  <b-spinner small type="grow"/>
                  {{ $t("Message.loading") }}...
                </span>

                <span
                    class="whitespace-nowrap text-center w-full text-base"
                    v-else
                    v-text="$t(locale.save)"
                />
              </button>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </b-card>
    <b-modal
        id="modal-configuration"
        modal-class="modal-primary"
        ref="modal"
        :title="$t(locale.guide)"
        @close="closeModal"
        @cancel="closeModal"
        @hide="closeModal"
        :hide-footer="true"
    >
      <b-card-text>
        <div v-if="guideConfig.length > 0">
          <h5>{{ guideConfig[0][subject] }}</h5>
          <div v-html="guideConfig[0][textConfig]"></div>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {getSubscribersMixins} from "@/mixins/getSubscribersMixins";
import { required } from 'vee-validate/dist/rules';
import {billingMixins} from "@/mixins/billingMixins";
import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import mdAccessDenied from "@core/components/permission/mdAccessDenied";
import {mapGetters} from "vuex";
import PAGES from "@/services/helpers/constant";
import Datepicker from 'vuejs-datepicker';

extend('required', {
    ...required,
    message: field => i18n.t('validation.required', { field })
  });

export default {
  name: "InvoiceConfigView",
  mixins: [getSubscribersMixins, billingMixins],
  components: {
    ValidationProvider,
    ValidationObserver,
    mdAccessDenied,
    Datepicker
  },
  data() {
    return {
      startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      saving: false,
      accessDenied: false,
      currentPasswordHidden: true,
      hidden_fields: {...JSON.parse(JSON.stringify(PAGES['configuration']['email_configuration']))},
      guideConfig: [],
      emailForm: {
        use_general_mail_server: true,
        mail_server: "",
        email_address: "",
        email_password: "",
        email_port: 465,
        banner: null,
        bannerPreview: null,
        reminder_subject: "",
        reminder_text: "",
        receipt_subject: "",
        receipt_text: "",
        auto_send_receipts: false,
        receipts_apply_to: "golf",
        auto_send_reminders: false,
        reminders_apply_to: "golf",
        reminders_days_after_duedate: 3,
        payment_date_since: null,
        due_date_since: null
      },
      locale: {
        save: "EmailConfig.save",
        guide: "ConfigurationGuide.title",
      },
      newImage: null,
      helptext: [],
    };
  },

  computed: {
    message() {
      return this.GET_LOCALE === "sv" ? "swe_message" : "eng_message";
    },
    subject() {
      return this.GET_LOCALE === "sv" ? "swe_subject" : "eng_subject";
    },
    helpText() {
      return this.GET_LOCALE === "sv" ? "swedish_text" : "english_text";
    },
    textConfig() {
      if (this.GET_LOCALE === "") {
        return this.swedishLang ? "swe_text" : "eng_text";
      } else return this.GET_LOCALE === "sv" ? "swe_text" : "eng_text";
    },
    features() {
      return this.GET_LOCALE === "sv" ? "features" : "features";
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  mounted() {
    this.getGuides("email_config");
    this.getHelptext("email_config");
    this.getEmailConfig();
    this.disabledFields()
  },
  watch: {
    'emailForm.payment_date_since'(newDate) {
      this.emailForm.payment_date_since = this.formatDateToYYYYMMDD(newDate);
    },
    'emailForm.due_date_since'(newDate) {
      this.emailForm.due_date_since = this.formatDateToYYYYMMDD(newDate);
    }
  },
  methods: {
    formatDateToYYYYMMDD(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    showDatepicker(fieldId="") {
      const datepickerRef = `datepicker-${fieldId}`;
      if (this.$refs[datepickerRef]) {
        this.$refs[datepickerRef].showCalendar();
      }
    },
    disabledFields() {
      let hidden_object = this.hiddenResource
      try {
        // this.$useJwt.hiddenResource({method: 'get', URL: `/${hidden_object['id']}/`}, {}).then()
        hidden_object['hidden_fields']['configuration']['email_configuration'].map(item => {
          this.hidden_fields[item] = false
        })
      } catch (e) {

      }
    },
    closeModal() {
    },
    getGuides(config) {
      this.$useJwt
          .customGuides({URL: `?guide_type=${config}`, method: "get"})
          .then((res) => {
            this.guideConfig = res.data.results;
          });
    },
    getEmailConfig() {
      this.accessDenied = false
      this.$useJwt
          .customConfiguration({URL: "/email", method: "get"})
          .then((res) => {
            this.emailForm = res.data.results[0];
          }).catch(err => {
        if (err.response.status === 403) {
          this.accessDenied = true
        } else {
          this.accessDenied = false
        }
      });
    },
    getHelptext(helptext) {
      this.$useJwt
          .customHelptext({URL: `?dashboard_page=${helptext}`, method: "get"})
          .then((res) => {
            this.helptext = res.data.results;
          });
    },
    renderHelptext(field) {
      const filter = this.helptext.filter((el) => el.field === field);
      if (filter.length > 0) {
        return filter[0][this.helpText];
      }
      return "";
    },
    removeImage: function() {
        this.emailForm.banner = null;
        this.emailForm.bannerPreview = null;
        this.$refs.imageInput.value = null; // Reset the input field
    },
    saveSettings() {
      this.$refs.emailForm.validate().then((res) => {
        if (res) {
          this.saving = true;
          if (this.emailForm.banner && this.emailForm.banner instanceof File) {
            const formdata = new FormData();
            Object.keys(this.emailForm).map((el) => {
              formdata.append(el, this.emailForm[el]);
            });

            this.$useJwt
                .customConfiguration(
                    {URL: `/email/${this.emailForm.uuid}`, method: "put"},
                    formdata,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                )
                .then((res) => {
                  this.saving = false;
                  this.popupMsg(
                      this.$t("Message.Success"),
                      res.data[this.message],
                      "CheckIcon",
                      "success"
                  );
                })
                .catch((err) => {
                  this.saving = false;
                  if (err.response.data.error) {
                    this.popupMsg(
                        this.$t("Message.Failed"),
                        err.response.data.message,
                        "AlertTriangleIcon",
                        "danger"
                    );
                  } else {
                    this.popupMsg(
                        this.$t("Message.Failed"),
                        err.response.data[this.message],
                        "AlertTriangleIcon",
                        "danger"
                    );
                  }
                });
          } else {
            const payload = {};
            Object.keys(this.emailForm).map((el) => {
              payload[el] = this.emailForm[el];
            });

            if (typeof this.emailForm.banner === 'string') {
              delete payload.banner; // Exclude the banner if it's a string
            } // If banner is null, it remains in the payload as null

            this.$useJwt
                .customConfiguration(
                    {URL: `/email/${this.emailForm.uuid}`, method: "put"},
                    payload
                )
                .then((res) => {
                  this.saving = false;
                  this.popupMsg(
                      this.$t("Message.Success"),
                      res.data[this.message],
                      "CheckIcon",
                      "success"
                  );
                })
                .catch((err) => {
                  this.saving = false;
                  if (err.response.data.error) {
                    this.popupMsg(
                        this.$t("Message.Failed"),
                        err.response.data.error,
                        "AlertTriangleIcon",
                        "danger"
                    );
                  } else {
                    this.popupMsg(
                        this.$t("Message.Failed"),
                        err.response.data[this.message],
                        "AlertTriangleIcon",
                        "danger"
                    );
                  }
                });
          }
        }
      });
    },
    inputImage() {
      const acceptedType = ["png", "jpg", "jpeg"];
      const files = this.$refs.imageInput.files[0];
      const fileType = files.type.split("/")[1];
      const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes

      if (acceptedType.includes(fileType)) {
        if (files.size > maxFileSize) {
          this.popupMsg(
              this.$t("Message.Failed"),
              "Filen är för stor (max. 2 MB).",
              "AlertTriangleIcon",
              "danger"
          );
          this.emailForm.bannerPreview = null;
          this.emailForm.banner = null;
          return;
        }

        var reader = new FileReader();

        reader.addEventListener("load", () => {
          this.$set(this.emailForm, 'bannerPreview', reader.result);
        });

        reader.readAsDataURL(files);
        this.emailForm.banner = files;
      } else {
        this.danger = true;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.datepicker-wrapper {
  position: relative;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  cursor: pointer;
  width: 70%; 
}

.datepicker-wrapper input {
  border: none;
  outline: none;
  width: 100%;
  padding-right: 30px;
}

.calendar-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none;
  cursor: pointer;
  /* Style as needed */
}

.calendar-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
