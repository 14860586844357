<template>
    <b-modal
        id="change-modal-users"
        ref="modalEdit"
        ok-variant="primary"
        :ok-title="$t(locale.modalConfirm)"
        :cancel-title="$t(locale.modalCancel)"
        cancel-variant="outline-secondary"
        modal-class="modal-primary"
        :ok-disabled="disableBtn"
        centered
        @ok="confirmMyChoose"
        @close="closeModal"
        @cancel="closeModal"
        @hide="closeModal"
        clickToClose
        :title="$t(locale.titleUpdate)"
    >
        <b-card-text
            class="d-flex flex-column justify-content-center"
            style="gap: 1rem"
        >
            <p class="text-center font-weight-bold" v-text="$t(locale.title)" />

            <p class="text-secondary">
                {{
                    $t(locale.subtitle1, {
                        number: limitUser,
                        current_number: numberCurrentUser,
                    })
                }}
            </p>

            <b-form-group class="ml-2">
                <b-form-checkbox
                    v-for="(option, index) in usersOptions"
                    :key="index"
                    v-model="selectedUsers"
                    :value="index"
                    @change="checkValidation"
                    name="flavour-3a"
                >
                    {{ option.text }}
                </b-form-checkbox>
            </b-form-group>

            <!-- <p class="text-secondary">
                {{
                    $t(locale.subtitle2, {
                        number: limitResource,
                        current_number: selectedResource.length,
                    })
                }}
            </p>

            <b-form-group class="ml-2">
                <b-form-checkbox
                    v-for="option in resourceList"
                    :key="option.value"
                    v-model="selectedResource"
                    @change="checkValidation"
                    :value="option.value"
                    name="flavour-3a"
                >
                    {{ option.text }}
                </b-form-checkbox>
            </b-form-group> -->
        </b-card-text>
    </b-modal>
</template>

<script>
import {settingsMixins} from '@/mixins/settingsMixins';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
export default {
    mixins: [settingsMixins],
    props: {
        currentUuid: {
            type: String,
        },
        limitUser: {
            type: Number,
        },
        limitResource: {
            type: Number,
        },
        openModal: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedUsers: [],
            usersOptions: [],
            usersOptionsCopy: '',
            numberCurrentUser: '',
            disableBtn: true,
            locale: {
                title: 'SubscriptionCard.modalTitle',
                modalConfirm: 'SubscriptionCard.ModalConfirm',
                modalCancel: 'SubscriptionCard.ModalCancel',
                titleUpdate: 'SubscriptionCard.update',
                subtitle1: 'SubscriptionCard.modalSutitle1',
                subtitle2: 'SubscriptionCard.modalSutitle2',
            },
        };
    },
    watch: {
        selectedUsers(value) {
            const valueLength = value.length;
            if (valueLength > 0 && valueLength <= this.limitUser) {
                this.disableBtn = false;
            } else this.disableBtn = true;
        },
    },

    methods: {
        checkValidation(value) {
            if (
                this.selectedUsers.length > 0 &&
                this.selectedUsers.length <= this.limitUser
            ) {
                this.disableBtn = false;
            } else this.disableBtn = true;
        },
        confirmMyChoose(event) {
            event.preventDefault();

            this.$toast({
                component: ToastificationContent,
                props: {
                    title: this.$t('Message.wait'),
                    variant: 'warning',
                    waiting: true,
                },
            });
            if (!this.disableBtn) {
                let parseRemoveUSers = JSON.parse(this.usersOptionsCopy);

                for (let i = this.selectedUsers.length - 1; i >= 0; i--) {
                    parseRemoveUSers.splice(this.selectedUsers[i], 1);
                }

                this.$emit('finalStepsUpdate');
                this.$emit('selectedUsers', parseRemoveUSers);
                this.$emit('usersOptions', this.usersOptions);

                this.$emit('modalEditElement', this.$refs.modalEdit);
            }
        },

        closeModal() {
            this.$emit('closeModal');
        },

        deleteUsers() {
            let uuid = '';

            this.selectedUsers.forEach(element => {
                uuid = this.usersOptions
                    .filter(users => users.value !== element)
                    .map(user => user.value);
            });

            uuid.forEach(uuid => {
                this.$useJwt.deleteUser(uuid).then(res => {
                    return true;
                });
            });
            return true;
        },
        updateConfigurations() {
            return new Promise(resolve => {
                this.selectedResource.forEach(resource => {
                    this.$useJwt
                        .updateConfigure(this.userData.user_uuid, {
                            resources_to_check: [
                                {
                                    resource: resource,
                                },
                            ],
                        })
                        .then(res => {
                        })
                        .finally(() => true);
                });
            });
        },
        getUsers() {
            this.$useJwt.getUsers().then(res => {
                const {data} = res.data;
                const dataArray = [];
                data.forEach(element => {
                    dataArray.push({
                        text: `${element.full_name} 
                        ${element.role ? ',' : ''} ${element.role || ''}`,
                        value: element.uuid,
                    });
                });
                this.numberCurrentUser = dataArray.length;
                this.usersOptions = dataArray;

                this.usersOptionsCopy = JSON.stringify(dataArray);
            });
        },
    },

    async mounted() {
        this.getUsers();
    },
};
</script>

<style></style>
