<template>
  <div>
    <b-card class="position-relative" :class="{'md-access-denied-height': accessDenied === true}">
      <md-access-denied :is-access-denied="accessDenied" v-if="accessDenied === true" class="mt-40" :height="200"/>
      <div class="flex flex-col font-rubik">
        <div class="relative w-full">
          <div class="right-0 absolute">
            <span
                class="cursor-pointer"
                @click="$bvModal.show('modal-configuration')"
            >
              <mdicon
                  role="button"
                  :width="20"
                  :height="20"
                  name="help-circle-outline"
              />
            </span>
          </div>
        </div>
        <h3 class="text-blue-900 text-lg mb-1 font-rubik">{{ $t('contractConfig.contractInvoices') }}</h3>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div class="col-span-2 flex flex-col">
            <div class="flex items-center gap-10 md:gap-20">
              <label for="" class="text-base"
              >{{ $t('contractConfig.autoCreateInvoices') }}
                <span>
                  <span
                      class="cursor-pointer"
                      v-b-tooltip="renderHelptext('auto_create_invoice')"
                  >
                    <mdicon
                        role="button"
                        :width="20"
                        :height="20"
                        name="help-circle-outline"
                    />
                  </span>
                </span>
              </label>
              <div class="">
                <span class="flex gap-5 mt-1" v-if="hidden_fields.auto_create_invoice">

                  <div >
                    <span>{{ $t('contractConfig.yes') }}</span>
                    <b-form-radio
                        name="radio-auto-create"
                        class="c-mb-2"
                        v-model="contractForm.auto_create_invoice"
                        :value="true"
                    >
                    </b-form-radio>
                  </div>
                  <div>
                    <span>{{ $t('contractConfig.no') }}</span>
                    <b-form-radio
                        name="radio-auto-create"
                        class="c-mb-2"
                        v-model="contractForm.auto_create_invoice"
                        :value="false"
                    >
                    </b-form-radio>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div class="col-span-2 flex flex-col">
            <h3 class="text-blue-900 text-lg mb-1 font-rubik">
              {{ $t('contractConfig.basedOn') }}
              <span
                  class="cursor-pointer"
                  v-b-tooltip="renderHelptext('create_invoice_based_on')"
              >
                <mdicon
                    role="button"
                    :width="20"
                    :height="20"
                    name="help-circle-outline"
                />
              </span>
            </h3>
            <span class="flex flex-col mt-1" v-if="hidden_fields.create_invoice_based_on">
              <b-form-radio
                  name="radio-create-inv"
                  class="c-mb-2"
                  :disabled="!contractForm.auto_create_invoice"
                  v-model="contractForm.create_invoice_based_on"
                  value="Period start date"
              >
                {{ $t('contractConfig.periodStart') }}
              </b-form-radio>
              <b-form-radio
                  name="radio-create-inv"
                  class="c-mb-2"
                  :disabled="!contractForm.auto_create_invoice"
                  v-model="contractForm.create_invoice_based_on"
                  value="Calendar date"
              >
                {{ $t('contractConfig.calendarDay') }}
              </b-form-radio>
            </span>

            <div class="flex flex-col mt-2 mb-1">
              <span class="flex relative flex-wrap gap-2 items-center" v-if="hidden_fields.days_before_period_start">
                <span>{{ $t('contractConfig.createInvoices') }}</span>
                <span class="w-32 block">
                  <b-form-input
                      type="number"
                      :disabled="
                      !contractForm.auto_create_invoice ||
                      contractForm.create_invoice_based_on !==
                        'Period start date'
                    "
                      name="login-email"
                      v-model="contractForm.days_before_period_start"
                      :placeholder="''"
                  />
                </span>
                <span> {{ $t('contractConfig.beforePeriodStart') }}</span>
                <span class="right-0 -mt-2">
                  <span
                      class="cursor-pointer"
                      v-b-tooltip="renderHelptext('days_before_period_start')"
                  >
                    <mdicon
                        role="button"
                        :width="20"
                        :height="20"
                        name="help-circle-outline"
                    />
                  </span>
                </span>
              </span>
            </div>

            <div class="flex flex-col">
              <span class="flex flex-wrap gap-2 items-center">
                <span>{{ $t('contractConfig.createInvoices') }} {{ $t('contractConfig.onThe') }}</span>
                <span class="w-32 flex block" v-if="hidden_fields.day_of_every_month">
                  <b-form-input
                      type="number"
                      name="login-email"
                      :disabled="
                      !contractForm.auto_create_invoice ||
                      contractForm.create_invoice_based_on !== 'Calendar date'
                    "
                      v-model="contractForm.day_of_every_month"
                      :placeholder="''"
                  />
                  <span class="right-0 -mt-2">
                    <span
                        class="cursor-pointer"
                        v-b-tooltip="renderHelptext('day_of_every_month')"
                    >
                      <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                      />
                    </span>
                  </span>
                </span>
                <span> {{ $t('contractConfig.everyMonth') }}</span>
                <span class="w-32 flex block" v-if="hidden_fields.days_from_period_start">
                  <b-form-input
                      name="login-email"
                      type="number"
                      :disabled="
                      !contractForm.auto_create_invoice ||
                      contractForm.create_invoice_based_on !== 'Calendar date'
                    "
                      v-model="contractForm.days_from_period_start"
                      :placeholder="''"
                  />
                  <span class="right-0 -mt-2">
                    <span
                        class="cursor-pointer"
                        v-b-tooltip="renderHelptext('days_from_period_start')"
                    >
                      <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                      />
                    </span>
                  </span>
                </span>
                <span> {{ $t('contractConfig.daysFromDay') }}</span>
              </span>
            </div>

            <hr/>
          </div>

          <div class="col-span-2 flex flex-col">
            <h3 class="text-blue-900 text-lg mb-1 font-rubik">{{ $t('contractConfig.sendInvoices') }}</h3>
            <div
                class="grid grid-cols-1 md:grid-cols-2 w-full md:w-1/2 md:gap-10"
            >
              <div v-if="hidden_fields.auto_send_invoices">
                <span class="flex flex-col">
                  <span class="text-gray-500 mb-1"
                  >{{ $t('contractConfig.autoSendInvoices') }}
                    <span>
                      <span
                          class="cursor-pointer"
                          v-b-tooltip="renderHelptext('auto_send_invoices')"
                      >
                        <mdicon
                            role="button"
                            :width="20"
                            :height="20"
                            name="help-circle-outline"
                        />
                      </span>
                    </span>
                  </span>
                  <b-form-radio
                      name="radio-app-auto"
                      class="c-mb-2"
                      v-model="contractForm.auto_send_invoices"
                      :disabled="!contractForm.auto_create_invoice"
                      :value="true"
                  >
                    {{ $t('contractConfig.yes') }}
                  </b-form-radio>
                  <b-form-radio
                      v-model="contractForm.auto_send_invoices"
                      name="radio-app-auto"
                      class="c-mb-2"
                      :disabled="!contractForm.auto_create_invoice"
                      :value="false"
                  >
                    {{ $t('contractConfig.no') }}
                  </b-form-radio>
                </span>
              </div>
              <div v-if="hidden_fields.use_customer_default_send_method">
                <span class="flex flex-col">
                  <span class="text-gray-500 mb-1"
                  >{{ $t('contractConfig.useDefaultSendMethod') }}
                    <span>
                      <span
                          class="cursor-pointer"
                          v-b-tooltip="
                          renderHelptext('use_customer_default_send_method')
                        "
                      >
                        <mdicon
                            role="button"
                            :width="20"
                            :height="20"
                            name="help-circle-outline"
                        />
                      </span>
                    </span>
                  </span>
                  <b-form-radio
                      name="radio-customer-default"
                      class="c-mb-2"
                      v-model="contractForm.use_customer_default_send_method"
                      :disabled="!contractForm.auto_create_invoice"
                      :value="true"
                  >
                    {{ $t('contractConfig.yes') }}
                  </b-form-radio>
                  <b-form-radio
                      name="radio-customer-default"
                      class="c-mb-2"
                      v-model="contractForm.use_customer_default_send_method"
                      :disabled="!contractForm.auto_create_invoice"
                      :value="false"
                  >
                    {{ $t('contractConfig.no') }}
                  </b-form-radio>
                </span>
              </div>

              <div v-if="hidden_fields.send_invoice_with_fortnox_finance">
                <span class="flex flex-col">
                  <span class="text-gray-500 mb-1"
                  >{{ $t('contractConfig.noxFinance') }}
                    <span>
                      <span
                          class="cursor-pointer"
                          v-b-tooltip="
                          renderHelptext('send_invoice_with_fortnox_finance')
                        "
                      >
                        <mdicon
                            role="button"
                            :width="20"
                            :height="20"
                            name="help-circle-outline"
                        />
                      </span>
                    </span>
                  </span>
                  <b-form-radio
                      name="radio-fortnox-finance"
                      class="c-mb-2"
                      v-model="contractForm.send_invoice_with_fortnox_finance"
                      :disabled="!contractForm.auto_create_invoice"
                      value="All"
                  >
                    {{ $t('contractConfig.all') }}
                  </b-form-radio>
                  <b-form-radio
                      name="radio-fortnox-finance"
                      class="c-mb-2"
                      v-model="contractForm.send_invoice_with_fortnox_finance"
                      :disabled="!contractForm.auto_create_invoice"
                      value="Some"
                  >
                    {{ $t('contractConfig.some') }}
                  </b-form-radio>
                  <b-form-radio
                      name="radio-fortnox-finance"
                      class="c-mb-2"
                      v-model="contractForm.send_invoice_with_fortnox_finance"
                      :disabled="!contractForm.auto_create_invoice"
                      value="None"
                  >
                    {{ $t('contractConfig.none') }}
                  </b-form-radio>
                </span>
              </div>

              <div v-if="hidden_fields.send_all_invoices_through">
                <span class="flex flex-col">
                  <span class="text-gray-500 mb-1"
                  >{{ $t('contractConfig.sendThrough') }}
                    <span>
                      <span
                          class="cursor-pointer"
                          v-b-tooltip="
                          renderHelptext('send_all_invoices_through')
                        "
                      >
                        <mdicon
                            role="button"
                            :width="20"
                            :height="20"
                            name="help-circle-outline"
                        />
                      </span>
                    </span>
                  </span>
                  <b-form-radio
                      name="radio-1-through"
                      class="c-mb-2"
                      :disabled="contractForm.use_customer_default_send_method || !contractForm.auto_create_invoice"
                      v-model="contractForm.send_all_invoices_through"
                      value="Email"
                  >
                    {{ $t('contractConfig.email') }}
                  </b-form-radio>
                  <b-form-radio
                      name="radio-1-through"
                      class="c-mb-2"
                      :disabled="contractForm.use_customer_default_send_method || !contractForm.auto_create_invoice"
                      v-model="contractForm.send_all_invoices_through"
                      value="Letter"
                  >
                    {{ $t('contractConfig.letter') }}
                  </b-form-radio>
                </span>
              </div>
            </div>

            <div class="flex flex-col justify-between w-full md:w-3/4 mt-2">
              <span
                  class="flex flex-wrap gap-2 items-center"
                  v-for="(row, index) in contractForm.config_rows"
                  :key="index"
              >
                <span>{{ $t('contractConfig.sendInvoicesWith') }}</span>
                <span class="w-32 block">
                  <b-form-group>
                    <select
                        v-model="row.type"
                        :disabled="
                        contractForm.send_invoice_with_fortnox_finance !==
                          'Some' || !contractForm.auto_create_invoice
                      "
                        class="w-full flex focus:outline-none c-p-2 bg-white custom-border"
                    >
                      <option value="terms_of_payment">
                        {{ $t(locale.paymentTerm) }}
                      </option>
                      <option value="contract_amount">
                        {{ $t(locale.amount) }}
                      </option>
                      <!-- <option :value="item.value" v-for="item in termsOfPayment" :key="item.id">{{ item.label }}</option> -->
                    </select>
                  </b-form-group>
                </span>
                <span class="w-32 block">
                  <b-form-group>
                    <select
                        class="w-full flex focus:outline-none c-p-2 bg-white custom-border"
                        v-model="row.comparison"
                        :disabled="
                        contractForm.send_invoice_with_fortnox_finance !==
                          'Some' || !contractForm.auto_create_invoice
                      "
                    >
                      <option :value="values[0].key">
                        {{ values[0].name }}
                      </option>
                      <option
                          v-if="row.type !== 'terms_of_payment'"
                          :value="values[1].key"
                      >
                        {{ values[1].name }}
                      </option>
                      <option
                          v-if="row.type !== 'terms_of_payment'"
                          :value="values[2].key"
                      >
                        {{ values[2].name }}
                      </option>
                    </select>
                  </b-form-group>
                </span>

                <span class="w-32 block">
                  <b-form-group>
                    <select
                        class="w-full flex focus:outline-none c-p-2 bg-white custom-border"
                        v-if="row.type === 'terms_of_payment'"
                        v-model="row.condition"
                        :disabled="
                        contractForm.send_invoice_with_fortnox_finance !==
                          'Some' || !contractForm.auto_create_invoice
                      "
                    >
                      <option
                          :value="item.value"
                          v-for="item in termsOfPayment"
                          :key="item.id"
                      >
                        {{ item.label }}
                      </option>
                    </select>
                    <b-form-input
                        class="w-full flex focus:outline-none c-p-2 bg-white custom-border"
                        v-else
                        v-model="row.condition"
                        :disabled="
                        contractForm.send_invoice_with_fortnox_finance !==
                          'Some' || !contractForm.auto_create_invoice
                      "
                    >
                    </b-form-input>
                  </b-form-group>
                </span>

                <span>{{ $t('contractConfig.withNox') }}</span>
                <span
                    @click="removeConfigRow(index)"
                    class="h-5 w-5 flex justify-center items-center cursor-pointer rounded shadow"
                >
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-red-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                  >
                    <path
                        fill-rule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </span>
              <div>
                <b-button
                    size="sm"
                    class="bg-theme-sidebar"
                    @click="addConfigRow"
                >
                  <template>
                    <span
                        class="h-5 w-5 flex justify-center items-center cursor-pointer rounded shadow"
                    >
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6 text-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                      >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        />
                      </svg>
                    </span>
                  </template>
                </b-button>
              </div>

              <!-- <span class="flex flex-wrap gap-2 items-center">
                                <span>Send invoices with</span>
                                <span class="w-32 block" v-for="i in 3" :key="i">
                                    <b-form-select
                                        id="perPageSelect"
                                        size="md"
                                        inline
                                    >
                                    </b-form-select>
                                </span>
                                
                                <span>with Fortnox Finance</span>
                                <span class="h-5 w-5 bg-blue-900 flex justify-center items-center cursor-pointer rounded shadow">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                </span>
                            </span> -->
            </div>
            <hr/>
          </div>

          <div class="col-span-2 flex flex-col">
            <h3 class="text-blue-900 text-lg mb-1 font-rubik">
              {{ $t('contractConfig.bookkeepInvoices') }}
            </h3>
            <div class="flex flex-col mb-3">
              <div v-if="hidden_fields.auto_bookkeep_created_invoices">
                <span class="flex flex-col">
                  <span class="text-gray-500 mb-1"
                  >{{ $t('contractConfig.autoBookInvoices') }}
                    <span>
                      <span
                          class="cursor-pointer"
                          v-b-tooltip="renderHelptext('auto_book_invoices')"
                      >
                        <mdicon
                            role="button"
                            :width="20"
                            :height="20"
                            name="help-circle-outline"
                        />
                      </span>
                    </span>
                  </span>
                  <b-form-radio
                      name="radio-bookkeep"
                      v-model="contractForm.auto_bookkeep_created_invoices"
                      :disabled="!contractForm.auto_create_invoice"
                      class="c-mb-2"
                      :value="true"
                  >
                    {{ $t('contractConfig.yes') }}
                  </b-form-radio>
                  <b-form-radio
                      name="radio-bookkeep"
                      v-model="contractForm.auto_bookkeep_created_invoices"
                      :disabled="!contractForm.auto_create_invoice"
                      class="c-mb-2"
                      :value="false"
                  >
                    {{ $t('contractConfig.no') }}
                  </b-form-radio>
                </span>
              </div>
            </div>

            <hr/>
          </div>

          <div class="col-span-2 flex flex-col">
            <h3 class="text-blue-900 text-lg mb-1 font-rubik">
              {{ $t('contractConfig.renewContracts') }}
            </h3>
            <div
                class="grid grid-cols-1 md:grid-cols-3 w-full md:w-11/12 md:gap-10"
            >
              <div v-if="hidden_fields.auto_renew_contracts">
                <span class="flex flex-col">
                  <span class="text-gray-500 mb-1"
                  >{{ $t('contractConfig.autoRenewContracts') }}
                    <span>
                      <span
                          class="cursor-pointer"
                          v-b-tooltip="renderHelptext('auto_renew_contracts')"
                      >
                        <mdicon
                            role="button"
                            :width="20"
                            :height="20"
                            name="help-circle-outline"
                        />
                      </span>
                    </span>
                  </span>
                  <b-form-radio
                      name="radio-renew"
                      v-model="contractForm.auto_renew_contracts"
                      :value="true"
                      class="c-mb-2"
                  >
                    {{ $t('contractConfig.yes') }}
                  </b-form-radio>
                  <b-form-radio
                      name="radio-renew"
                      v-model="contractForm.auto_renew_contracts"
                      :value="false"
                      class="c-mb-2"
                  >
                    {{ $t('contractConfig.no') }}
                  </b-form-radio>
                </span>
              </div>
              <div v-if="hidden_fields.use_current_article_data">
                <span class="flex flex-col">
                  <span class="text-gray-500 mb-1"
                  >{{ $t('contractConfig.currentPrice') }}
                  </span>
                  <b-form-radio
                      name="radio-apply-current"
                      class="c-mb-2"
                      v-model="contractForm.use_current_article_data"
                      :value="true"
                  >
                    {{ $t('contractConfig.yes') }}
                  </b-form-radio>
                  <b-form-radio
                      name="radio-apply-current"
                      class="c-mb-2"
                      v-model="contractForm.use_current_article_data"
                      :value="false"
                  >
                    {{ $t('contractConfig.no') }}
                  </b-form-radio>
                </span>
              </div>

              <div class="col-span-1">
                <span
                    class="flex flex-col bg-blue-500 shadow-md rounded-md p-1 text-white"
                >
                  *{{ $t('contractConfig.noteText') }}
                </span>
              </div>
            </div>

            <div class="mt-3">
              <span class="items-center">
                <span
                    class="text-theme-sidebar cursor-pointer"
                    @click="modalVisible = true"
                >{{ $t('contractConfig.clickHere') }}</span
                >
                {{ $t('contractConfig.toExclude') }}
              </span>
            </div>

            <hr/>
          </div>


          <div class="col-span-2 flex justify-end">
            <button
                @click="updateConfigWithExcludeContract"
                :class="saving ? 'bg-blue-300' : 'bg-blue-900'"
                :disabled="saving || accessDenied"
                type="submit"
                class="flex md:w-1/4 p-1 text-center focus:outline-none text-white whitespace-nowrap rounded-md"
            >
              <span class="w-full flex justify-center" v-if="saving">
                <b-spinner small type="grow"/>
                {{ $t("Message.loading") }}...
              </span>

              <span
                  class="whitespace-nowrap text-center w-full text-base"
                  v-else
                  v-text="$t(locale.save)"
              />
            </button>
          </div>
        </div>
      </div>
    </b-card>
    <CustomNoHeaderModalVue
        v-if="modalVisible"
        width="w-11/12 md:w-1/2"
        :title="$t(locale.cancel)"
        @handleClose="modalVisible = false"
        :close="!true"
    >
      <div class="flex flex-col" slot="header">
        <div
            class="flex flex-col md:flex-row md:justify-between md:items-center gap-5 border-b p-1"
        >
          <div class="flex flex-col">
            <h2
                class="text-xl md:text-2xl font-semibold font-poppins text-gray-900"
            >
              {{ $t('contractConfig.contractsToExclude') }}
            </h2>
            <p>
              {{ $t('contractConfig.excludeInfoText') }}
            </p>
          </div>
          <div class="w-1/2">
            <b-form-input
                name="login-email"
                @input="searchContractField"
                :placeholder="'Sök'"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col" slot="body">
        <div class="mb-1 overflow-y-scroll" style="height: 300px">
          <span class="w-full flex justify-center" v-if="loadingTable">
            <b-spinner small type="grow"/>
          </span>
          <table class="table w-full z-50 relative mb-7" v-else>
            <thead class="">
            <tr>
              <th class="text-left whitespace-nowrap p-2 md:p-0">
                  <span class="text-xs text-gray-400">
                    <b-form-checkbox
                        v-model="selected"
                        @input="performAllCustomerChecked"
                        :value="checked"
                    />
                  </span>
              </th>
              <th class="text-left whitespace-nowrap p-2 md:p-0">
                <span class="text-xs text-gray-400">{{ $t('contractConfig.contractNumber') }}</span>
              </th>
              <th class="text-left whitespace-nowrap p-2 md:p-0">
                <span class="text-xs text-gray-400">{{ $t('contractConfig.customerName') }}</span>
              </th>
              <th class="text-left whitespace-nowrap p-2 md:p-0">
                <span class="text-xs text-gray-400">{{ $t('contractConfig.contractEndDate') }}</span>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in allContract.data" :key="item.uuid">
              <td>
                  <span>
                    <b-form-checkbox
                        v-model="item.checked"
                        @change="performAllSingleChecked"
                    />
                  </span>
              </td>
              <td>
                <span>{{ item.document_number }}</span>
              </td>
              <td>
                <span>{{ item.customer_name }}</span>
              </td>
              <td>
                <span>{{ item.period_end }}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <hr/>
        <div class="w-full flex justify-end mb-2">
          <paginate
              v-model="currentPage"
              :page-count="allContract.total_pages"
              :click-handler="firePagination"
              :prev-text="'<'"
              :next-text="'>'"
              :page-class="'page-item c-mr-1 border-unset'"
              prev-link-class="page-link c-mr-1 border-unset"
              next-link-class="page-link c-mr-1 border-unset"
              next-class="next-item"
              prev-class="prev-item"
              page-link-class="page-link c-mr-1 border-unset"
              active-class="active c-mr-1 border-unset"
              :container-class="'pagination  b-pagination'"
          >
          </paginate>
        </div>
        <div class="flex justify-end mt-1">
          <button
              @click="modalVisible = false"
              class="flex md:w-1/4 p-1 text-center focus:outline-none bg-blue-600 text-white whitespace-nowrap rounded-md"
          >
            <span class="w-full flex justify-center" v-if="saving">
              <b-spinner small type="grow"/>
              {{ $t("Message.loading") }}...
            </span>

            <span
                class="whitespace-nowrap text-center w-full text-base"
                v-else
                v-text="$t(locale.save)"
            />
          </button>
        </div>
      </div>
    </CustomNoHeaderModalVue>
    <b-modal
        id="modal-configuration"
        modal-class="modal-primary"
        ref="modal"
        :title="$t(locale.guide)"
        @close="closeModal"
        @cancel="closeModal"
        @hide="closeModal"
        :hide-footer="true"
    >
      <b-card-text>
        <div v-if="guideConfig.length > 0">
          <h5>{{ guideConfig[0][subject] }}</h5>
          <div v-html="guideConfig[0][textConfig]"></div>
        </div>
      </b-card-text>
    </b-modal>
    <Modal
        id="modal-add-invoice-to-exclude"
        :hideFooter="true"
        :hide-footer="true"
    >
      <b-card-text
          class="d-flex flex-column text-center align-items-center justify-content-center"
          style="gap: 1rem"
      >

      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('modal-remove-customer-segment')"
        >
                <span
                    v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"

        >
                <span
                    v-text="'Proceed'"
                />
        </b-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import {getSubscribersMixins} from "@/mixins/getSubscribersMixins";
import {billingMixins} from "@/mixins/billingMixins";
import CustomNoHeaderModalVue from "@/@core/customComponent/CustomNoHeaderModal.vue";
import vSelect from "vue-select";
import debounce from "lodash.debounce";
import Paginate from "vuejs-paginate";

import mdAccessDenied from "@core/components/permission/mdAccessDenied";
import {mapGetters} from "vuex";
import PAGES from "@/services/helpers/constant";

export default {
  name: "ContractConfigView",
  mixins: [getSubscribersMixins, billingMixins],
  components: {
    vSelect,
    paginate: Paginate,
    CustomNoHeaderModalVue,
    mdAccessDenied
  },
  data() {
    return {
      saving: false,
      modalVisible: false,
      accessDenied: false,
      currentPage: 1,
      guideConfig: [],
      hidden_fields: {...JSON.parse(JSON.stringify(PAGES['configuration']['contract_configuration']))},
      helptext: [],
      contractForm: {
        config_rows: [],
        auto_create_invoice: false,
        create_invoice_based_on: "",
        use_x_day_before_period_start: false,
        days_before_period_start: null,
        day_of_every_month: null,
        days_from_period_start: null,
        auto_bookkeep_created_invoices: false,
        auto_send_invoices: false,
        use_customer_default_send_method: false,
        send_invoice_with_fortnox_finance: "All",
        send_all_invoices_through: "Email",
        auto_renew_contracts: false,
        use_current_article_data: false,
        contracts_to_exclude_from_auto_renewal: null,
        todo_with_inactive_articles: "include",
        continuous_terminated_contracts: false,
        continuous_inactivated_contracts: false,
        fixed_contracts_terminated_prior_to_end: false,
        fixed_contracts_terminated_on_end: false,
        all_contracts: false,
        all_contracts_that_are: "terminated",
        contract_comment_containing: "",
      },
      locale: {
        save: "ContractConfig.save",
        guide: "ConfigurationGuide.title",
        amount: "dashboardCard.table.amountHead",
        paymentTerm: "InvoiceCardCustomerEdit.paymentTerms",
      },
      termsOfPayment: [],
      values: [
        {
          key: "=",
          name: "equal",
        },
        {
          key: "<",
          name: "less",
        },
        {
          key: ">",
          name: "greater",
        },
      ],
      params: {},
      selected: false,
      checked: "selected",
      allContract: {data: [], total_pages: 1},
      markContracts: [],
    };
  },
  watch: {
    GET_CONTRACT_CHECKED: function (newVal, oldVal) {
      if (newVal && newVal.data.length > 0) {
        newVal.data.forEach((element) => {
          if (
              this.contractForm.contracts_to_exclude_from_auto_renewal.includes(
                  parseInt(element.document_number)
              )
          ) {
            this.removeItemOnce(
                this.contractForm.contracts_to_exclude_from_auto_renewal,
                parseInt(element.document_number)
            );
          } else {
            this.contractForm.contracts_to_exclude_from_auto_renewal.push(
                parseInt(element.document_number)
            );
          }
        });
      }
    },
  },
  computed: {
    message() {
      return this.GET_LOCALE === "sv" ? "swe_message" : "eng_message";
    },
    subject() {
      return this.GET_LOCALE === "sv" ? "swe_subject" : "eng_subject";
    },
    helpText() {
      return this.GET_LOCALE === "sv" ? "swedish_text" : "english_text";
    },
    textConfig() {
      return this.GET_LOCALE === "sv" ? "swe_text" : "eng_text";
    },
    features() {
      return this.GET_LOCALE === "sv" ? "features" : "features";
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  async mounted() {
    await this.getContract();
    await this.getGuides("contract_config");
    await this.getHelptext("contract_config");
    await this.getAllRegisters();
    await this.getContractConfig();
    this.disabledFields()
  },
  methods: {
    disabledFields() {
      let hidden_object = this.hiddenResource
      try {
        // this.$useJwt.hiddenResource({method: 'get', URL: `/${hidden_object['id']}/`}, {}).then()
        hidden_object['hidden_fields']['configuration']['contract_configuration'].map(item => {
          this.hidden_fields[item] = false
        })
      } catch (e) {

      }
    },
    closeModal() {
    },
    getGuides(config) {
      this.$useJwt
          .customGuides({URL: `?guide_type=${config}`, method: "get"})
          .then((res) => {
            this.guideConfig = res.data.results;
          });
    },
    getHelptext(helptext) {
      this.$useJwt
          .customHelptext({URL: `?dashboard_type=${helptext}`, method: "get"})
          .then((res) => {
            this.helptext = res.data.results;
          });
    },
    renderHelptext(field) {
      const filter = this.helptext.filter((el) => el.field === field);
      if (filter.length > 0) {
        return filter[0][this.helpText];
      }
      return "";
    },
    searchContractField: debounce(function (e) {
      if (e) {
        this.params.search = e;
      } else {
        delete this.params.search;
      }
      this.getContract();
    }, 350),
    getContract(already = false) {
      this.loadingTable = true;
      this.$useJwt
          .getContracts(
              {URL: "?customize=no", method: "get"},
              {params: this.params}
          )
          .then((res) => {
            this.loadingTable = false;
            this.allContract = {
              ...res.data,
              data: res.data.data.map((el) => {
                return {
                  ...el,
                  checked: false,
                };
              }),
              total_pages: res.data.total_pages,
            };

            if (already) {
              this.computeExcludedCntract();
            }
          })
          .catch((err) => {
            this.loadingTable = false;
          });
    },
    getContractConfig() {
      this.accessDenied = false
      this.$useJwt
          .customConfiguration({URL: "/contract", method: "get"})
          .then((res) => {
            this.contractForm = res.data.results[0];
            this.computeExcludedCntract();
          }).catch(err => {
        if (err.response.status === 403) {
          this.accessDenied = true
        } else {
          this.accessDenied = false
        }
      });
    },
    computeExcludedCntract() {
      if (this.contractForm.contracts_to_exclude_from_auto_renewal) {
        if (this.contractForm.contracts_to_exclude_from_auto_renewal.length > 0) {
          this.contractForm.contracts_to_exclude_from_auto_renewal.map((item) => {
            let conIndex = this.allContract.data.findIndex(
                (conItem) => conItem.document_number === item
            );
            if (conIndex !== -1) {
              this.allContract.data[conIndex].checked = true;

              let contractExclude = this.allContract.data[conIndex];
              this.performMainMarks(contractExclude);
            }
          });
        }
      }

    },
    performMainMarks(data) {
      this.markContracts.push(data);
    },
    async getAllRegisters() {
      const termsOfPayment = await this.$useJwt.getAllRegisters(
          "termsofpayment"
      );
      this.termsOfPayment = termsOfPayment.data.results.map((el) => {
        return {label: el.description, value: el.code};
      });
    },

    addConfigRow() {
      if (
          this.contractForm.send_invoice_with_fortnox_finance === "Some" &&
          this.contractForm.auto_send_invoices &&
          this.contractForm.auto_create_invoice
      ) {
        const payload = {
          type: "payment",
          comparison: "",
        };
        this.contractForm.config_rows.push(payload);
      }
    },
    removeConfigRow(condition) {
      if (
          this.contractForm.send_invoice_with_fortnox_finance === "Some" &&
          this.contractForm.auto_send_invoices &&
          this.contractForm.auto_create_invoice
      ) {
        const data = this.contractForm.config_rows[condition];
        if ("id" in data) {
          this.$useJwt
              .getSubContract({URL: `/${data.id}`, method: "delete"})
              .then((res) => {
                this.contractForm.config_rows.splice(condition, 1);
                this.popupMsg(
                    this.$t("Message.Success"),
                    res.data[this.message],
                    "CheckIcon",
                    "success"
                );
              })
              .catch((err) => {
                this.popupMsg(
                    this.$t("Message.Failed"),
                    err.response.data[this.message],
                    "AlertTriangleIcon",
                    "danger"
                );
              });
        } else {
          this.contractForm.config_rows.splice(condition, 1);
        }
      }
    },
    performAllCustomerChecked(e) {
      this.allContract.data.forEach((element) => {
        element.checked = this.selected === "selected" ? true : false;
      });
      this.performAllSingleChecked(e);
    },
    performAllSingleChecked(e) {
      const filter = this.allContract.data.filter((item) => item.checked);
      if (filter.length > 0) {
        this.markContracts = filter;
      } else {
        this.markContracts = [];
      }
    },
    firePagination(number) {
      this.currentPage = number;
      this.params.page = number;
      this.getContract(true);
    },
    updateConfigWithExcludeContract() {
      if (
          this.contractForm.send_invoice_with_fortnox_finance === "Some" &&
          !this.contractForm.config_rows.length
      ) {
        this.popupMsg(
            this.$t("Message.Failed"),
            "Please add at least one config row.",
            "AlertTriangleIcon",
            "danger"
        );
        return;
      }
      this.saving = true;
      let array = [];
      if (this.markContracts.length > 0) {
        this.markContracts.map((el) => {
          // THIS CONTAINS DUPLICATE SO MAKE ATTEMPT NOT
          // TO SEND TO SERVER
          if (!array.includes(el.document_number)) {
            array.push(el.document_number);
          }
        });
      } else {
        if (this.contractForm.contracts_to_exclude_from_auto_renewal &&
            this.contractForm.contracts_to_exclude_from_auto_renewal.length > 0
        ) {
          array = this.contractForm.contracts_to_exclude_from_auto_renewal;
        }
      }

      const payload = this.contractForm;
      payload.contracts_to_exclude_from_auto_renewal = array;

      this.$useJwt
          .customConfiguration(
              {URL: `/contract/${payload.uuid}`, method: "put"},
              payload
          )
          .then((res) => {
            this.getContractConfig();
            this.saving = false;
            this.modalVisible = false;
            this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.message],
                "CheckIcon",
                "success"
            );
          })
          .catch((err) => {
            this.saving = false;
            if (err.response.data.error) {
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data.error,
                  "AlertTriangleIcon",
                  "danger"
              );
            } else {
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            }
          });
      // } else {
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

::v-deep .table th {
  padding: 0.3rem !important;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

// .table tbody, td {
//     padding-top: .4rem;
//     padding-bottom: .4rem;
//     vertical-align: top;
//     border-top: 1px solid #dee2e6 !important;
// }
</style>
