<template>
  <div>
    <b-card class="position-relative" :class="{'md-access-denied-height': accessDenied === true}">
      <md-access-denied :is-access-denied="accessDenied" v-if="accessDenied === true" class="mt-40" :height="200"/>
      <ValidationObserver ref="memberForm">
        <div class="flex flex-col font-rubik">
          <div class="relative w-full">
            <div class="right-0 absolute">
                            <span class="cursor-pointer" @click="$bvModal.show('modal-configuration')">  
                                <mdicon
                                    role="button"
                                    :width="20"
                                    :height="20"
                                    name="help-circle-outline"
                                />
                            </span>
            </div>
          </div>
          <div class="md:grid grid-cols-1 md:grid-cols-2 gap-5">
            <div class="flex flex-col">
              <div class="flex flex-col">
                <h3 class="text-lg mb-1 font-rubik">{{ $t('memberConfig.memberUpdatesCreation') }}
                  <span>
                                        <span class="cursor-pointer" v-b-tooltip="renderHelptext('auto_update_member')">  
                                            <mdicon
                                                role="button"
                                                :width="20"
                                                :height="20"
                                                name="help-circle-outline"
                                            />
                                        </span>
                                    </span>
                </h3>
                <div class="">
                                    <span class="flex flex-col mt-1">
                                        <!--<div v-if="hidden_fields.auto_update_member">
                                            <ValidationProvider
                                                #default="{errors}"
                                                :name="'member updates'"
                                                rules="required"
                                            >
                                                <b-form-checkbox
                                                    v-model="memberConfig.auto_update_member"
                                                    class="c-mb-2"
                                                    :value="true"
                                                >
                                                <span>{{ $t('memberConfig.updateMember') }}</span>
                                                </b-form-checkbox> 
                                                <small class="text-danger" v-text="errors[0]"/>
                                            </ValidationProvider>
                                        </div>-->
                                        <div class="mt-1 md:w-4/5" v-if="hidden_fields.terms_of_payment">
                                            <label for="" class="text-base">{{ $t('memberConfig.defaultTOP') }}
                                                <span>
                                                    <span class="cursor-pointer"
                                                          v-b-tooltip="renderHelptext('terms_of_payment')">  
                                                        <mdicon
                                                            role="button"
                                                            :width="20"
                                                            :height="20"
                                                            name="help-circle-outline"
                                                        />
                                                    </span>
                                                </span>
                                            </label>
                                            <div class="w-2/3">
                                                <b-form-group
                                                    id="perPageSelect"
                                                    size="md"
                                                    inline
                                                >
                                                    <v-select
                                                        :placeholder="$t(locale.paymentTerms)"
                                                        :options="termsOfPayment"
                                                        @change="onChange"
                                                        v-model="selectTerms"
                                                        @option:selected="computeSelect($event, 'terms_of_payment')"
                                                        class="resource-selector d-inline-block w-full"
                                                    >
                                                        <template slot="option" slot-scope="option">
                                                            <div class="d-center">
                                                                 {{ option.label }}
                                                            </div>
                                                        </template>
                                                    </v-select>
                                                </b-form-group>
                                            </div>
                                        </div>
                                    </span>
                </div>
              </div>

              <br>
              <div class="flex flex-col" v-if="hidden_fields.exclude_dash_from_customer_number">
              <h3 class="text-lg mb-1 font-rubik">{{ $t('memberConfig.excludeDashInNumber') }}
                <span>
                                    <span class="cursor-pointer"
                                          v-b-tooltip="renderHelptext('exclude_dash_from_customer_number')">  
                                        <mdicon
                                            role="button"
                                            :width="20"
                                            :height="20"
                                            name="help-circle-outline"
                                        />
                                    </span>
                                </span>
              </h3>
              <span class="flex gap-5 mt-1">
                                <div>
                                    <ValidationProvider
                                        #default="{errors}"
                                        :name="'send method'"
                                        rules="required"
                                    >
                                        <b-form-checkbox
                                            v-model="memberConfig.exclude_dash_from_customer_number"
                                            :value="true"
                                            class="c-mb-2"
                                        >
                                        <span>{{ $t('memberConfig.excludeDashInNumberText') }}</span>
                                        </b-form-checkbox> 
                                        <small class="text-danger" v-text="errors[0]"/>
                                    </ValidationProvider>
                                </div>
                                <div>
                                        
                                </div>
                            </span>
            </div>


            </div>


            <div class="flex flex-col" v-if="hidden_fields.set_default_delivery_method_to_email">
              <h3 class="text-lg mb-1 font-rubik">{{ $t('memberConfig.invoiceSendMethod') }}
                <span>
                                    <span class="cursor-pointer"
                                          v-b-tooltip="renderHelptext('set_default_delivery_method_to_email')">  
                                        <mdicon
                                            role="button"
                                            :width="20"
                                            :height="20"
                                            name="help-circle-outline"
                                        />
                                    </span>
                                </span>
              </h3>
              <span class="flex gap-5 mt-1">
                                <div>
                                    <ValidationProvider
                                        #default="{errors}"
                                        :name="'send method'"
                                        rules="required"
                                    >
                                        <b-form-checkbox
                                            v-model="memberConfig.set_default_delivery_method_to_email"
                                            :value="true"
                                            class="c-mb-2"
                                        >
                                        <span>{{ $t('memberConfig.invoiceSendMethodText') }}</span>
                                        </b-form-checkbox> 
                                        <small class="text-danger" v-text="errors[0]"/>
                                    </ValidationProvider>
                                </div>
                                <div>
                                        
                                </div>
                            </span>
            </div>


            <div class="col-span-2 flex justify-center">
              <button @click="saveSettings" type="submit"
                      class="flex md:w-1/4 p-1 text-center focus:outline-none bg-theme-teal text-white whitespace-nowrap rounded-md">
                                <span class="w-full flex justify-center" v-if="saving">
                                    <b-spinner
                                        small
                                        type="grow"
                                    />
                                    {{ $t('Message.loading') }}...
                                </span>

                <span
                    class="whitespace-nowrap text-center w-full text-base"
                    v-else
                    v-text="
                                        $t(locale.save)
                                    "
                />
              </button>
            </div>

          </div>
        </div>
      </ValidationObserver>
    </b-card>
    <b-modal
        id="modal-configuration"
        modal-class="modal-primary"
        ref="modal"
        :title="$t(locale.guide)"
        @close="closeModal"
        @cancel="closeModal"
        @hide="closeModal"
        :hide-footer="true"
    >
      <b-card-text>
        <div v-if="guideConfig.length > 0">
          <h5>{{ guideConfig[0][subject] }}</h5>
          <div v-html="guideConfig[0][textConfig]">

          </div>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {getSubscribersMixins} from '@/mixins/getSubscribersMixins';
import {billingMixins} from '@/mixins/billingMixins';
import CustomNoHeaderModalVue from '@/@core/customComponent/CustomNoHeaderModal.vue';
import vSelect from 'vue-select';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {mapGetters} from "vuex";

import mdAccessDenied from "@core/components/permission/mdAccessDenied";
import PAGES from "@/services/helpers/constant";

export default {
  name: 'MemberConfigView',
  mixins: [getSubscribersMixins, billingMixins],
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    CustomNoHeaderModalVue, mdAccessDenied
  },
  data() {
    return {
      saving: false,
      accessDenied: false,
      modalVisible: false,
      hidden_fields: {...JSON.parse(JSON.stringify(PAGES['configuration']['member_configuration']))},
      guideConfig: [],
      selectTerms: null,
      memberConfig: {
        auto_update_member: true,
        set_default_delivery_method_to_email: true,
        terms_of_payment: null, 
        exclude_dash_from_customer_number: false
      },
      termsOfPayment: [],
      locale: {
        save: 'ContractConfig.save',
        guide: 'ConfigurationGuide.title'
      },
      helptext: [],
    };
  },
  computed: {
    message() {
      if (this.GET_LOCALE === '') {
        return this.swedishLang ? 'message' : 'eng_message';
      } else return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message';
    },
    subject() {
      if (this.GET_LOCALE === '') {
        return this.swedishLang ? 'swe_subject' : 'eng_subject';
      } else return this.GET_LOCALE === 'sv' ? 'swe_subject' : 'eng_subject';
    },
    textConfig() {
      if (this.GET_LOCALE === '') {
        return this.swedishLang ? 'swe_text' : 'eng_text';
      } else return this.GET_LOCALE === 'sv' ? 'swe_text' : 'eng_text';
    },
    helpText() {
      if (this.GET_LOCALE === '') {
        return this.swedishLang ? 'swedish_text' : 'english_text';
      } else return this.GET_LOCALE === 'sv' ? 'swedish_text' : 'english_text';
    },
    features() {
      if (this.GET_LOCALE === '') {
        return this.swedishLang ? 'features' : 'features';
      } else return this.GET_LOCALE === 'sv' ? 'features' : 'features';
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  mounted() {
    this.getGuides('member_config')
    this.getHelptext('member_config')
    this.getAllRegisters()
    this.getMemberConfig()
  },
  methods: {
    disabledFields() {
      let hidden_object = this.hiddenResource
      try {
        // this.$useJwt.hiddenResource({method: 'get', URL: `/${hidden_object['id']}/`}, {}).then()
        hidden_object['hidden_fields']['configuration']['member_configuration'].map(item => {
          this.hidden_fields[item] = false
        })
      } catch (e) {

      }
    },
    closeModal() {
    },
    getGuides(config) {
      this.$useJwt.customGuides({URL: `?guide_type=${config}`, method: 'get'})
          .then(res => {
            this.guideConfig = res.data.results
          })
    },
    async getAllRegisters() {
      const termsOfPayment = await this.$useJwt.getAllRegisters(`termsofpayment?p=${new Date().getMilliseconds()}`)
      this.termsOfPayment = termsOfPayment.data.results.map(el => {
        return {label: el.description, value: el.id}
      })
    },
    getHelptext(helptext) {
      this.$useJwt.customHelptext({URL: `?dashboard_page=${helptext}`, method: 'get'})
          .then(res => {
            this.helptext = res.data.results
          })
    },
    renderHelptext(field) {
      const filter = this.helptext.filter(el => el.field === field)
      if (filter.length > 0) {
        return filter[0][this.helpText]
      }
      return ''
    },
    getMemberConfig() {
      this.$useJwt.customConfiguration({URL: '/member', method: 'get'})
          .then(res => {
            this.memberConfig = res.data.results[0]
            if (res.data.results[0].terms_of_payment) {
              this.selectTerms = res.data.results[0].terms_of_payment.description
              this.memberConfig['terms_of_payment'] = res.data.results[0].terms_of_payment.id
            }
          }).catch(err => {
        if (err.response.status === 403) {
          this.accessDenied = true
        } else {
          this.accessDenied = false
        }
      })
    },
    onChange: function () {
      // left blank
    },
    computeSelect(event, key) {
      if (key === 'terms_of_payment') {
        this.selectTerms = event.label
        this.memberConfig[key] = event.value

      } else {
        this.memberConfig[key] = event.value
      }

    },
    saveSettings() {
      this.$refs.memberForm.validate().then(res => {
        if (res) {
          this.saving = true
          const payload = this.memberConfig
          if (!this.selectTerms) {
            payload['terms_of_payment'] = ''
          }
          this.$useJwt.customConfiguration({URL: `/member/${payload.uuid}`, method: 'put'}, payload)
              .then(res => {
                this.saving = false
                this.popupMsg(
                    this.$t('Message.Success'),
                    res.data[this.message],
                    'CheckIcon',
                    'success'
                );
              })
              .catch(err => {
                this.saving = false
                if (err.response.data.error) {
                  this.popupMsg(
                      this.$t('Message.Failed'),
                      err.response.data.error,
                      'AlertTriangleIcon',
                      'danger'
                  );
                } else {
                  this.popupMsg(
                      this.$t('Message.Failed'),
                      err.response.data[this.message],
                      'AlertTriangleIcon',
                      'danger'
                  );
                }
              })
        }
      });
    }
  },
  created() {
    this.disabledFields()
  }

};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
