<template>
    <b-card
        bg-variant="secondaryColor"
        text-variant="white"
        class="rounded-lg text-white"
    >
        <template v-if="currentPlan.length > 0">
            <b-card-title class="text-white">
                {{ $t('BillingPageCard.rightSide.title') }}
            </b-card-title>

            <b-card-text v-for="(data, index) in currentPlan" :key="index">
                <p class="font-weight-bold">{{ data.plan_name }}</p>
                <p>
                    {{
                        $t('BillingPageCard.invoiceNumber', {
                            number: data.invoiceNumbers,
                        })
                    }}
                </p>
                <p class="ml-2">
                    {{ data.price }} SEK / {{ $t('Message.month') }}
                </p>
            </b-card-text>

            <b-link @click="switchTab(2)" class="">
                {{ $t('BillingPageCard.rightSide.planLink') }} >>
            </b-link>
        </template>

        <Loading v-else />
    </b-card>
</template>

<script>
import {getSubscribersMixins} from '@/mixins/getSubscribersMixins';
export default {
    mixins: [getSubscribersMixins],
    name: 'CurrentPlan',
    props : {
        switchTab : {
            type : Function,
            required : false
        }
    },
    data() {
        return {
            currentPlan: [],
        };
    },

    methods: {
        async renderCurrentPlan() {
            let currentPlanId = await this.getCurrentPlan();

            let paymentPlan = await this.getPaymentPlan();


            this.$useJwt.packages().then(res => {
                const {results} = res.data;


                let getCurrentPlan = results.filter(element => {
                    return element.uuid === currentPlanId[0];
                });

                getCurrentPlan.forEach(element => {
                    this.currentPlan.push({
                        plan_name: element.name,
                        price:
                            paymentPlan[0].toLowerCase() === 'quarterly'
                                ? element.monthly_price
                                : element.monthly_price,
                        // invoiceNumbers: element.number_of_invoices_per_month,
                        paymentPlan: paymentPlan[0],
                    });
                });
            });
        },
    },

    mounted() {
        this.renderCurrentPlan();
    },
};
</script>

<style></style>
