<template>
    <div>
        <h1 class="text-lg mb-1 font-rubik">{{ $t('invoiceConfig.paymentRule') }}</h1>
        <div class="table-container">

            <table class="data-table mt-2">
                <thead>
                    <tr>
                        <th class="text-base font-semibold">{{ $t('invoiceConfig.modeOfPayment') }}</th>
                        <th class="text-base font-semibold">{{ $t('invoiceConfig.excludeFromCredited') }}</th>
                        <th class="text-base font-semibold">{{ $t('invoiceConfig.excludeFromPaid') }}</th>
                        <th class="text-base font-semibold">{{ $t('invoiceConfig.includeInCredited') }}</th>
                        <!-- <th>Action</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in tableData" :key="item.id">
                        <td class="text-base font-semibold">{{ getMOPName(item) }}</td>
                        
                        <!-- Icon for exclude_from_credited -->
                        <td>
                            <span v-if="item.exclude_from_credited">
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M8.5 17C13.1946 17 17 13.1946 17 8.5C17 3.80545 13.1946 0 8.5 0C3.80545 0 0 3.80545 0 8.5C0 13.1946 3.80545 17 8.5 17ZM13.0654 5.80975C13.1051 5.76879 13.1362 5.72023 13.1568 5.66698C13.1774 5.61373 13.187 5.55688 13.1852 5.49982C13.1833 5.44276 13.17 5.38666 13.1459 5.33487C13.1219 5.28308 13.0877 5.23666 13.0453 5.19839C13.003 5.16011 12.9533 5.13077 12.8994 5.1121C12.8454 5.09343 12.7883 5.08582 12.7313 5.08972C12.6744 5.09363 12.6188 5.10897 12.5679 5.13484C12.517 5.1607 12.4718 5.19655 12.4351 5.24025L7.344 10.866L4.54325 8.1923C4.46176 8.11441 4.35266 8.07209 4.23996 8.07464C4.12726 8.07719 4.02019 8.12441 3.9423 8.2059C3.86441 8.28739 3.82209 8.39649 3.82464 8.50919C3.82719 8.62189 3.87441 8.72896 3.9559 8.80685L7.07285 11.7819L7.38863 12.0836L7.68145 11.7598L13.0654 5.80975Z"
                                        fill="#419E6A"/>
                                </svg>
                            </span>
                            <span v-else></span>
                        </td>

                        <!-- Icon for exclude_from_paid -->
                        <td>
                            <span v-if="item.exclude_from_paid">
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M8.5 17C13.1946 17 17 13.1946 17 8.5C17 3.80545 13.1946 0 8.5 0C3.80545 0 0 3.80545 0 8.5C0 13.1946 3.80545 17 8.5 17ZM13.0654 5.80975C13.1051 5.76879 13.1362 5.72023 13.1568 5.66698C13.1774 5.61373 13.187 5.55688 13.1852 5.49982C13.1833 5.44276 13.17 5.38666 13.1459 5.33487C13.1219 5.28308 13.0877 5.23666 13.0453 5.19839C13.003 5.16011 12.9533 5.13077 12.8994 5.1121C12.8454 5.09343 12.7883 5.08582 12.7313 5.08972C12.6744 5.09363 12.6188 5.10897 12.5679 5.13484C12.517 5.1607 12.4718 5.19655 12.4351 5.24025L7.344 10.866L4.54325 8.1923C4.46176 8.11441 4.35266 8.07209 4.23996 8.07464C4.12726 8.07719 4.02019 8.12441 3.9423 8.2059C3.86441 8.28739 3.82209 8.39649 3.82464 8.50919C3.82719 8.62189 3.87441 8.72896 3.9559 8.80685L7.07285 11.7819L7.38863 12.0836L7.68145 11.7598L13.0654 5.80975Z"
                                        fill="#419E6A"/>
                                </svg>
                            </span>
                            <span v-else></span>
                        </td>

                        <td>
                            <span v-if="item.include_in_credited">
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M8.5 17C13.1946 17 17 13.1946 17 8.5C17 3.80545 13.1946 0 8.5 0C3.80545 0 0 3.80545 0 8.5C0 13.1946 3.80545 17 8.5 17ZM13.0654 5.80975C13.1051 5.76879 13.1362 5.72023 13.1568 5.66698C13.1774 5.61373 13.187 5.55688 13.1852 5.49982C13.1833 5.44276 13.17 5.38666 13.1459 5.33487C13.1219 5.28308 13.0877 5.23666 13.0453 5.19839C13.003 5.16011 12.9533 5.13077 12.8994 5.1121C12.8454 5.09343 12.7883 5.08582 12.7313 5.08972C12.6744 5.09363 12.6188 5.10897 12.5679 5.13484C12.517 5.1607 12.4718 5.19655 12.4351 5.24025L7.344 10.866L4.54325 8.1923C4.46176 8.11441 4.35266 8.07209 4.23996 8.07464C4.12726 8.07719 4.02019 8.12441 3.9423 8.2059C3.86441 8.28739 3.82209 8.39649 3.82464 8.50919C3.82719 8.62189 3.87441 8.72896 3.9559 8.80685L7.07285 11.7819L7.38863 12.0836L7.68145 11.7598L13.0654 5.80975Z"
                                        fill="#419E6A"/>
                                </svg>
                            </span>
                            <span v-else></span>
                        </td>

                        <button @click="deleteRow(item.uuid)">{{ $t('articleConfig.delete') }}</button>
                    </tr>
                </tbody>

            </table>
        </div>
        <div class="row mt-10">
            <div class="customWitdh">
                <label class="text-base font-semibold" style="widht:100%">{{ $t('invoiceConfig.modeOfPayment') }}
                    <span>
                    <span
                        class="cursor-pointer"
                        v-b-tooltip="renderHelptext('mode_of_payment')"
                    >
                      <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                      />
                    </span>
                  </span>
                </label>
            </label>
                <v-select class="w-full" v-model="formData.mode_of_payment" :options="MOPOptions" label="description"
                    :filterable="true" @search="getMOPOptions"></v-select>
            </div>
            <div class="customWitdh">
                <label class="text-base font-semibold">{{ $t('invoiceConfig.excludeFromCredited') }}
                    <span>
                    <span
                        class="cursor-pointer"
                        v-b-tooltip="renderHelptext('exclude_from_credited')"
                    >
                      <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                      />
                    </span>
                  </span>
                </label>
                <b-form-checkbox
                    :id="'checkbox-exclude-from-credited'"
                    class="mb-2"
                    v-model="formData.exclude_from_credited"
                    name="checkbox-form-1"
                    :value="true"
                >
                </b-form-checkbox>
            </div>
            <div class="customWitdh">
                <label class="text-base font-semibold">{{ $t('invoiceConfig.excludeFromPaid') }}
                    <span>
                    <span
                        class="cursor-pointer"
                        v-b-tooltip="renderHelptext('exclude_from_paid')"
                    >
                      <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                      />
                    </span>
                  </span>
                </label>
                <b-form-checkbox
                    :id="'checkbox-exclude-from-paid'"
                    class="mb-2"
                    v-model="formData.exclude_from_paid"
                    name="checkbox-form-1"
                    :value="true"
                >
                </b-form-checkbox>
            </div>
            <div class="customWitdh">
                <label class="text-base font-semibold">{{ $t('invoiceConfig.includeInCredited') }}
                    <span>
                    <span
                        class="cursor-pointer"
                        v-b-tooltip="renderHelptext('include_in_credited')"
                    >
                      <mdicon
                          role="button"
                          :width="20"
                          :height="20"
                          name="help-circle-outline"
                      />
                    </span>
                  </span>
                </label>
                
                <b-form-checkbox
                    :id="'checkbox-include_in_credited'"
                    class="mb-2"
                    v-model="formData.include_in_credited"
                    name="checkbox-form-1"
                    :value="true"
                >
                </b-form-checkbox>
            </div>

            <div class="btn-main">
                <button class="addBtn" @click="addButtons">
                    <span>
                        {{ $t('articleConfig.add') }}
                    </span>
                </button>

            </div>
        </div>

    </div>
</template>
  
<script>
import customSelect from '@/@core/customComponent/customSelect.vue';
import vSelect from 'vue-select';
import PAGES from "@/services/helpers/constant";
import {mapGetters} from "vuex";

export default {
    components: { customSelect, vSelect },
    data() {
        return {
            hidden_fields: {...JSON.parse(JSON.stringify(PAGES['configuration']['article_configuration']))},
            tableData: [],
            helptext: [],
            MOPOptions: [],
            formData: {
                mode_of_payment_code: null,
                exclude_from_paid: false,
                exclude_from_credited: false,
                include_in_credited: false
            }
        }
    },
    created() {
        this.disabledFields()
    },
    mounted() {
        this.getHelptext("invoice_config");
        this.getData()
        this.getMOPOptions()
    },
    computed: {
        helpText() {
            return this.GET_LOCALE === "sv" ? "swedish_text" : "english_text";
        },
        ...mapGetters({
        GET_LOCALE: 'appConfig/getLocale'
        }),
    },
    methods: {
        getHelptext(helptext) {
            this.$useJwt
                .customHelptext({URL: `?dashboard_page=${helptext}`, method: "get"})
                .then((res) => {
                    this.helptext = res.data.results;
                });
        },
        renderHelptext(field) {
            const filter = this.helptext.filter((el) => el.field === field);
            if (filter.length > 0) {
                return filter[0][this.helpText];
            }
            return "";
        },
        disabledFields() {
            let hidden_object = this.hiddenResource
            try {
                // this.$useJwt.hiddenResource({method: 'get', URL: `/${hidden_object['id']}/`}, {}).then()
                hidden_object['hidden_fields']['configuration']['article_configuration'].map(item => {
                this.hidden_fields[item] = false
                })
            } catch (e) {

            }
        },
        getMOPName(item) {
            return item.mode_of_payment ? item.mode_of_payment.description : '';
        },

        deleteRow(uuid) {
            this.$swal({
                title: this.$t('articleConfig.confirmation'),
                text: this.$t('invoiceConfig.deletionInformation'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: this.$t('articleConfig.confirm'),
            }).then(async (result) => {
                if (result.isConfirmed) {

                    try {
                        const response = await this.$useJwt.customConfiguration({ URL: '/mode_of_payment_rules/' + uuid, method: 'delete' })
                        this.popupMsg(
                            this.$t('Message.Success'),
                            this.$t('articleConfig.customdeleteAccount'),
                            'CheckIcon',
                            'success'
                        );

                        this.getData()
                        // this.$swal('Deleted!', 'Your row has been deleted.', 'success');
                    } catch (error) {

                    }
                }
            });
        },

        async getData() {
            try {
                const response = await this.$useJwt.customConfiguration({ URL: '/mode_of_payment_rules', method: 'get' })
                this.tableData = response.data?.results

            } catch (error) {

            }


        },

        async getMOPOptions(search = "") {
            try {
                const response = await this.$useJwt.getAllRegisters(`mode_of_payments?search=${search}`)
                this.MOPOptions = response.data?.results?.map((item) => ({ ...item, description: item.code + " - " + item.description }))

            } catch (error) {

            }
        },

        async addButtons() {
            // Add your logic to handle button addition
            const payload = {
                mode_of_payment: this.formData.mode_of_payment?.id,
                exclude_from_paid: this.formData.exclude_from_paid,
                exclude_from_credited: this.formData.exclude_from_credited,
                include_in_credited: this.formData.include_in_credited
            }
            try {
                const response = await this.$useJwt.customConfiguration({ URL: '/mode_of_payment_rules', method: 'post' }, payload);
                this.popupMsg(
                    this.$t('Message.Success'),
                    response.data.message,
                    'CheckIcon',
                    'success'
                );
                this.getData()
                this.formData = {
                    mode_of_payment: null,
                    exclude_from_paid: false,
                    exclude_from_credited: false,
                    include_in_credited: false
                }


            } catch (error) {
                this.popupMsg(
                    this.$t('Message.Failed'),
                    error.response?.data?.message,
                    'AlertTriangleIcon',
                    'danger'
                );
            }
        },


    }
};

</script>
  
<style lang="scss">
.row {
    display: flex;
    align-items: center;
    margin-left: .2rem;
    margin-bottom: 1rem; /* Add bottom margin for spacing */
    margin-right: 1rem;
}

.title {
    color: var(--others-body-font, #27364B);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 155.556% */
}

.label {
    color: #232D39;
    // font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.btn-main {
    display: flex;
    align-items: center;
    // min-width: 12% !important;
    justify-content: center;
    margin-top: 20px;
    margin-left: 80px;
}

.addBtn {
    border-radius: 0.375rem;
    background: #4FCFC0;
    padding: 10px 20px;
    color: #FFF;
    font-style: normal;
    line-height: normal;
    border: none;
    width: auto; /* Adjusted for auto width */
    display: block; /* To take the full width */
}

.table-container {
    overflow-x: auto;
    /* Enable horizontal scroll */
    max-width: 100%;
    /* Adjust as needed */
}


.data-table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
        color: #232D39;
        // font-family: Roboto;
        font-size: 12px;
        padding: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        border-bottom: 1px solid #ddd;
        /* Add this line to add a bottom border to each cell */

    }

    th {
        font-size: inherit; // Reset the font-size to inherit from the class
        font-weight: inherit; // Reset the font-weight to inherit from the class
        padding: 8px;
        text-align: left;
        color: #232D39;
        // font-family: Roboto;
        // font-size: 12px;
        font-style: normal;
        // font-weight: 700;
        line-height: normal;
        border-bottom: 1px solid #ddd;
        /* Add this line to add a bottom border to each cell */

        // background-color: #f2f2f2;
    }

    button {
        color: white;
        padding: 10px 20px 10px 20px;
        border: none;
        cursor: pointer;
        border-radius: 0.375rem;
        margin: 10px;
        background: #FB4B4B;
        color: #FFF;
        font-style: normal;
        line-height: normal;
        margin-left: 10px;
    }

}


.customWitdh {
    min-width: 12% !important;
    margin-right: 2rem; /* Increased right margin for more horizontal space */
    margin-bottom: 30px; /* Increased bottom margin for more vertical space */
}

.row .customWitdh {
    margin-right: 2rem !important;
    margin-bottom: 20px;
}





@import '@core/scss/vue/libs/vue-select.scss';
</style>
  