<template>
  <div>
    <b-card class="position-relative" :class="{'md-access-denied-height': accessDenied === true}">
      <md-access-denied :is-access-denied="accessDenied" v-if="accessDenied === true" class="mt-40"/>
      <b-card-text class="text-center">
        <h2 class="d-md-block d-none" v-text="$t(locale.title)"/>

        <h3 class="d-block d-md-none" v-text="$t(locale.title)"/>

        <b-card-text v-if="currentContractEndDate" class="text-center">
          <div>
            <h5 style="color: darkblue;">
              {{ $t(locale.contractEndDate) }} {{ currentContractEndDate }}
            </h5>
          </div>
        </b-card-text>

        <div class="d-flex justify-content-center">
          <b-row align-h="center">
            <b-col cols="12" sm="6" md="7">
              <p
                  class="text-center textSubtitle w-100 font-small-3 mb-0"
                  v-text="$t(locale.subtitle)"
              />
            </b-col>
          </b-row>
        </div>
      </b-card-text>

      <!-- New upgrade button -->
      <div class="upgrade-button-container text-center mt-3" v-if="isGitAPIUser === false">
        <b-button variant="success" @click="openUpgradeConfirmModal">
          Byt till nya Golfigo versionen!
        </b-button>
      </div>

      <b-card-text class="">
        <!-- <div class="d-flex justify-content-center align-items-center">
          <PaymentToggler
              @resetProps="updateCurrentPayment = ''"
              @updateBtnCurrentPackage="updateCurrentBtn = $event"
              @monthly="changePaymentPlan($event)"
              :fontSize="{ fontSize: '28px' }"
              :updateCurrentPayment="updateCurrentPayment"
          />
        </div> -->
        <b-row class="mt-3">
          <Loading v-if="packages.length === 0"/>

          <template v-else>
            <b-col
                v-for="(data, index) in packages"
                :key="index"
                xl="3"
                sm="6"
                class="p-1"
            >
              <div
                  class="shadow h-100 flex flex-col justify-between custom-border border-theme-blue rounded-lg p-2"
              >
                <!-- <b-card-text> -->
                <!-- <div class="flex flex-col justify-between"> -->
                <div>
                  <div class="flex relative">
                    <h2
                        class="mb-0 text-uppercase text-gray-900 font-weight-bolder"
                        style="letter-spacing: 2px"
                        v-text="data.name"
                    />

                    <span
                        class="absolute right-0 w-full mt-10"
                        v-if="data.currentPlan"
                    >
                      <img
                          class="checkLargeIcon"
                          src="@/assets/icon/checkIcon.png"
                      />
                    </span>
                  </div>

                  <div class="d-flex mt-2 flex-column">
                    <!-- style="height: 168px" -->
                    <div
                        v-for="(featuresItem, index) in data.features"
                        :key="index"
                        class="d-flex h-100 items-center"
                        style="gap: 1rem"
                    >
                      <img
                          src="@/assets/icon/checkIcon.png"
                          width="20px"
                          height="20px"
                      />

                      <div class="d-flex flex-column">
                        <p class="mb-0 text-sm" v-text="featuresItem"/>
                      </div>
                    </div>

                    <div class="dropdown-divider mt-2"/>
                  </div>
                </div>

                <div class="bottom-0 right-0">
                  <div class="my-1">
                    <div
                        class="w-full flex justify-center items-center text-gray-900"
                    >
                      <div
                          class="text-2xl font-weight-bolder d-flex align-items-center justify-content-center text-center mb-0 price"
                      >
                        {{ data.monthly_price }}
                      </div>
                      <div
                          class="font-weight-light d-flex justify-content-center priceDuration"
                      >
                        SEK/{{ $t(locale.month) }} {{ $t(locale.billedYearly) }}
                      </div>
                    </div>
                  </div>

                  <div class="bottom">
                    <template v-if="data.currentPlan">
                      <b-button
                          v-if="updateCurrentBtn"
                          v-ripple.400

                          @click="confirmPlan(data,'update')"
                          block
                          variant="secondaryColor"
                      >
                        <span v-if="data.loadingStatus">
                          <b-spinner small type="grow"/>
                          {{ $t(locale.loading) }}...
                        </span>

                        <span v-else v-text="$t(locale.upgradeBtn)"/>
                      </b-button>

                      <b-button
                          v-else
                          v-ripple.400
                          disabled
                          block
                          variant="white"
                      >
                        <span
                            class="text-secondaryColor"
                            v-text="$t(locale.currentBtn)"
                        />
                      </b-button>
                    </template>

                    <b-button
                        v-else
                        @click="confirmPlan(data,'action')"
                        variant="secondaryColor"
                        :style="notOwnerRole ? '' : 'cursor: pointer'"
                        :disabled="notOwnerRole"
                        block
                    >
                      <span v-if="data.loadingStatus">
                        <b-spinner small type="grow"/>
                        {{ $t(locale.loading) }}...
                      </span>

                      <span v-else v-text="$t(locale.upgradeBtn)"/>
                    </b-button>
                  </div>
                </div>
                <!-- </div> -->
                <!-- </b-card-text> -->
              </div>
            </b-col>
          </template>

          <b-col cols="12" md="6" lg="3" sm="6">
            <b-button
                v-if="packages.length > 0"
                v-ripple.400
                variant="danger"
                block
                :disabled="notOwnerRole"
                v-b-modal.modal-primary
            >
              <span v-if="cancelSubscriptionStatus">
                <b-spinner small type="grow"></b-spinner>
                {{ $t(locale.loading) }}...
              </span>

              <span v-else v-text="$t(locale.cancel)"/>
            </b-button>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>

    <b-modal
        id="modal-primary"
        ref="modal"
        :title="$t(locale.subscriptionCancel)"
        :hide-footer="true"
        no-close-on-backdrop
    >
      <!-- modal-class="modal-primary"
            :ok-title="$t(locale.modalConfirm)"
            ok-only
            clickToClose
            ok-variant="primary"
            centered
            @ok="validationForm" -->
      <b-card-text>
        <ValidationObserver ref="cancelForm">
          <b-form class="auth-login-form mt-2">
            <!-- Reason Options -->
            <b-form-group>
              <ValidationProvider
                  #default="{ errors }"
                  :name="$t(locale.optionsLabel)"
                  rules="required"
              >
                <b-input-group
                    class="input-group-merge"
                    :class="{
                    'is-invalid': errors.length > 0,
                  }"
                >
                  <v-select
                      :options="$t(locale.reasonOptions)"
                      :clearable="false"
                      :placeholder="$t(locale.optionsReason)"
                      class="per-page-selector-send-method d-inline-block"
                      v-model="requestCancelSubscription.reasons_for_cancellation"
                  >
                  </v-select>
                </b-input-group>
                <small class="text-danger" v-text="errors[0]"/>
              </ValidationProvider>
            </b-form-group>
            <b-form-group>
              <!-- For element at index 3 -->
              <ValidationProvider
                  #default="{ errors }"
                  :name="$t(locale.reasonText)"
                  vid="reasonText"
                  rules="required"
                  v-if="requestCancelSubscription.reasons_for_cancellation == $t(locale.reasonOptions)[3]"
              >
                  <b-form-textarea
                      id="textarea-reason"
                      v-model="requestCancelSubscription.cancellation_text"
                      :placeholder="$t(locale.reasonText)"
                      rows="3"
                  />
                  <small class="text-danger" v-text="errors[0]"/>
              </ValidationProvider>

              <!-- For element at index 2 -->
              <ValidationProvider
                  #default="{ errors }"
                  :name="$t(locale.explanationText)"
                  vid="explanationText"
                  rules="required"
                  v-if="requestCancelSubscription.reasons_for_cancellation == $t(locale.reasonOptions)[2]"
              >
                  <b-form-textarea
                      id="textarea-explanation"
                      v-model="requestCancelSubscription.cancellation_text" 
                      :placeholder="$t(locale.explanationText)"
                      rows="3"
                  />
                  <small class="text-danger" v-text="errors[0]"/>
              </ValidationProvider>
            </b-form-group>
          </b-form>
        </ValidationObserver>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('modal-primary')"
        >
          <span v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"
            :disabled="cancelSubscriptionStatus"
            @click="validationForm"
        >
          <span
              v-text="
              cancelSubscriptionStatus ? $t('Message.loading') :  $t('Message.proceed')
            "
          />
        </b-button>
      </div>
    </b-modal>

    <ModalEditMember
        @modalEditElement="modalEditElement = $event"
        @finalStepsUpdate="finalStepsUpdate"
        @selectedUsers="selectedUsers = $event"
        @usersOptions="usersOptions = $event"
        @closeModal="closeMemberModal"
        :limitUser="limitUser"
        :limitResource="limitResource"
    />
    <Modal
        id="confirmation-modal"
        :hideFooter="true"
        :title="$t('subscriptionPlanConfirmation')"
    >
      <b-card-text
          class="d-flex flex-column text-center align-items-center justify-content-center"
          style="gap: 1rem"
      >
        <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        />
        <p class="mb-0 pb-0">
          {{ $t('subscriptionPlanConfirmationText') }}
        </p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('confirmation-modal')"
        >
          <span v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"
            :disabled="sendingModalAction"
            @click="performModalAction"
        >
          <span
              v-text="sendingModalAction ? $t('Message.loading') : $t('confirm')"
          />
        </b-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Switches from "vue-switches";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {getSubscribersMixins} from "@/mixins/getSubscribersMixins";
import {billingMixins} from "@/mixins/billingMixins";
import useAppConfig from "@core/app-config/useAppConfig";
// import PaymentToggler from "@/@core/components/subscription/PaymentToggler.vue";
import vSelect from "vue-select";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import ModalEditMember from "@/@core/components/subscription/ModalEditMember.vue";
import mdAccessDenied from "@core/components/permission/mdAccessDenied";
import {mapGetters} from "vuex";

export default {
  mixins: [getSubscribersMixins, billingMixins],

  data() {
    return {
      currentContractEndDate: null,
      isGitAPIUser: null,
      packages: [],
      packagesCopy: [],
      paymentPlans: [],
      paymenToggler: true,
      accessDenied: false,
      sendingModalAction: false,
      currentPackage: {},
      selectedPlan: {},
      mode: '',
      currentSubscriptionPlan: {},
      monthly: "",
      userCurrentSubscription: {},
      updateCurrentBtn: false,
      tierList: [],
      modalEditElement: "",
      editPackages: "",
      cancelSubscriptionStatus: false,
      config: useAppConfig(),
      paymentStatusChange: false,
      limitResource: null,
      limitUser: null,
      updateCurrentPayment: "",
      selectedUsers: [],
      usersOptions: [],
      totalUsers: "",
      notOwnerRole: false,
      requestCancelSubscription: {
        cancellation_text: "",
        reasons_for_cancellation: "",
      },
      locale: {
        title: "SubscriptionCard.title",
        subtitle: "SubscriptionCard.subtitle",
        month: "Message.month",
        billedYearly: 'SubscriptionCard.billedYearly',
        contractEndDate: 'SubscriptionCard.contractEndDate',
        upgradeBtn: "SubscriptionCard.upgradeNow",
        loading: "Message.loading",
        cancel: "SubscriptionCard.cancel",
        subscriptionCancel: "SubscriptionCard.cancel",
        modalConfirm: "SubscriptionCard.ModalConfirm",
        optionsLabel: "SubscriptionCard.ReasonOptionLabel",
        optionsReason: "SubscriptionCard.ReasonOption",
        reasonText: "SubscriptionCard.ReasonTextLabel",
        explanationText: "SubscriptionCard.ExplanationText",
        currentBtn: "SubscriptionCard.currentPackage",
        upgradeMessage: "SubscriptionCard.upgradeMessage",
        downgradeMessage: "SubscriptionCard.downgrade",
        reasonOptions: "SubscriptionCard.ReasonOptions",
      },
      fullPlan: [],
    };
  },

  components: {
    Switches,
    ToastificationContent,
    // PaymentToggler,
    mdAccessDenied,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ModalEditMember,
  },

  computed: {
    message() {
      return this.GET_LOCALE === "sv" ? "swe_message" : "eng_message";
    },
    subMessage() {
      return this.GET_LOCALE === "sv" ? "message" : "eng_message";
    },
    features() {
      return this.GET_LOCALE === "sv" ? "features" : "features";
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  methods: {
    performModalAction() {
      if (this.mode === 'action') {
        this.actionButton(this.selectedPlan)
      } else {
        this.updatePlan(this.selectedPlan)
      }
    },
    async getTier() {
      return this.$useJwt.tierList().then((res) => res.data.results);
    },
    getSubcription() {
      this.$useJwt.subscription().then((res) => {
        this.currentContractEndDate = res.data.data[0].period_start_date
        // if (res.status === 200) {
        //   this.userCurrentSubscription = res.data.data;
        // }
      }).catch(err => {
        this.accessDenied = err.response.status === 403;
      });
    },
    userCurrentPlan(plan) {
      if (this.userCurrentSubscription.current_packag) {
        if (plan.name === this.userCurrentSubscription.current_packag.name) {
          return true;
        }
      }
      return false;
    },
    async getAllPackages(planAmount = null) {
      const currentUserPlan = await this.getSubscriptions();
      this.isGitAPIUser = currentUserPlan.data.data[0].golfclub.is_git_api_user;
      const {data} = await this.getFullPlan();
      this.fullPlan = data.data.payment_plans;

      const amountCal = planAmount
          ? planAmount
          : currentUserPlan.data.data[0].payment_plan.monthly_add_on;

      this.packages = [];
      this.packagesCopy = [];
      this.$useJwt.packages().then((res) => {
        const {results} = res.data;


        results.forEach((element) => {
          if (element.name) {
            this.packagesCopy.push({
              ...element,
              loadingStatus: false,
              currentPlan:
                  element.uuid ===
                  currentUserPlan.data.data[0].current_package.uuid
                      ? true
                      : false,
            })
            this.packages.push({
              ...element,
              loadingStatus: false,
              monthly_price: element.monthly_price + amountCal,
              // tier: tierList.find(
              //     tierItem =>
              //         tierItem.tier.level == element.package.level
              // ),
              currentPlan:
                  element.uuid ===
                  currentUserPlan.data.data[0].current_package.uuid
                      ? true
                      : false,
            });
          }
        });

        this.packages.sort((a, b) => {
          return a.monthly_price - b.monthly_price;
        });
        this.currentPackage = this.packages.find(
            (element) => element.currentPlan === true
        );
        if (currentUserPlan.data.data.length > 0) {
          this.currentSubscriptionPlan = currentUserPlan.data.data[0]
        }
      });
    },

    async cancelSubscription() {
      this.cancelSubscriptionStatus = true;
      const uuid = await this.getUuid();
      let obj = {
        cancellation_text:
            this.requestCancelSubscription.cancellation_text ||
            this.requestCancelSubscription.reasons_for_cancellation,
        reasons_for_cancellation: [
          this.requestCancelSubscription.reasons_for_cancellation,
        ],
      };
      this.$useJwt
          .cancelSubscription(uuid, obj)
          .then((res) => {
            this.$nextTick(() => {
              this.$refs["modal"].toggle("#toggle-btn");
            });
            //Change to modalMsg
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("Message.Success"),
                icon: "CheckIcon",
                variant: "success",
                text: res.data[this.message],
              },
            });

            this.$refs.cancelForm.reset();

            this.requestCancelSubscription = Object.assign({
              cancellation_text: "",
              reasons_for_cancellation: [],
            });
          })
          .finally(() => {
            this.cancelSubscriptionStatus = false;
          });
    },

    getPrice(price) {
      return this.monthly
          ? price.quarterly_base_price_monthly_average
          : price.yearly_base_price_monthly_average;
    },

    validationForm(event) {
      event.preventDefault();

      this.$refs.cancelForm.validate().then((res) => {
        if (res) this.cancelSubscription();
      });
    },
    confirmPlan(data, mode) {
      this.selectedPlan = data
      this.mode = mode
      this.$bvModal.show("confirmation-modal");
    },
    updatePlan(value) {
      if (this.paymentStatusChange) {
        value.loadingStatus = true;
        this.sendingModalAction = true;
        this.updatePaymentPlan(
            this.monthly,
            this.editPackages,
            this.subMessage
        ).then((res) => {
          this.updateCurrentBtn = res;
          this.updateCurrentPayment = "update";
          this.$bvModal.hide("confirmation-modal");
          this.sendingModalAction = false;
          value.loadingStatus = false;
        });
      }
    },
    closeMemberModal() {
      this.editPackages.loadingStatus = false;
    },
    deletedUser() {
      this.selectedUsers.forEach((uuid) => {
        this.$useJwt.deleteUser(uuid.value);
      });
      this.modalEditElement.hide();
    },

    async finalStepsUpdate() {
      let newPrice = this.editPackages.monthly_price;
      let currentPrice = this.currentPackage.monthly_price;

      const choosenPackage = this.editPackages;

      const uuid = await this.getUuid();

      if (this.paymentStatusChange) {
        this.updatePaymentPlan(
            this.monthly,
            this.editPackages,
            this.subMessage
        ).then((res) => {
          this.editPackages.loadingStatus = res;
          this.sendingModalAction = false;
          this.$bvModal.hide("confirmation-modal");
        });
      }

      if (newPrice > currentPrice) {
        this.updatePackage(uuid, "upgrade", {
          package_uuid: choosenPackage.uuid,
        })
            .then((res) => {
              if (this.selectedUsers.length > 0) {
                this.deletedUser();
              }
              this.editPackages.loadingStatus = false;
              this.sendingModalAction = false;
              this.$bvModal.hide("confirmation-modal");
            })
            .catch((err) => {
              this.sendingModalAction = false;
              this.editPackages.loadingStatus = false;
            });
      } else {
        this.updatePackage(uuid, "downgrade", {
          package_uuid: choosenPackage.uuid,
        })
            .then((res) => {
              if (this.selectedUsers.length > 0) {
                this.deletedUser();
              }
              this.editPackages.loadingStatus = false;
              this.sendingModalAction = false;
              this.$bvModal.hide("confirmation-modal");
            })
            .catch((err) => {
              this.editPackages.loadingStatus = false;
              this.sendingModalAction = false;
            });
      }
      await this.getAllPackages();
    },

    async actionButton(value) {
      value.loadingStatus = true;
      this.sendingModalAction = true;
      const downgradeId = await this.getDowngradePackages();
      const upgradeId = await this.getUpgradePackages();
      if (downgradeId) {
        let requestDowngradePackage = this.packages.find(
            (element) => element.uuid === downgradeId.uuid
        ).name;

        this.popupMsg(
            this.$t("Message.Failed"),
            this.$t(this.locale.downgradeMessage, {
              package: requestDowngradePackage,
            }),
            "AlertTriangleIcon",
            "danger"
        );

        value.loadingStatus = false;
        this.sendingModalAction = false;
      } else if (upgradeId) {
        let requestUpgrade = this.packages.find(
            (element) => element.uuid == upgradeId.uuid
        ).name;

        this.popupMsg(
            this.$t("Message.Failed"),
            this.$t(this.locale.upgradeMessage, {
              package: requestUpgrade,
            }),
            "AlertTriangleIcon",
            "danger"
        );

        value.loadingStatus = false;
        this.sendingModalAction = false;
      } else if (this.totalUsers < value.allowed_users) {
        this.editPackages = value;
        this.limitUser = value.allowed_users;
        this.$bvModal.show("change-modal-users");
      } else {
        this.editPackages = value;
        this.finalStepsUpdate();
      }
    },
    getFullPlan() {
      return this.$useJwt.getLandingPackages();
    },
    changePaymentPlan(value) {
      if (value.hasOwnProperty("paymentStatusChange")) {
        this.monthly = value.monthly;
        let plan = this.paymentPlans.filter(item => item.name === value.monthly)
        let addon_price = 0.0
        if (plan.length > 0) {
          addon_price = parseFloat(plan[0]['monthly_add_on'])
        }
        this.paymentStatusChange = value.paymentStatusChange;
        let packagesPlan = JSON.parse(JSON.stringify(this.packagesCopy))
        packagesPlan.map(item => {
          item.monthly_price += parseFloat(addon_price)
        })
        packagesPlan.sort((a, b) => {
          return a.monthly_price - b.monthly_price;
        });
        this.packages = packagesPlan

      } else this.monthly = value.monthly;
    },
    getPackagePlan() {
      this.$useJwt.getPaymentPlan().then(res => {
        this.paymentPlans = res.data.results
      })
    },
    openUpgradeConfirmModal() {
      this.$bvModal.msgBoxConfirm(
        'Du är på väg att flytta golfklubbens prenumeration från Golfigos gamla filversion, till den nya versionen som är direktkopplad till GIT - bra val! När du klickar på bekräfta nedan kommer ett nytt avtal att skapas för golfklubben med Golfigos nya priser, ni kommer också att motta en faktura för er Golfigo-prenumeration de kommande 12 månaderna. Precis som vanligt är bindningstid 12 månader. Köpvillkor hittar ni på Golfigos hemsida. Notera att det inte utgår någon kredit på vad som redan betalats via gamla avtalet.', {
        title: 'Vänligen bekräfta uppgraderingen',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        okTitle: 'Bekräfta',
        cancelTitle: 'Avbryt',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if(value) {
          this.upgradeApplication();
        }
      })
      .catch(err => {
        // Handle error if necessary
      });
    },
    upgradeApplication() {
      // Replace with your actual API endpoint and request method
      this.$useJwt.customSubscription({URL: `/move_to_api/`, method: 'get'})
        .then(response => {
          // Handle success response
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Message."),
              icon: "CheckIcon",
              variant: "success",
              text: response.data.message,
            },
          });
        })
        .catch(error => {
          // Handle error response
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Message.Failed"),
              icon: "CheckIcon",
              variant: "danger",
              text: error.response ? error.response.data.message : "Ett fel uppstod, kontakta supporten på info@golfigo.se för assistans.",
            },
          });
        });
    },
  },
  async mounted() {
    this.$useJwt.getCurrentUsers().then((res) => {
      const {role} = res.data.data;
      // if (role.role_definition !== "Full Access") {
      //   this.$swal({
      //     text: this.$t("SubscriptionCard.notOwnerAlert"),
      //     icon: "warning",
      //     showCancelButton: false,
      //     showConfirmButton: true,
      //     timer: 5000,
      //     reverseButtons: true,
      //     confirmButtonText: "Ok",
      //     timerProgressBar: true,
      //     customClass: {
      //       confirmButton: "btn btn-primary",
      //       cancelButton: "btn btn-danger mr-1",
      //     },
      //     buttonsStyling: false,
      //   });
      // } else this.notOwnerRole = false;
    });

    this.$useJwt.getUsers().then((res) => {
      this.totalUsers = res.data.data.length;
    });
    this.getSubcription()
    this.getPackagePlan()
    this.getAllPackages();
  },
};
</script>

<style scoped lang="scss">
.checkLargeIcon {
  position: absolute;
  bottom: 15px;
  right: 0px;
  width: 25px;
}

.price {
  font-size: 50px;
  height: 60px;
}

.priceDuration {
  font-size: 20px;
}

.per-page-selector-send-method {
  width: 100%;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
