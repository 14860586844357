<template>
    <div class="customWitdh">
        <label class="title">{{ title }}</label>

        <v-select class="w-full" v-model="value" :options="options" :label="getLabel(label)" :filterable="true"></v-select>
    </div>
</template>
  
<script>
import vSelect from 'vue-select';

export default {
    components: { vSelect },
    props: {
        title: String,
        options: Array,
        label: String,
        value: Object
    },
    methods: {
        getLabel(label) {
            return label ? label : 'name'
        }
    }
};
</script>
  
<style lang="scss">
.title {
    color: #232D39;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.customWitdh {
    min-width: 12% !important;
    margin-right: .5rem !important;

}

@import '@core/scss/vue/libs/vue-select.scss';
</style>
  