<template>
  <div>
    <b-card class="position-relative" :class="{'md-access-denied-height': accessDenied === true}">
      <md-access-denied :is-access-denied="accessDenied" v-if="accessDenied === true" class="mt-40"/>
      <div class="flex flex-col font-rubik">
        <h3 class="text-blue-900 text-lg mb-1 font-rubik" v-if="hidden_fields.auto_pause_unpaid_and_overdue_invoices">
          {{ $t('invoiceConfig.noxFinance') }}</h3>
        <!--                <ValidationObserver ref="invoiceForm">-->
        <div class="relative w-full">
          <div class="right-0 absolute">
                            <span class="cursor-pointer" @click="$bvModal.show('modal-configuration')">  
                                <mdicon
                                    role="button"
                                    :width="20"
                                    :height="20"
                                    name="help-circle-outline"
                                />
                            </span>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div class="col-span-2 flex flex-col">
            <div class="flex items-center gap-10 md:gap-20">
              <label for="" class="text-base"
                     v-if="hidden_fields.auto_pause_unpaid_and_overdue_invoices">{{ $t('invoiceConfig.autoPauseText') }}
                <span>
                                        <span class="cursor-pointer"
                                              v-b-tooltip="renderHelptext('auto_pause_unpaid_and_overdue_invoices')">
                                            <mdicon
                                                role="button"
                                                :width="20"
                                                :height="20"
                                                name="help-circle-outline"
                                            />
                                        </span>
                                    </span>
              </label>
              <div class="" v-if="hidden_fields.auto_pause_unpaid_and_overdue_invoices">
                                    <span class="flex gap-5 mt-1">
                                        <div>
                                            <span>{{ $t('invoiceConfig.yes') }}</span>
                                            <b-form-radio
                                                v-model="invoiceForm.auto_pause_unpaid_and_overdue_invoices"
                                                name="radio-auto-pause"
                                                class="c-mb-2"
                                                :value="true"
                                            >
                                            </b-form-radio> 
                                        </div>
                                        <div>
                                            <span>{{ $t('invoiceConfig.no') }}</span>
                                            <b-form-radio
                                                v-model="invoiceForm.auto_pause_unpaid_and_overdue_invoices"
                                                name="radio-auto-pause"
                                                class="c-mb-2"
                                                :value="false"
                                            >
                                            </b-form-radio> 
                                        </div>
                                    </span>
              </div>
            </div>
            <div class="flex flex-col">
                                <span class="flex flex-wrap gap-2 items-center"
                                      v-if="hidden_fields.auto_pause_unpaid_and_overdue_invoices">
                                    <span>{{ $t('invoiceConfig.pauseFor') }}
                                        <span>
                                            <span class="cursor-pointer"
                                                  v-b-tooltip="renderHelptext('pause_invoice_days_count')">
                                                <mdicon
                                                    role="button"
                                                    :width="20"
                                                    :height="20"
                                                    name="help-circle-outline"
                                                />
                                            </span>
                                        </span>
                                    </span>
                                    <span class="w-32 block" v-if="hidden_fields.pause_invoice_days_count">
                                        <b-form-input
                                            v-model="invoiceForm.pause_invoice_days_count"
                                            :disabled="!invoiceForm.auto_pause_unpaid_and_overdue_invoices"
                                            type="number"
                                            min="0"
                                            name="login-email"
                                            :placeholder="
                                                ''
                                            "
                                        />
                                    </span>
                                    <span> {{ $t('invoiceConfig.days') }} </span>
                                    <span class="relative w-32 block"
                                          v-if="hidden_fields.pause_invoice_days_after_due_date">
                                        <b-form-input
                                            name="login-email"
                                            min="0"
                                            type="number"
                                            :disabled="!invoiceForm.auto_pause_unpaid_and_overdue_invoices"
                                            v-model="invoiceForm.pause_invoice_days_after_due_date"
                                            :placeholder="
                                                ''
                                            "
                                        />
                                    </span>
                                    <span> {{ $t('invoiceConfig.afterDueDate') }}
                                        <span>
                                            <span class="cursor-pointer"
                                                  v-b-tooltip="renderHelptext('pause_invoice_days_after_due_date')">
                                                <mdicon
                                                    role="button"
                                                    :width="20"
                                                    :height="20"
                                                    name="help-circle-outline"
                                                />
                                            </span>
                                        </span>
                                    </span>
                                </span>
            </div>

          </div>
          <div class="col-span-2 flex flex-col" v-if="hidden_fields.apply_settings_to">
            <ValidationProvider
                #default="{errors}"
                :name="'Apply this to'"
                rules="required"
            >
              <h3 class="text-blue-900 text-lg mb-1 font-rubik">{{ $t('invoiceConfig.applyTo') }}
                <span>
                                        <span class="cursor-pointer" v-b-tooltip="renderHelptext('apply_settings_to')">  
                                            <mdicon
                                                role="button"
                                                :width="20"
                                                :height="20"
                                                name="help-circle-outline"
                                            />
                                        </span>
                                    </span>
              </h3>
              <span class="flex flex-col mt-1">

                                    <b-form-radio
                                        v-model="invoiceForm.apply_settings_to"
                                        :disabled="!invoiceForm.auto_pause_unpaid_and_overdue_invoices"
                                        name="apply-to"
                                        class="c-mb-2"
                                        value="all_invoices"
                                    >
                                        {{ $t('invoiceConfig.allInvoices') }}
                                    </b-form-radio>
                                    <div class="flex items-center mb-1">
                                        <b-form-radio
                                            name="apply-to"
                                            :disabled="!invoiceForm.auto_pause_unpaid_and_overdue_invoices"
                                            class="c-mb-2"
                                            value="invoices_for_customers_in_segment"
                                            v-model="invoiceForm.apply_settings_to"
                                        >
                                        </b-form-radio>
                                        <span class="flex flex-wrap gap-2 items-center">
                                            <span>{{ $t('invoiceConfig.customerSegmentInvoices') }}
                                                <span>
                                                    <span class="cursor-pointer"
                                                          v-b-tooltip="renderHelptext('customers_segment')">
                                                        <mdicon
                                                            role="button"
                                                            :width="20"
                                                            :height="20"
                                                            name="help-circle-outline"
                                                        />
                                                    </span>
                                                </span>
                                            </span>
                                            <span class="w-32 block">
                                                <b-form-input
                                                    :disabled="invoiceForm.apply_settings_to !== 'invoices_for_customers_in_segment' ||
                                                !invoiceForm.auto_pause_unpaid_and_overdue_invoices"
                                                    v-model="invoiceForm.customers_segment"
                                                    type="number"
                                                    min="0"
                                                    id="perPageSelect"
                                                    size="md"
                                                    inline
                                                >
                                            </b-form-input>
                                            </span>
                                        </span>
                                    </div>
                                    <div class="flex items-center">
                                        <b-form-radio
                                            name="apply-to"
                                            :disabled="!invoiceForm.auto_pause_unpaid_and_overdue_invoices"
                                            v-model="invoiceForm.apply_settings_to"
                                            value="invoices_with_terms_of_payment"
                                            class="items-center c-mb-2"
                                        >
                                        </b-form-radio>
                                        <span class="flex flex-wrap gap-2 items-center">
                                            <span>{{ $t('invoiceConfig.topInvoices') }}
                                                <span>
                                                    <span class="cursor-pointer"
                                                          v-b-tooltip="renderHelptext('customers_segment')">
                                                        <mdicon
                                                            role="button"
                                                            :width="20"
                                                            :height="20"
                                                            name="help-circle-outline"
                                                        />
                                                    </span>
                                                </span>
                                            </span>
                                            <span class="w-32 block">
                                                <b-form-input
                                                    :disabled="invoiceForm.apply_settings_to !== 'invoices_with_terms_of_payment' ||
                                                !invoiceForm.auto_pause_unpaid_and_overdue_invoices"
                                                    size="md"
                                                    inline
                                                >
                                                <!-- v-model="invoiceForm.apply_settings_to" -->
                                            </b-form-input>
                                            </span>
                                        </span>
                                    </div>
                                </span>
              <small class="text-danger" v-text="errors[0]"/>
            </ValidationProvider>
            <hr>
          </div>
          <!--<div class="col-span-2 flex flex-col" v-if="hidden_fields.lost_revenue_days">
            <h3 class="text-blue-900 text-lg mb-1 font-rubik">{{ $t('invoiceConfig.lostRevenue') }}
              <span>
                                    <span class="cursor-pointer" v-b-tooltip="renderHelptext('lost_revenue_days')">  
                                        <mdicon
                                            role="button"
                                            :width="20"
                                            :height="20"
                                            name="help-circle-outline"
                                        />
                                    </span>
                                </span>
            </h3>
            <span class="flex flex-col mt-1">
                                <span class="flex flex-wrap gap-2 items-center">
                                    <span>{{ $t('invoiceConfig.considerInvoices') }} </span>
                                    <span class="w-32 block">
                                        <b-form-input
                                            type="number"
                                            v-model="invoiceForm.lost_revenue_days"
                                            min="0"
                                            size="md"
                                            inline
                                        />
                                    </span>
                                    <span>{{ $t('invoiceConfig.afterDueDate') }} {{
                                        $t('invoiceConfig.isLostRevenue')
                                      }}</span>
                                </span>
                            </span>
            <hr>
          </div>-->

          <div class="col-span-2 flex flex-col" v-if="hidden_fields.receive_notification">
            <h3 class="text-blue-900 text-lg mb-1 font-rubik">{{ $t('invoiceConfig.notifications') }}</h3>
            <span class="flex flex-col mt-1">
                                <span class="text-base">{{ $t('invoiceConfig.receiveNotifications') }}
                                    <span>
                                        <span class="cursor-pointer"
                                              v-b-tooltip="renderHelptext('receive_notification')">
                                            <mdicon
                                                role="button"
                                                :width="20"
                                                :height="20"
                                                name="help-circle-outline"
                                            />
                                        </span>
                                    </span>
                                </span>
                                <span class="flex flex-col mt-1">
                                    <div class="flex items-center mb-1" v-if="hidden_fields.receive_notification">
                                        <b-form-checkbox
                                            v-model="invoiceForm.receive_notification"
                                            name="notify"
                                            class="c-mb-2"
                                            :value="true"
                                        >
                                        </b-form-checkbox>
                                        <span class="flex flex-wrap gap-2 items-center"
                                              v-if="hidden_fields.receive_notification_days_after_due_date">
                                            <span>{{ $t('invoiceConfig.invoiceUnpaid') }}
                                                <span>
                                                    <span class="cursor-pointer"
                                                          v-b-tooltip="renderHelptext('receive_notification_days_after_due_date')">
                                                        <mdicon
                                                            role="button"
                                                            :width="20"
                                                            :height="20"
                                                            name="help-circle-outline"
                                                        />
                                                    </span>
                                                </span>
                                            </span>
                                            <span class="w-32 block">
                                                <b-form-input
                                                    name="login-email"
                                                    :disabled="!invoiceForm.receive_notification"
                                                    type="number"
                                                    v-model="invoiceForm.receive_notification_days_after_due_date"
                                                    min="0"
                                                    :placeholder="
                                                        ''
                                                    "
                                                />
                                            </span>
                                            <span> {{ $t('invoiceConfig.afterDueDate') }}</span>
                                        </span>
                                    </div>
                                </span>
                            </span>
            <hr>
          </div>

          <div class="col-span-2 flex flex-col">
            <h3 class="text-blue-900 text-lg mb-1 font-rubik">{{ $t('invoiceConfig.golfRelated') }}</h3>
            <div class="grid grid-cols-1 md:grid-cols-2 w-full md:w-3/4 md:gap-10">
              <div class="flex flex-col mb-1">
                <b-form-checkbox v-if="hidden_fields.always_use_receiver"
                                 name="always_use_receiver"
                                 v-model="invoiceForm.always_use_receiver"
                                 class="c-mb-2"
                                 :value="true"
                >
                                        <span>{{ $t('invoiceConfig.alwaysUseReceiver') }}
                                            <span>
                                                <span class="cursor-pointer"
                                                      v-b-tooltip="renderHelptext('always_use_receiver')">
                                                    <mdicon
                                                        role="button"
                                                        :width="20"
                                                        :height="20"
                                                        name="help-circle-outline"
                                                    />
                                                </span>
                                            </span>
                                        </span>
                </b-form-checkbox>
                <b-form-checkbox v-if="hidden_fields.use_gits_article_text"
                                 name="use_gits_article_text"
                                 v-model="invoiceForm.use_gits_article_text"
                                 class="c-mb-2"
                                 :value="true"
                >
                                        <span>{{ $t('invoiceConfig.useGitArticleText') }}
                                            <span>
                                                <span class="cursor-pointer"
                                                      v-b-tooltip="renderHelptext('use_gits_article_text')">
                                                    <mdicon
                                                        role="button"
                                                        :width="20"
                                                        :height="20"
                                                        name="help-circle-outline"
                                                    />
                                                </span>
                                            </span>
                                        </span>
                </b-form-checkbox>
                <b-form-checkbox v-if="hidden_fields.bookkeep_invoice"
                                 name="bookkeep"
                                 v-model="invoiceForm.bookkeep_invoice"
                                 class="c-mb-2"
                                 :value="true"
                >
                                        <span>{{ $t('invoiceConfig.bookkeep') }}
                                            <span>
                                                <span class="cursor-pointer"
                                                      v-b-tooltip="renderHelptext('bookkeep_invoice')">
                                                    <mdicon
                                                        role="button"
                                                        :width="20"
                                                        :height="20"
                                                        name="help-circle-outline"
                                                    />
                                                </span>
                                            </span>
                                        </span>
                </b-form-checkbox>
                <b-form-checkbox v-if="hidden_fields.send_invoice"
                                 name="golf-send"
                                 v-model="invoiceForm.send_invoice"
                                 class="c-mb-2"
                                 :value="true"
                >
                                        <span>{{ $t('invoiceConfig.send') }}
                                            <span>
                                                <span class="cursor-pointer"
                                                      v-b-tooltip="renderHelptext('send_invoice')">
                                                    <mdicon
                                                        role="button"
                                                        :width="20"
                                                        :height="20"
                                                        name="help-circle-outline"
                                                    />
                                                </span>
                                            </span>
                                        </span>
                </b-form-checkbox>

                <div class="mt-1" v-if="hidden_fields.send_channel">
                  <label for="" class="text-base">{{ $t('invoiceConfig.sendChannel') }}
                    <span>
                                                <span class="cursor-pointer"
                                                      v-b-tooltip="renderHelptext('send_channel')">
                                                    <mdicon
                                                        role="button"
                                                        :width="20"
                                                        :height="20"
                                                        name="help-circle-outline"
                                                    />
                                                </span>
                                            </span>
                  </label>
                  <div class="w-2/3">
                    <select :disabled="!invoiceForm.send_invoice" v-model="invoiceForm.send_channel"
                            class="flex w-full custom-border border-gray-300 focus:outline-none bg-white rounded-md c-p-2">
                      <option value="nox">{{ $t('invoiceConfig.noxFinance') }}</option>
                      <option value="regular">{{ $t('invoiceConfig.regular') }}</option>
                    </select>
                    <!-- <b-form-select
                        v-model="invoiceForm.send_channel"
                        :options="['nox', 'regular']"
                        size="md"
                        inline
                    >
                    </b-form-select> -->
                  </div>
                </div>

                <div class="mt-1" v-if="hidden_fields.send_method">
                  <label for="" class="text-base">{{ $t('invoiceConfig.sendMethod') }}
                    <span>
                                                <span class="cursor-pointer"
                                                      v-b-tooltip="renderHelptext('send_method')">
                                                    <mdicon
                                                        role="button"
                                                        :width="20"
                                                        :height="20"
                                                        name="help-circle-outline"
                                                    />
                                                </span>
                                            </span>
                  </label>
                  <div class="w-2/3">
                    <select :disabled="!invoiceForm.send_invoice" v-model="invoiceForm.send_method"
                            class="flex w-full custom-border border-gray-300 focus:outline-none bg-white rounded-md c-p-2">
                      <option value="email">{{ $t('invoiceConfig.email') }}</option>
                      <option value="customer_specific">{{ $t('invoiceConfig.customerSpecific') }}</option>
                    </select>
                    <!-- <b-form-select
                        v-model="invoiceForm.send_method"
                        :options="['email', 'customer_specific']"
                        size="md"
                        inline
                    >
                    </b-form-select> -->
                  </div>
                </div>
                <div class="mt-1" v-if="hidden_fields.terms_of_payment">
                  <label for="" class="text-base">{{ $t('git.payment_terms') }}
                    <span>
                                                <span class="cursor-pointer"
                                                      v-b-tooltip="renderHelptext('terms_of_payment')">
                                                    <mdicon
                                                        role="button"
                                                        :width="20"
                                                        :height="20"
                                                        name="help-circle-outline"
                                                    />
                                                </span>
                                            </span>
                  </label>
                  <div class="w-2/3">
                    <v-select
                        :placeholder="$t(`git.payment_terms`)"
                        :options="termsOfPayment"
                        v-model="selectedTop"
                        label="description"
                        @option:selected="computeSelect($event)"
                        class="resource-selector select-size-sm d-inline-block w-full"
                    >
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option[top_locale] }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option[top_locale] }}
                        </div>
                      </template>
                    </v-select>
                  </div>
                </div>


              </div>

              <div class="flex flex-col">
                <div class="flex flex-col" v-if="hidden_fields.invoice_text">
                                        <span class="text-gray-500">{{ $t('invoiceConfig.invoiceText') }}
                                            <span>
                                                <span class="cursor-pointer"
                                                      v-b-tooltip="renderHelptext('invoice_text')">
                                                    <mdicon
                                                        role="button"
                                                        :width="20"
                                                        :height="20"
                                                        name="help-circle-outline"
                                                    />
                                                </span>
                                            </span>
                                        </span>
                  <b-form-textarea
                      v-model="invoiceForm.invoice_text"
                      id="textarea"
                      placeholder=""
                      rows="3"
                      max-rows="6"
                  ></b-form-textarea>
                </div>

                <div class="mt-1">
                  <b-form-checkbox v-if="hidden_fields.is_warehouse_user"
                                   name="warehouse"
                                   v-model="invoiceForm.is_warehouse_user"
                                   class="c-mb-2"
                                   :value="true"
                  >
                                            <span>{{ $t('invoiceConfig.warehouseModule') }}
                                                <span>
                                                    <span class="cursor-pointer"
                                                          v-b-tooltip="renderHelptext('is_warehouse_user')">
                                                        <mdicon
                                                            role="button"
                                                            :width="20"
                                                            :height="20"
                                                            name="help-circle-outline"
                                                        />
                                                    </span>
                                                </span>
                                            </span>
                  </b-form-checkbox>
                </div>

                <div class="mt-1">
                  <b-form-checkbox v-if="hidden_fields.create_invoice_with_default_account"
                                   v-model="invoiceForm.create_invoice_with_default_account"
                                   name="create-default"
                                   class="c-mb-2"
                                   :value="true"
                  >
                                            <span>{{ $t('invoiceConfig.createDefaultAccount') }}
                                                <span>
                                                    <span class="cursor-pointer"
                                                          v-b-tooltip="renderHelptext('create_invoice_with_default_account')">
                                                        <mdicon
                                                            role="button"
                                                            :width="20"
                                                            :height="20"
                                                            name="help-circle-outline"
                                                        />
                                                    </span>
                                                </span>
                                            </span>
                  </b-form-checkbox>
                </div>

                <div class="mt-1" v-if="hidden_fields.default_sales_account">
                  <label for="" class="text-base">{{ $t('invoiceConfig.defaultAccount') }}
                    <span>
                                                <span class="cursor-pointer"
                                                      v-b-tooltip="renderHelptext('default_sales_account')">
                                                    <mdicon
                                                        role="button"
                                                        :width="20"
                                                        :height="20"
                                                        name="help-circle-outline"
                                                    />
                                                </span>
                                            </span>
                  </label>
                  <div class="w-1/2">
                    <b-form-input
                        v-model="invoiceForm.default_sales_account"
                        name="login-email"
                        :placeholder="
                                                    ''
                                                "
                    />
                  </div>
                </div>
                <div class="mt-1" v-if="hidden_fields.invoice_fee">
                  <label for="" class="text-base">{{ $t('git.invoice_fee') }}
                    <span>
                                                <span class="cursor-pointer"
                                                      v-b-tooltip="renderHelptext('invoice_fee')">
                                                    <mdicon
                                                        role="button"
                                                        :width="20"
                                                        :height="20"
                                                        name="help-circle-outline"
                                                    />
                                                </span>
                                            </span>
                  </label>
                  <div class="w-full">
                    <b-form-input
                        type="number"
                        v-model="invoiceForm.invoice_fee"
                        name="invoice_fee"
                        :placeholder="``"
                    />
                  </div>
                </div>
                <div class="mt-1" v-if="hidden_fields.your_reference">
                  <label for="" class="text-base">{{ $t('git.your_reference') }}
                    <span>
                                                <span class="cursor-pointer"
                                                      v-b-tooltip="renderHelptext('your_reference')">
                                                    <mdicon
                                                        role="button"
                                                        :width="20"
                                                        :height="20"
                                                        name="help-circle-outline"
                                                    />
                                                </span>
                                            </span>
                  </label>
                  <div class="w-full">
                    <b-form-input
                        type="text"
                        v-model="invoiceForm.your_reference"
                        name="your_reference"
                        :placeholder="``"
                    />
                  </div>
                </div>
                <div class="mt-1" v-if="hidden_fields.our_reference">
                  <label for="" class="text-base">{{ $t('git.our_reference') }}
                    <span>
                                                <span class="cursor-pointer"
                                                      v-b-tooltip="renderHelptext('our_reference')">
                                                    <mdicon
                                                        role="button"
                                                        :width="20"
                                                        :height="20"
                                                        name="help-circle-outline"
                                                    />
                                                </span>
                                            </span>
                  </label>
                  <div class="w-full">
                    <b-form-input
                        type="text"
                        v-model="invoiceForm.our_reference"
                        name="our_reference"
                        :placeholder="``"
                    />
                  </div>
                </div>
              </div>

            </div>
          </div>

          <br>
          <div class="col-span-2 flex justify-center">
            <button @click="saveSettings" type="button"
                    class="flex md:w-1/4 p-1 text-center focus:outline-none bg-theme-teal text-white whitespace-nowrap rounded-md">
                                <span class="w-full flex justify-center" v-if="saving">
                                    <b-spinner
                                        small
                                        type="grow"
                                    />
                                    {{ $t('Message.loading') }}...
                                </span>

              <span
                  class="whitespace-nowrap text-center w-full text-base center"
                  v-else
                  v-text="
                                        $t(locale.save)
                                    "
              />
            </button>
          </div>
        </div>
        <!--                </ValidationObserver>-->
      </div>
      <div class="account-main" v-if="hidden_fields.payment_rules">
        <PaymentRule/>
      </div>
    </b-card>
    <b-modal
        id="modal-configuration"
        modal-class="modal-primary"
        ref="modal"
        :title="$t(locale.guide)"
        @close="closeModal"
        @cancel="closeModal"
        @hide="closeModal"
        :hide-footer="true"
    >
      <b-card-text>
        <div v-if="guideConfig.length > 0">
          <h5>{{ guideConfig[0][subject] }}</h5>
          <div v-html="guideConfig[0][textConfig]">

          </div>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {getSubscribersMixins} from '@/mixins/getSubscribersMixins';
import {billingMixins} from '@/mixins/billingMixins';
import CustomNoHeaderModalVue from '@/@core/customComponent/CustomNoHeaderModal.vue';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import vSelect from 'vue-select';
import {mapGetters} from "vuex"
import PaymentRule from "./PaymentRules.vue"

import mdAccessDenied from "@core/components/permission/mdAccessDenied";
import PAGES from "@/services/helpers/constant";

export default {
  name: 'InvoiceConfigView',
  mixins: [getSubscribersMixins, billingMixins],
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    CustomNoHeaderModalVue,
    mdAccessDenied,
    PaymentRule
  },
  data() {
    return {
      saving: false,
      accessDenied: false,
      hidden_fields: {...JSON.parse(JSON.stringify(PAGES['configuration']['invoice_configuration']))},
      guideConfig: [],
      invoiceForm: {
        auto_pause_unpaid_and_overdue_invoices: true,
        pause_invoice_days_count: 0,
        pause_invoice_days_after_due_date: 0,
        apply_settings_to: '',
        customers_segment: 0,
        all_invoices: true,
        lost_revenue_days: 0,
        receive_notification: true,
        receive_notification_days_after_due_date: 0,
        always_use_receiver: true,
        bookkeep_invoice: true,
        send_invoice: true,
        send_channel: '',
        invoice_fee: '',
        your_reference: '',
        our_reference: '',
        terms_of_payment: '',
        send_method: 'email',
        invoice_text: '',
        is_warehouse_user: false,
        create_invoice_with_default_account: true,
        default_sales_account: '',
      },
      modalVisible: false,
      items: [],
      fields: [
        {
          key: 'id',
          label: 'invoicesCard.table.number',
          tdClass: 'bTableThStyle',
          thStyle: {
            padding: '0.72rem 10px',
            width: '70px',
          },
        },
        {
          key: 'golfclub',
          label: 'BillingPageCard.companyHead',
          tdClass: 'bTableThStyle',
          thStyle: {
            padding: '0.72rem 0',
            width: '50px',
          },
        },
        {
          key: 'invoice_number',
          label: 'BillingPageCard.invoiceHead',
          tdClass: 'bTableThStyle',
          thStyle: {
            padding: '0.72rem 0',
            width: '50px',
          },
        },
        {
          key: 'date',
          label: 'BillingPageCard.dateHead',
          tdClass: 'bTableThStyle',
          thStyle: {
            padding: '0.72rem 0',
            width: '50px',
          },
        },
      ],
      locale: {
        save: 'ContractConfig.save',
        guide: 'ConfigurationGuide.title'
      },
      invoiceConfiguration: {},
      helptext: [],
      termsOfPayment: [],
      selectedTop: {
        value: '',
        description: '',
        description_english: ''
      }
    };
  },
  computed: {
    message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message';
    },
    subject() {
      if (this.getLang === '') {
        return this.swedishLang ? 'swe_subject' : 'eng_subject';
      } else return this.getLang === 'sv' ? 'swe_subject' : 'eng_subject';
    },
    helpText() {
      if (this.getLang == '') {
        return this.swedishLang ? 'swedish_text' : 'english_text';
      } else return this.getLang == 'sv' ? 'swedish_text' : 'english_text';
    },
    textConfig() {
      if (this.getLang == '') {
        return this.swedishLang ? 'swe_text' : 'eng_text';
      } else return this.getLang == 'sv' ? 'swe_text' : 'eng_text';
    },
    features() {
      if (this.getLang == '') {
        return this.swedishLang ? 'features' : 'features';
      } else return this.getLang == 'sv' ? 'features' : 'features';
    },
    getLang() {
      return this.config.lang.value;
    },
    top_locale() {
      return this.GET_LOCALE === 'sv' ? 'description' : 'description_english'
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  mounted() {
    this.fetchRegister()
    this.getGuides('invoice_config')
    this.getInvoiceConfig()
    this.disabledFields()
    this.getHelptext('invoice_config')
    // this.fetchRegister()
  },
  methods: {
    async fetchRegister() {
      const termsOfPayment = await this.$useJwt.getAllRegisters('termsofpayment')
      this.termsOfPayment = termsOfPayment.data.results.map(el => {
        return {description: el.description, value: el.id, description_english: el.description_english}
      })
    },
    disabledFields() {
      let hidden_object = this.hiddenResource
      try {
        // this.$useJwt.hiddenResource({method: 'get', URL: `/${hidden_object['id']}/`}, {}).then()
        hidden_object['hidden_fields']['configuration']['invoice_configuration'].map(item => {
          this.hidden_fields[item] = false
        })
      } catch (e) {

      }
    },
    closeModal() {
    },
    getGuides(config) {
      this.$useJwt.customGuides({URL: `?guide_type=${config}`, method: 'get'})
          .then(res => {
            this.guideConfig = res.data.results
          })
    },
    getInvoiceConfig() {
      this.accessDenied = false
      this.$useJwt.customConfiguration({URL: '/invoice', method: 'get'})
          .then(res => {
            this.invoiceForm = res.data.results[0]
            if (this.invoiceForm.terms_of_payment) {
              let exist = this.termsOfPayment.filter(item => String(item.value) === String(this.invoiceForm.terms_of_payment))
              this.selectedTop = exist.length > 0 ? exist[0] : {}
            }
          }).catch(err => {
        if (err.response.status === 403) {
          this.accessDenied = true
        } else {
          this.accessDenied = false
        }
      })
    },
    getHelptext(helptext) {
      this.$useJwt.customHelptext({URL: `?dashboard_page=${helptext}`, method: 'get'})
          .then(res => {
            this.helptext = res.data.results
          })
    },
    renderHelptext(field) {
      const filter = this.helptext.filter(el => el.field === field)
      if (filter.length > 0) {
        return filter[0][this.helpText]
      }
      return ''
    },
    saveSettings() {
      let excludeFields = ['created', 'created_at', 'last_modified', 'updated_at', 'all_invoices']
      this.saving = true
      const payload = {}
      Object.keys(this.invoiceForm).map(el => {
        if (excludeFields.includes(el) === false) {
          if (el === 'apply_settings_to') {
            if (this.invoiceForm[el] === "all_invoices") {
              payload['all_invoices'] = true
            } else {
              payload['all_invoices'] = false
            }
          } else if (el === 'terms_of_payment') {
            if (this.selectedTop) {
              payload['terms_of_payment'] = this.selectedTop.value
            }
          } else {
            payload[el] = this.invoiceForm[el]
          }
        }
      })
      this.$useJwt.customConfiguration({URL: `/invoice/${payload.uuid}`, method: 'put'}, payload)
          .then(res => {
            this.saving = false
            this.popupMsg(
                this.$t('Message.Success'),
                res.data[this.message],
                'CheckIcon',
                'success'
            );
          })
          .catch(err => {
            this.saving = false
            if (err.response.data.error) {
              this.popupMsg(
                  this.$t('Message.Failed'),
                  err.response.data.error,
                  'AlertTriangleIcon',
                  'danger'
              );
            } else {
              this.popupMsg(
                  this.$t('Message.Failed'),
                  err.response.data[this.message],
                  'AlertTriangleIcon',
                  'danger'
              );
            }
          })

    },
    computeSelect: function (event) {
      this.selectedTop = event
    }
  },

};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
