<template>
  <b-row class="position-relative" :class="{'md-access-denied-height': accessDenied === true}">
    <md-access-denied :is-access-denied="accessDenied" v-if="accessDenied === true" class="mt-40"/>
    <b-col cols="12" xl="9" md="8">
      <div class="shadow leftCardBottomBorder">
        <div
            class="w-100 position-relative bg-secondaryColor p-2 text-white d-flex justify-content-between leftCardTopBorder"
        >
          <p
              class="mb-0 h2 font-weight-bold"
              style="color: inherit"
              v-text="$t(locale.rightSideTitle)"
          />

          <img
              v-for="(data, index) in priceTag"
              :key="index"
              width="80px"
              class="position-absolute"
              :src="require(`@/assets/duplioAsset/icons/${data.img}`)"
              :style="data.style"
          />
        </div>
        <Loading v-if="dataForm.length == 0"/>
        <div v-else class="w-100 bg-white p-2 leftCardBottomBorder">
          <ValidationObserver ref="form">
            <b-row>
              <b-col
                  v-for="(item, index) in dataForm"
                  :key="index"
                  :xl="item.xl"
                  :sm="item.sm"
              >
                <b-form-group
                    :label="$t(item.label)"
                    :label-for="$t(item.label)"
                >
                  <v-select
                      v-if="item.type == 'select'"
                      :clearable="false"
                      :options="dataCountry"
                      label="name"
                      :placeholder="$t(locale.sendMethod)"
                      class="per-page-selector-send-method d-inline-block"
                      v-model="item.value"
                  >
                  </v-select>

                  <ValidationProvider
                      v-else-if="item.type == 'email'"
                      #default="{ errors }"
                      :name="$t(item.label)"
                      vid="email"
                      rules="required|email"
                  >
                    <b-form-input
                        v-model="item.value"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t(item.label)"
                    />
                    <small class="text-danger" v-text="errors[0]"/>
                  </ValidationProvider>

                  <ValidationProvider
                      v-else
                      #default="{ errors }"
                      :name="$t(item.label)"
                      rules="required"
                  >
                    <b-form-input
                        :id="$t(item.label)"
                        :placeholder="$t(item.label)"
                        v-model="item.value"
                    />
                    <small class="text-danger" v-text="errors[0]"/>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
          </ValidationObserver>

          <div class="d-flex justify-content-end">
            <b-button
                @click="updateBillingDetails"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
            >
              <span v-if="updateDetails">
                <b-spinner small type="grow"></b-spinner>
                {{ $t(locale.loading) }}...
              </span>

              <span v-else v-text="$t(locale.updateBtn)"/>
            </b-button>
          </div>
        </div>
      </div>
    </b-col>
    <b-col cols="12" md="4" xl="3">
      <CurrentPlan :switchTab="switchingTab"/>
    </b-col>

    <b-col cols="12">
      <b-card class="mt-2 rounded-lg">
        <div class="mb-2">
          <b-row>
            <b-col
                cols="12"
                md="4"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                style="gap: 1rem"
            >
              <p class="mb-0" v-text="$t(locale.filter)"/>

              <v-select
                  v-model="selected"
                  :options="filterOptions"
                  :clearable="false"
                  placeholder="Filter"
                  @input="filterStatus($event)"
                  class="per-page-selector d-inline-block"
              >
              </v-select>
            </b-col>

            <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                style="gap: 0.5rem"
            >
              <b-form-input
                  v-model="searchQuery"
                  placeholder="Sök"
                  style="width: 190px"
              />
            </b-col>
          </b-row>
        </div>
        <!-- Table -->
        <b-table
            ref="table"
            responsive
            :items="items"
            :fields="tableColumns"
            primary-key="id"
            show-empty
            :sort-desc.sync="sortDesc"
            :sort-by.sync="sortBy"
            :empty-text="$t(locale.notMatchRecord)"
            class="position-relative"
            :tbody-tr-class="rowClassCallback"
        >
          <template #head(companyName)="data">
            <div class="d-flex">
              <span class="my-auto" style="margin-right: 5px">
                {{ $t(data.field.label) }}
              </span>

              <span class="d-flex flex-column position-relative">
                <feather-icon
                    size="10"
                    @click="sortingChanged('golfclub')"
                    class="cursor-pointer"
                    icon="ChevronUpIcon"
                    style="pointer: cursor"
                    role="button"
                />

                <feather-icon
                    @click="sortingChanged('-golfclub')"
                    size="10"
                    class="cursor-pointer"
                    icon="ChevronDownIcon"
                    role="button"
                />
              </span>
            </div>
          </template>
          <template #head(invoiceNumber)="data">
            <div class="d-flex">
              <span class="my-auto" style="margin-right: 5px">
                {{ $t(data.field.label) }}
              </span>

              <span class="d-flex flex-column position-relative">
                <feather-icon
                    size="10"
                    @click="sortingChanged('invoice_number')"
                    class="cursor-pointer"
                    icon="ChevronUpIcon"
                    style="pointer: cursor"
                    role="button"
                />

                <feather-icon
                    @click="sortingChanged('-invoice_number')"
                    size="10"
                    class="cursor-pointer"
                    icon="ChevronDownIcon"
                    role="button"
                />
              </span>
            </div>
          </template>
          <template #head(amountDue)="data">
            <div class="d-flex">
              <span class="my-auto" style="margin-right: 5px">
                {{ $t(data.field.label) }}
              </span>

              <span class="d-flex flex-column position-relative">
                <feather-icon
                    size="10"
                    @click="sortingChanged('amount_due')"
                    class="cursor-pointer"
                    icon="ChevronUpIcon"
                    style="pointer: cursor"
                    role="button"
                />

                <feather-icon
                    @click="sortingChanged('-amount_due')"
                    size="10"
                    class="cursor-pointer"
                    icon="ChevronDownIcon"
                    role="button"
                />
              </span>
            </div>
          </template>
          <template #head(date)="data">
            <div class="d-flex">
              <span class="my-auto" style="margin-right: 5px">
                {{ $t(data.field.label) }}
              </span>

              <span class="d-flex flex-column position-relative">
                <feather-icon
                    size="10"
                    @click="sortingChanged('date')"
                    class="cursor-pointer"
                    icon="ChevronUpIcon"
                    style="pointer: cursor"
                    role="button"
                />

                <feather-icon
                    @click="sortingChanged('-date')"
                    size="10"
                    class="cursor-pointer"
                    icon="ChevronDownIcon"
                    role="button"
                />
              </span>
            </div>
          </template>
          <template #head(dueDate)="data">
            <div class="d-flex">
              <span class="my-auto" style="margin-right: 5px">
                {{ $t(data.field.label) }}
              </span>

              <span class="d-flex flex-column position-relative">
                <feather-icon
                    size="10"
                    @click="sortingChanged('due_date')"
                    class="cursor-pointer"
                    icon="ChevronUpIcon"
                    style="pointer: cursor"
                    role="button"
                />

                <feather-icon
                    @click="sortingChanged('-due_date')"
                    size="10"
                    class="cursor-pointer"
                    icon="ChevronDownIcon"
                    role="button"
                />
              </span>
            </div>
          </template>
          <template #head(status)="data">
            <div class="d-flex">
              <span class="my-auto" style="margin-right: 5px">
                {{ $t(data.field.label) }}
              </span>

              <span class="d-flex flex-column position-relative">
                <feather-icon
                    size="10"
                    @click="sortingChanged('status')"
                    class="cursor-pointer"
                    icon="ChevronUpIcon"
                    style="pointer: cursor"
                    role="button"
                />

                <feather-icon
                    @click="sortingChanged('-status')"
                    size="10"
                    class="cursor-pointer"
                    icon="ChevronDownIcon"
                    role="button"
                />
              </span>
            </div>
          </template>

          <template #head(hasBeenCredited)="data">
            <div class="d-flex">
              <span class="my-auto" style="margin-right: 5px">
                {{ $t(data.field.label) }}
              </span>

              <span class="d-flex flex-column position-relative">
                <feather-icon
                    size="10"
                    @click="sortingChanged('has_been_credited')"
                    class="cursor-pointer"
                    icon="ChevronUpIcon"
                    style="pointer: cursor"
                    role="button"
                />

                <feather-icon
                    @click="sortingChanged('-has_been_credited')"
                    size="10"
                    class="cursor-pointer"
                    icon="ChevronDownIcon"
                    role="button"
                />
              </span>
            </div>
          </template>

          <template #head(isCredit)="data">
            <div class="d-flex">
              <span class="my-auto" style="margin-right: 5px">
                {{ $t(data.field.label) }}
              </span>

              <span class="d-flex flex-column position-relative">
                <feather-icon
                    size="10"
                    @click="sortingChanged('is_credit')"
                    class="cursor-pointer"
                    icon="ChevronUpIcon"
                    style="pointer: cursor"
                    role="button"
                />

                <feather-icon
                    @click="sortingChanged('-is_credit')"
                    size="10"
                    class="cursor-pointer"
                    icon="ChevronDownIcon"
                    role="button"
                />
              </span>
            </div>
          </template>
          
          <template #head(file)="data">
            <div class="d-flex">
              <span class="my-auto" style="margin-right: 5px">
                {{ $t(data.field.label) }}
              </span>

              <!-- <span class="d-flex flex-column position-relative">
                                <feather-icon
                                    size="10"
                                    @click="sortingChanged()"
                                    class="cursor-pointer"
                                    icon="ChevronUpIcon"
                                    style="pointer: cursor"
                                    role="button"
                                />

                                <feather-icon
                                    @click="sortingChanged(data, false)"
                                    size="10"
                                    class="cursor-pointer"
                                    icon="ChevronDownIcon"
                                    role="button"
                                />
                            </span> -->
            </div>
          </template>
          <!-- Column: date -->
          <template #cell(date)="data">
            <div class="d-flex">
              <p :class="[data.item.classText, 'mb-0']">
                {{ data.value }}
              </p>
            </div>
          </template>

          <template #cell(companyName)="data">
            <div class="d-flex">
              <p :class="[data.item.classText, 'mb-0']">
                {{ data.value }}
              </p>
            </div>
          </template>

          <template #cell(invoiceNumber)="data">
            <div class="d-flex">
              <p :class="[data.item.classText, 'mb-0']">
                {{ data.value }}
              </p>
            </div>
          </template>

          <template #cell(amountDue)="data">
            <div class="d-flex">
              <p :class="[data.item.classText, 'mb-0']">{{ data.value }} SEK</p>
            </div>
          </template>

          <template #cell(dueDate)="data">
            <div class="d-flex">
              <p :class="[data.item.classText, 'mb-0']">
                {{ data.value }}
              </p>
            </div>
          </template>

          <template #cell(status)="data">
            <div class="d-flex">
              <p :class="[data.item.classText, 'mb-0']">
                {{ data.value }}
              </p>
            </div>
          </template>

          <template #cell(hasBeenCredited)="data">
            <div class="d-flex justify-content-center align-items-center" style="height: 100%;">
              <p :class="[data.item.classText, 'mb-0']">
                <!-- Check if data.value is true -->
                <span v-if="data.value === true">
                  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 17C13.1946 17 17 13.1946 17 8.5C17 3.80545 13.1946 0 8.5 0C3.80545 0 0 3.80545 0 8.5C0 13.1946 3.80545 17 8.5 17ZM13.0654 5.80975C13.1051 5.76879 13.1362 5.72023 13.1568 5.66698C13.1774 5.61373 13.187 5.55688 13.1852 5.49982C13.1833 5.44276 13.17 5.38666 13.1459 5.33487C13.1219 5.28308 13.0877 5.23666 13.0453 5.19839C13.003 5.16011 12.9533 5.13077 12.8994 5.1121C12.8454 5.09343 12.7883 5.08582 12.7313 5.08972C12.6744 5.09363 12.6188 5.10897 12.5679 5.13484C12.517 5.1607 12.4718 5.19655 12.4351 5.24025L7.344 10.866L4.54325 8.1923C4.46176 8.11441 4.35266 8.07209 4.23996 8.07464C4.12726 8.07719 4.02019 8.12441 3.9423 8.2059C3.86441 8.28739 3.82209 8.39649 3.82464 8.50919C3.82719 8.62189 3.87441 8.72896 3.9559 8.80685L7.07285 11.7819L7.38863 12.0836L7.68145 11.7598L13.0654 5.80975Z" fill="#419E6A"/>
                  </svg>
                </span>
                <!-- If data.value is false, display nothing -->
                <span v-else></span>
              </p>
            </div>
          </template>
          
          <template #cell(isCredit)="data">
            <div class="d-flex justify-content-center align-items-center" style="height: 100%;"></div>
              <p :class="[data.item.classText, 'mb-0']">
                <!-- Check if data.value is true -->
                <span v-if="data.value === true">
                  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 17C13.1946 17 17 13.1946 17 8.5C17 3.80545 13.1946 0 8.5 0C3.80545 0 0 3.80545 0 8.5C0 13.1946 3.80545 17 8.5 17ZM13.0654 5.80975C13.1051 5.76879 13.1362 5.72023 13.1568 5.66698C13.1774 5.61373 13.187 5.55688 13.1852 5.49982C13.1833 5.44276 13.17 5.38666 13.1459 5.33487C13.1219 5.28308 13.0877 5.23666 13.0453 5.19839C13.003 5.16011 12.9533 5.13077 12.8994 5.1121C12.8454 5.09343 12.7883 5.08582 12.7313 5.08972C12.6744 5.09363 12.6188 5.10897 12.5679 5.13484C12.517 5.1607 12.4718 5.19655 12.4351 5.24025L7.344 10.866L4.54325 8.1923C4.46176 8.11441 4.35266 8.07209 4.23996 8.07464C4.12726 8.07719 4.02019 8.12441 3.9423 8.2059C3.86441 8.28739 3.82209 8.39649 3.82464 8.50919C3.82719 8.62189 3.87441 8.72896 3.9559 8.80685L7.07285 11.7819L7.38863 12.0836L7.68145 11.7598L13.0654 5.80975Z" fill="#419E6A"/>
                  </svg>
                </span>
                <!-- If data.value is false, display nothing -->
                <span v-else></span>
              </p>
            </div>
          </template>


          <!-- Column: date -->
          <template #cell(file)="data">
            <b-button variant="transparent" @click="installFile(data.value)">
              <feather-icon size="16" icon="DownloadIcon"/>
            </b-button>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row align-h="end">
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <paginate
                  v-model="pagination.currentPage"
                  :page-count="pagination.totalBilling"
                  :click-handler="firePagination"
                  :prev-text="'<'"
                  :next-text="'>'"
                  :page-class="'page-item c-mr-1 border-unset'"
                  prev-link-class="page-link c-mr-1 border-unset"
                  next-link-class="page-link c-mr-1 border-unset"
                  next-class="next-item"
                  prev-class="prev-item"
                  page-link-class="page-link c-mr-1 border-unset"
                  active-class="active c-mr-1 border-unset"
                  :container-class="'pagination  b-pagination'"
              >
              </paginate>

              <!-- <b-pagination
                                v-model="currentPage"
                                :total-rows="pagination.totalBilling"
                                :per-page="pagination.perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination> -->
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import {required, email} from "@validations";
import CurrentPlan from "@/@core/components/billings/CurrentPlan.vue";
import useAppConfig from "@core/app-config/useAppConfig";
import countriesCode from "@/mixins/countriesCode.json";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import Paginate from "vuejs-paginate";
import {mapGetters} from "vuex";

import mdAccessDenied from "@core/components/permission/mdAccessDenied";

export default {
  name: "BillingView",
  props: {
    switchingTab: {
      type: Function,
      required: false,
    },
  },
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    CurrentPlan,
    ValidationProvider,
    ValidationObserver,
    paginate: Paginate,
    mdAccessDenied
  },
  data() {
    return {
      dataForm: [],
      accessDenied: false,
      updateDetails: false,
      selected: "Alla",
      filterOptions: ["Alla", "Betald", "Obetald", "Förfallen"],
      searchQuery: "",
      statusOptions: ["betald", "obetald", "förfallen"],
      tableColumns: [
        {
          key: "companyName",
          label: "BillingPageCard.companyHead",
        },
        {
          key: "invoiceNumber",
          label: "BillingPageCard.invoiceHead",
        },
        {
          key: "amountDue",
          label: "BillingPageCard.amountHead",
        },
        {
          key: "date",
          label: "BillingPageCard.dateHead",
        },
        {
          key: "dueDate",
          label: "BillingPageCard.dueDateHead",
        },
        {
          key: "status",
          label: "BillingPageCard.statusHead",
        },
        {
          key: "hasBeenCredited",
          label: "BillingPageCard.hasBeenCreditedHead",
        },
        {
          key: "isCredit",
          label: "BillingPageCard.isCreditHead",
        },
        {
          key: "file",
          label: "BillingPageCard.fileHead",
          tdClass: "bTableThStyle",
          thStyle: {width: "20px"},
        },
      ],
      items: [],
      currentPage: 1,
      pagination: {
        currentPage: 1,
        totalBilling: 1,
        perPage: 50,
      },
      priceTag: [
        {
          img: "priceTagUp.png",
          style: {right: "5px", top: 0},
        },
        {
          img: "priceTagDown.png",
          style: {right: "54px", top: "11px"},
        },
      ],
      config: useAppConfig(),
      sortBy: "",
      sortDesc: null,
      base64: "",
      dataCountry: countriesCode,
      required,
      email,
      locale: {
        rightSideTitle: "BillingPageCard.rightSide.title",
        sendMethod: "SettingPageCard.placeHolder.chooceSendMethod",
        loading: "Message.loading",
        updateBtn: "BillingPageCard.leftSide.updateBtn",
        filter: "BillingPageCard.Filter",
        notMatchRecord: "EventLogCard.noMatchRecordFound",
      },
      queryParams: {
        limit: 50,
      },
    };
  },

  watch: {
    searchQuery(value) {
      let dataArray = [];

      this.$useJwt
          .getBillingsList({
            params: {
              status: this.selected == "All" ? "" : this.selected,
              search: value,
            },
          })
          .then((res) => {
            const {results} = res.data;
            this.pagination.totalBilling = Math.ceil(res.data.count / this.pagination.perPage);

            this.renderTable(results);
          });

      this.items = dataArray;
    },

    currentPage(value) {
      this.getBillingLists(value);
    },
  },

  computed: {
    getLang() {
      return this.config.lang.value;
    },
    message() {
      return this.GET_LOCALE === "sv" ? "message" : "eng_message";
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },

  methods: {
    renderCountries(params) {
      return this.dataCountry.find((element) => element.iso === params);
    },
    renderTable(value) {
      let dataArray = [];
      this.items = [];
      value.forEach((element) => {
        dataArray.push({
          id: element.id,
          companyName: element.golfclub,
          invoiceNumber: element.invoice_number,
          dueDate: element.due_date,
          status: element.status,
          date: element.date,
          isCredit: element.is_credit,
          hasBeenCredited: element.has_been_credited,
          classText: this.statusClass(element.status),
          file: {file: element.file, id: element.uuid},
          amountDue: element.amount_due,
          rowClass: this.statusToColor(element.status)
        });
      });

      this.items = dataArray;
    },
    statusClass(status) {
      switch (status.toLowerCase()) {
        case "unpaid":
          return "text-warning";
        case "overdue":
          return "text-danger";
        case "paid":
          return "text-success";
      }
    },
    sortingChanged(item) {
      this.queryParams.ordering = item;
      delete this.queryParams.search;
      this.getBillingLists();
    },
    getBillingLists() {
      this.$useJwt
          .getCustomBillingList(
              {URL: "", method: "get"},
              {params: this.queryParams}
          )
          .then((res) => {
            const {results} = res.data;
            this.pagination.totalBilling = Math.ceil(res.data.count / this.pagination.perPage);
            this.renderTable(results);
            // this.pagination.totalBilling = res.data.count;

            this.renderTable(results);
          }).catch(err => {
        this.accessDenied = err.response.status === 403;
      });
      // this.$useJwt
      //     .getBillingsList({
      //         params: {page: page},
      //     })
      //     .then(res => {
      //         const {results} = res.data;
      //         this.pagination.totalBilling = res.data.count;

      //         this.renderTable(results);
      //     });
    },
    firePagination(number) {
      this.pagination.currentPage = number;
      this.queryParams.page = number;
      this.getBillingLists();
    },
    getBillingDetails() {
      this.dataForm = [];
      this.$useJwt.getBillingsDetail().then((res) => {
        const {data} = res.data;

        this.dataForm.push(
            {
              label: "BillingPageCard.leftSide.Form.invoiceAddress",
              object: "invoice_address",
              xl: "3",
              sm: "6",
              type: "input",
              value: data.invoice_address,
            },
            {
              label: "BillingPageCard.leftSide.Form.city",
              object: "city",
              xl: "3",
              sm: "6",
              type: "input",
              value: data.city,
            },
            {
              label: "BillingPageCard.leftSide.Form.zipCode",
              object: "zip_code",
              xl: "3",
              sm: "6",
              type: "input",
              value: data.zip_code,
            },
            {
              label: "BillingPageCard.leftSide.Form.billingEmail",
              object: "billing_email",
              xl: "3",
              sm: "6",
              type: "email",
              value: data.billing_email,
            },
            {
              label: "BillingPageCard.leftSide.Form.country",
              object: "country",
              xl: "3",
              sm: "6",
              type: "select",
              value: this.renderCountries(data.country),
            }
        );
      });
    },
    installFile(value) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: this.$t("Message.wait"),
          variant: "warning",
          // text: this.$t('Message.subscriptionProcess'),
          waiting: true,
        },
      });
      this.$useJwt.downLoadFile(value.id).then((res) => {
        this.popupMsg(this.$t("Message.Success"), "", "CheckIcon", "success");
        const {base64_string} = res.data;
        const {file_name} = res.data;

        const linkSource = `data:application/pdf;base64,${base64_string}`;
        const downloadLink = document.createElement("a");
        const fileName = file_name;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
    },
    filterStatus(status) {
      if (status == "All") status = "";

      this.$useJwt
          .getBillingsList({
            params: {
              status: status,
            },
          })
          .then((res) => {
            const {results} = res.data;

            this.pagination.totalBilling = res.data.count;

            this.renderTable(results);
          });
    },
    updateBillingDetails() {
      this.$refs.form.validate().then((res) => {
        if (res) {
          let newBillingsDetails = {};

          this.dataForm.forEach((element) => {
            if (element.object == "country") {
              newBillingsDetails.country = element.value.iso;
            } else {
              newBillingsDetails[element.object] = element.value;
            }
          });

          this.updateDetails = true;

          this.$useJwt
              .updateBillingsDetail(newBillingsDetails)
              .then((res) => {
                this.popupMsg(
                    this.$t("Message.Success"),
                    res.data[this.message],
                    "CheckIcon",
                    "success"
                );
              })
              .finally(() => {
                this.updateDetails = false;
              });
        }
      });
    },
    statusToColor(status) {
      status = status.toLowerCase();
      switch (status) {
        case "paid":
        case "betald":
          return "text-success"; // Bootstrap class for green text
        case "unpaid":
        case "obetald":
          return "text-primary"; // Bootstrap class for blue text
        case "overdue":
        case "förfallen":
          return "text-danger"; // Bootstrap class for red text
        default:
          return "";
      }
    },
    rowClassCallback(item, type) {
      if (type === 'row') {
        return item.rowClass;
      }
      return '';
    },

    init() {
      this.getBillingLists();
      this.getBillingDetails();
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style>

</style>
<style scoped lang="scss">
.per-page-selector {
  width: 200px;
}

.leftCardTopBorder {
  border-top-left-radius: 0.6rem !important;
  border-top-right-radius: 0.6rem !important;
}

.leftCardBottomBorder {
  border-bottom-left-radius: 0.6rem !important;
  border-bottom-right-radius: 0.6rem !important;
}

.per-page-selector-send-method {
  width: 100%;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
